import React from 'react';
import { Container, Row, Col, Button, Card, Form, Table } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { getImageUrl, numberWithFloat, dealersPortal } from '../../../../core/helpers/OperationsHelper'
import CoreCarts from '../../../../core/components/transactions/CoreCarts';
import { updateCart } from '../../../../core/helpers/ServicesHelper';
import EmptyCart from '../../../components/EmptyCart';
import loader from '../../../assets/loader/circle-loader.gif';
import Tostify from '../../../components/Toaster';
import Loader from '../../../components/Loader';
import Variations from '../components/Variations';
import CompareModal from '../../../pages/products/components/CompareModal'
import MetaTags from '../../../components/MetaTags';

class CartListing extends CoreCarts {
	updateCartQuantity = async (value, id) => {
		var formdata = []
		formdata['carts[cart]'] = 1;
		formdata['carts[action_type]'] = 2;
		formdata['carts[quantity]'] = value;
		formdata['carts[id]'] = id;
		formdata['carts[user_id]'] = "";

		this.state.cartList.map((cart, index) => {
			if (cart.id == id) {
				cart.variations.map((variation, variation_index) => {
					formdata['cart_variations[' + variation_index + '][variation_name]'] = variation.variation_name;
					formdata['cart_variations[' + variation_index + '][variation_value]'] = variation.variation_value;
				})
			}
		});

		await this.updateCart(formdata, id);
		this.getCartListing()
	}

	render() {
		const { compareProducts, cartList } = this.state;
		const { t } = this.props
		return (
      <React.Fragment>
        <MetaTags {...this.props} />
        <Tostify />
        <div className="main mt-4">
          <div className="main_section">
            {this.state.loader == false && (
              <Container>
                <h1 className="page_heading bold text-uppercase black mb-3">
                  {t('shoppingCart')}
                </h1>
                {cartList.length > 0 ? (
                  <Row className="flex-column flex-sm-row">
                    <Col lg={8} sm={8} className="mt-4 mt-sm-0">
                      <Card className="cart_table shadow-sm">
                        <Card.Body>
                          {this.state.cartList.map((item, index) => (
                            <div key={index} className="cart_item">
                              <Row className="">
                                <Col xs={4}>
                                  <Link
                                    to={
                                      '/pp/' +
                                      item.item_details.category_slug +
                                      '/' +
                                      item.item_details.slug
                                    }
                                    title={item.item_details.name}
                                    className="product_item_photo">
                                    <span className="product_item_wrapper">
                                      {typeof this.state.images[item.id] !=
                                        'undefined' &&
                                      this.state.images[item.id] != '' ? (
                                        <img
                                          src={this.state.images[item.id]}
                                          className="product_item_img img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={loader}
                                          className="product_img img-fluid"
                                        />
                                      )}
                                    </span>
                                  </Link>
                                </Col>
                                <Col xs={8}>
                                  <div className="product_item_details">
                                    <div className="d-flex align-items-center">
                                      <div className="flex-fill w-50">
                                        <Link
                                          to={
                                            '/pp/' +
                                            item.item_details.category_slug +
                                            '/' +
                                            item.item_details.slug
                                          }
                                          className="product_item_name">
                                          {item.item_details.name}
                                        </Link>
                                        <Table borderless className="mt-2 mb-0">
                                          <tbody>
                                            <tr className="item_options">
                                              <td
                                                width="18%"
                                                className="px-0 py-0 pb-1">
                                                <p className="text-capitalize black mb-0">
                                                  {t('model')}
                                                </p>
                                              </td>
                                              <td
                                                width="82%"
                                                className="px-0 py-0 pb-1">
                                                <p className="text-capitalize black mb-0">
                                                  :{' '}
                                                  {item.item_details.item_code}
                                                </p>
                                              </td>
                                            </tr>
                                            <tr className="item_options">
                                              <td
                                                width="18%"
                                                className="px-0 py-0 pb-1">
                                                <p className="text-capitalize black mb-0">
                                                  {
                                                    item.item_details
                                                      .product_type_field_name
                                                  }
                                                </p>
                                              </td>
                                              <td
                                                width="82%"
                                                className="px-0 py-0 pb-1">
                                                <p className="text-capitalize black mb-0">
                                                  :{' '}
                                                  <i className="fal fa-rupee-sign"></i>{' '}
                                                  {item.item_details
                                                    .product_type_field_value -
                                                    (item.item_details
                                                      .product_type_field_value *
                                                      item.dealer_discount) /
                                                      100}
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>
                                      {item.item_details
                                        .product_type_field_currency == 0 && (
                                        <div className="price_div flex-fill">
                                          <span className="item_price bold">
                                            {typeof item.item_details
                                              .product_type_field_value !=
                                              'undefined' &&
                                              item.item_details
                                                .product_type_field_value +
                                                ' ' +
                                                item.item_details
                                                  .product_type_field_unit}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                    <div className="d-flex">
                                      <div className="flex-fill">
                                        <Table
                                          borderless
                                          width="100%"
                                          className="mb-0">
                                          <tbody>
                                            <Variations
                                              variations={item.variations}
                                            />
                                            <tr className="item_options">
                                              {item.stock_status == 1 ? (
                                                <td
                                                  width="18%"
                                                  className="px-0 py-0 pb-1 green bold"
                                                  style={{fontSize: '12px'}}>
                                                  {t('inStock')}
                                                </td>
                                              ) : (
                                                <td
                                                  width="18%"
                                                  className="px-0 py-0 pb-1 red bold"
                                                  style={{fontSize: '12px'}}>
                                                  {t('outStock')}
                                                </td>
                                              )}
                                            </tr>

                                            {item.remark != '' &&
                                              item.remark != null && (
                                                <tr className="item_options">
                                                  <td
                                                    width="18%"
                                                    className="px-0 py-0 pb-1 gray"
                                                    style={{fontSize: '12px'}}>
                                                    Remark
                                                  </td>
                                                  <td
                                                    width="82%"
                                                    className="px-0 py-0 pb-1 gray"
                                                    style={{fontSize: '12px'}}>
                                                    : {item.remark}
                                                  </td>
                                                </tr>
                                              )}
                                          </tbody>
                                        </Table>
                                        {typeof this.props.settings
                                          .quantity_selector != 'undefined' &&
                                          this.props.settings
                                            .quantity_selector === 'Yes' && (
                                            <div className="d-flex align-items-center">
                                              <h6 className="mb-0 pr-3 bold text-nowrap">
                                                {t('select')} {t('quantity')} :
                                              </h6>
                                              <div>
                                                <div className="quantity_input_div">
                                                  <button
                                                    className="quantity_btn"
                                                    onClick={() =>
                                                      this.updateCartQuantity(
                                                        parseInt(
                                                          item.quantity,
                                                        ) - 1,
                                                        item.id,
                                                      )
                                                    }>
                                                    <i className="fal fa-minus"></i>
                                                  </button>
                                                  <button
                                                    className="quantity_btn mx-3"
                                                    onClick={() =>
                                                      this.updateCartQuantity(
                                                        parseInt(
                                                          item.quantity,
                                                        ) + 1,
                                                        item.id,
                                                      )
                                                    }>
                                                    <i className="fal fa-plus"></i>
                                                  </button>
                                                  <input
                                                    type="text"
                                                    className="quantity_input"
                                                    value={item.quantity}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                        <div className="item_action pb-0">
                                          <div className="item_action_link">
                                            <a
                                              onClick={() =>
                                                this.deleteCartItem(item.id)
                                              }>
                                              {t('delete')}
                                            </a>
                                          </div>
                                          {this.props.settings
                                            .is_compare_enable == 'Yes' && (
                                            <div className="item_action_link">
                                              <a
                                                onClick={() =>
                                                  this.addCompareProduct(
                                                    item.item_details.id,
                                                  )
                                                }>
                                                {t('compare')}
                                              </a>
                                            </div>
                                          )}
                                          {this.props.settings
                                            .enable_wishlist == 'Yes' && (
                                            <div className="item_action_link">
                                              <a
                                                onClick={() =>
                                                  this.moveItemToWishList(
                                                    item.id,
                                                  )
                                                }>
                                                {t('moveToWishlist')}
                                              </a>
                                            </div>
                                          )}
                                        </div>
                                        <span className="red bold">
                                          {typeof this.state.cartErrors[
                                            item.id
                                          ] != 'undefined'
                                            ? this.state.cartErrors[item.id][
                                                'carts[quantity]'
                                              ]
                                            : ''}
                                        </span>
                                        <span className="red bold">
                                          {
                                            this.state.errors[
                                              'error_' + item.id
                                            ]
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {item.home_delivery_available == 'yes' &&
                                    item.store_pickup_available == 'yes' &&
                                    dealersPortal == false && (
                                      <React.Fragment>
                                        <p className="black mt-3 mb-0 bold">
                                          Do you want store pickup for this
                                          product?
                                        </p>
                                        <Form.Group controlId="formBasicCheckbox">
                                          {/* <Form.Check defaultChecked={(item.delivery_prefrence == 'store pickup') ? true : false} onChange={(e) => this.checkStorePickup(e.target.checked, item.id)} type="checkbox" label=" Check for store pickup" /> */}
                                          {/* <Form.Check.Input className="customCheck" onChange={(e, name, type) => this.changeFilterState(value, filterVal.name, filterVal.type)} type="checkbox" isValid /> */}
                                          <Form.Check.Input
                                            defaultChecked={
                                              item.delivery_prefrence ==
                                              'store pickup'
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              this.checkStorePickup(
                                                e.target.checked,
                                                item.id,
                                              )
                                            }
                                            type="checkbox"
                                          />
                                          <Form.Check.Label className="mx-2">
                                            Check for store pickup
                                          </Form.Check.Label>
                                        </Form.Group>
                                      </React.Fragment>
                                    )}
                                </Col>
                              </Row>
                            </div>
                          ))}
                          <div className="sub-total-div">
                            <p style={{fontSize: '1.428rem'}} className="mb-0">
                              <span style={{paddingRight: '5px'}}>
                                {t('subTotal')} ({this.state.itemsCount}{' '}
                                {t('items')}) :{' '}
                              </span>
                              {this.state.currency == 1 ? (
                                <span className="bold">
                                  <i className="fal fa-rupee-sign"></i>{' '}
                                  {numberWithFloat(this.state.subTotal)}
                                </span>
                              ) : (
                                <span className="bold">
                                  {numberWithFloat(this.state.subTotal) +
                                    ' ' +
                                    this.state.unit}
                                </span>
                              )}
                            </p>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col lg={4} sm={4}>
                      <Card className="shadow-sm">
                        <Card.Body>
                          <p style={{fontSize: '1.428rem'}}>
                            <span style={{paddingRight: '5px'}}>
                              {t('subTotal')} ({this.state.itemsCount}{' '}
                              {t('items')}) :{' '}
                            </span>
                            {this.state.currency == 1 ? (
                              <span className="bold">
                                <i className="fal fa-rupee-sign"></i>{' '}
                                {numberWithFloat(this.state.subTotal)}
                              </span>
                            ) : (
                              <span className="bold">
                                {numberWithFloat(this.state.subTotal) +
                                  ' ' +
                                  this.state.unit}
                              </span>
                            )}
                          </p>
                          {this.state.errors.length <= 0 ? (
                            <Button
                              className="d-block mx-auto w-100 "
                              onClick={() => this.checkInventory()}
                              style={this.props.styles.primary_btn}>
                              {this.props.settings.is_checkout_enabled == 'Yes'
                                ? 'Proceed to Checkout'
                                : 'Place Order'}
                            </Button>
                          ) : (
                            <Button
                              className="d-block mx-auto w-100 disabled"
                              style={this.props.styles.primary_btn}>
                              {this.props.settings.is_checkout_enabled == 'Yes'
                                ? 'Proceed to Checkout'
                                : 'Place Order'}
                            </Button>
                          )}
                          <br />
                          {typeof this.props.settings.quotations !=
                            'undefined' &&
                            this.props.settings.quotations == 'Yes' && (
                              <Button
                                className="d-block mx-auto w-100 "
                                onClick={() => this.placeOrder(2)}>
                                Get Quotations
                              </Button>
                            )}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                ) : (
                  <EmptyCart />
                )}
                {compareProducts.length > 0 && (
                  <CompareModal
                    removeAllCompareItem={() => this.removeAllCompareItem()}
                    removeCompareItem={(compare_id) =>
                      this.removeCompareItem(compare_id)
                    }
                    products={compareProducts}
                  />
                )}
              </Container>
            )}
            <Loader loaderState={this.state.loader} />
          </div>
        </div>
      </React.Fragment>
    );
	}
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings.settings,
		styles: state.styles.styles,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setCartCount: (count) => { dispatch({ type: 'SET_CART_COUNT', payload: count }) },
		setCartProducts: (products) => { dispatch({ type: 'SET_CART_PRODUCTS', payload: products }) },
		setWishListCount: (count) => { dispatch({ type: 'SET_WISHLIST_COUNT', payload: count }) },
		setWishListProducts: (products) => { dispatch({ type: 'SET_WISHLIST_PRODUCTS', payload: products }) },
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CartListing));