import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import Header from '../../../../src/web/components/headers/scott/Header';
import Footer from '../../../../src/web/components/footers/scott/Footer';
import { Provider } from 'react-redux';
import store from '../../../store';

const CoreRedirectWithStatus = ({from, to, status}) => {
  console.log(from,to)
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = status;
        return <Redirect from={from} to={to} />;
      }}
    />
  );
};

export default CoreRedirectWithStatus;
