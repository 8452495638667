const initialState = {count: 0, items: {}}

const wishlists = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_WISHLIST_COUNT':
      return { count: action.payload, products: state.products };
    case 'SET_WISHLIST_PRODUCTS':
      return { count: state.count, products: action.payload };
  }
  return state
}

export default wishlists;