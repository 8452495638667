import React from 'react';
import cookie from 'react-cookies';
import { mobile } from '../../../core/helpers/OperationsHelper'
import { getCustomerHeaderFields, selectTags } from '../../../core/helpers/ServicesHelper';

class CoreHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addClass: false, isOpen: false,
      openDiv: 0, openSubdiv: 0, isActive: false, isSubactive: false, userLog: "LOGIN",
      openSearch: false, redirectTo: '',
      openMenuValues: {},
      openSubMenuValues: {},
      selectedTag: '',
      tags: []
    }
  }

  removeStore = async () => {
    cookie.remove('authToken', { path: '/' });
    cookie.remove('sales_login', { path: '/' });
    cookie.remove('accessToken', { path: '/' });
    if (mobile == true) {
      this.props.navigation.navigate('/Login');
    } else {
      localStorage.removeItem("Catalog_Name");
      window.location.href = '/login';
    }
  }


  searchToggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  sidebarToggle(isOpen = false) {
    if (isOpen == true)
      this.setState({ isOpen: false });
    else
      this.setState({ isOpen: true });
  }

  async selectTags() {
    var response = await selectTags();

    if (response.data.status == 'success') {
      this.setState({ 'tags': response.data.data })
    }
  }

  handleOnSelect(val) {
    this.setState({ 'selectedTag': val })
  }

  async SearchItem() {
    if (this.state.selectedTag != "") {
      this.props.history.push('/pl?q=' + this.state.selectedTag, { q: this.state.selectedTag });
      await this.setState({ 'appliedSearch': true })

    }
  }

  menuToggle(index) {
    var menu = this.state.openMenuValues;
    if (typeof menu[index] != 'undefined' && menu[index].isOpen == true) {
      menu[index] = { isOpen: false }
      this.setState({ openMenuValues: menu })
    } else {
      menu[index] = { isOpen: true }
      this.setState({ openMenuValues: menu })
    }
  }

  subMenuToggle(index) {
    var menu = this.state.openSubMenuValues;
    if (typeof menu[index] != 'undefined' && menu[index].isOpen == true) {
      menu[index] = { isOpen: false }
      this.setState({ openSubMenuValues: menu })
    } else {
      menu[index] = { isOpen: true }
      this.setState({ openSubMenuValues: menu })
    }
  }

  openSearch = () => {
    this.setState({ openSearch: false });
  }
};

export default CoreHeader;