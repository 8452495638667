import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import successImg from '../../../../web/assets/images/icons/checked-big.png'
import OrderDetails from './OrderDetails';
class OrderResult extends React.Component {

	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="main mb-2 mt-5">					
					<Container>
						<Row>
							<Col md={12} className="text-center mx-auto">
								<img src={successImg}  className="mb-3" width="60px" />
								<h3 className="black medium">{t('thankYou')}!</h3>
								<h5 className="black">{t('orderReceive')}</h5>
								<h6>Order ID is <span className="bold">#{this.props.match.params.orderId}</span></h6>
							</Col>
							<Col md={12} style={{marginTop:'-50px'}}>
								<OrderDetails orderId={this.props.match.params.orderId} />
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}


export default  withTranslation()(OrderResult);