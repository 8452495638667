import React from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import LogoMini from '../../../assets/images/icons/logo_mini.png'; 
import Separator from '../../../assets/images/icons/separator.png'; 
import visa from '../../../assets/images/icons/visa.png';
import mastercard from '../../../assets/images/icons/mastercard.png';
import paypal from '../../../assets/images/icons/paypal.png';
import {dealersPortal} from '../../../../core/helpers/OperationsHelper';

class DealerFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {addClass: false,openDiv:1,isOpen:false}
  }
  navToggle(openDiv,isOpen=false) {
    if(isOpen == true)
      this.setState({openDiv: openDiv,isOpen:false});
    else
      this.setState({openDiv: openDiv,isOpen:true});

  }
  render() {
    const addClass = this.state.addClass;
    const { t } = this.props;
    var d = new Date();
    var currentYear = d.getFullYear();
    return (
      <div className="footer">        
        <Container>          
          <Row>
            <Col sm={12} className="text-center my-3">
              <img src={Separator} className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="footer_bottom">
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 1 && this.state.isOpen == true ? "open" : ""}`}  onClick={()=>this.navToggle(1,this.state.isOpen)}>warranty</p>
              <ul>
                <li>
                  <Link to={"/warranty"}>{t('warranty')}</Link>
                </li>               
                <li>
                  <Link to={"/buyer-return-policy"}>{t('policy')}</Link>
                </li>               
              </ul>
            </Col>
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 2 && this.state.isOpen == true ? "open" : ""}`} onClick={()=>this.navToggle(2,this.state.isOpen)}>Products</p>
              <ul>
                <li>
                  <Link to={"/manuals"}>{t('documentation')}</Link>
                </li>              
              </ul>
            </Col>
            
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 4 && this.state.isOpen == true ? "open" : ""}`} onClick={()=>this.navToggle(4,this.state.isOpen)}>Brands</p>
              <ul>
                {(dealersPortal == true)?
                  this.props.dealerBrands.map((brand,index)=>(
                    <li>
                      <Link to={"/bpl/"+brand.brand_name.toLowerCase()}>{brand.brand_name}</Link>
                    </li>
                  ))
                  :
                  <React.Fragment>
                    <li>
                      <Link to={"/bpl/scott"}>Scott</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/bergamont"}>Bergamont</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/syncros"}>Syncros</Link>
                    </li> 
                    <li>
                      <Link to={"/bpl/probike"}>Probike</Link>
                    </li> 
                    <li>
                      <Link to={"/bpl/avanti"}>Avanti</Link>
                    </li>
                  </React.Fragment>   
                }              
              </ul>
            </Col>
            <Col xs={12} lg={6} md={12} className="social_sec">
              <p className="footer_title hiphen open">Dealer Connect</p>
              <div className="row pd7">
                <div className="col-xl-3 col-lg-4 col-sm-4 col-6">
                  <ul className="custom_list mb-0 text-left pl-0 footer_actions">
                    <li className="mb-1">
                      <a href="https://customerconnect.sportnetwork.in/warranties" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100 text-uppercase" style={this.props.styles.secondary_btn}>Warranties</a>
                    </li>                                                                                     
                  </ul> 
                </div> 
                <div className="col-xl-3 col-lg-4 col-sm-4 col-6">
                  <ul className="custom_list mb-0 text-left pl-0 footer_actions">                    
                    <li>
                      <a href="https://customerconnect.sportnetwork.in/bike_orders/basic_customer_details" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100 text-uppercase" style={this.props.styles.secondary_btn}>{t('bikeOrder')}</a>
                    </li>                                                                                     
                  </ul>
                </div>                                     
                <div className="col-xl-3 col-lg-4 col-sm-4 col-6">
                  <ul className="custom_list mb-0 text-left footer_actions">                      
                    <li className="mb-1">
                      <a href="https://customerconnect.sportnetwork.in/dealer_bonanzas/bike_and_customer_details" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100 text-uppercase" style={this.props.styles.secondary_btn}>Dealer Bonanza</a>
                    </li>                                                        
                  </ul>
                </div>   
                <div className="col-xl-3 col-lg-4 col-sm-4 col-6">
                  <ul className="custom_list mb-0 text-left footer_actions">
                    <li className="mb-1">
                      <a href="https://customerconnect.sportnetwork.in/registrations/basic_informations" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100 text-uppercase" style={this.props.styles.secondary_btn}>Bike Registration</a>
                    </li>
                  </ul>
                </div>                                     
              </div>
            </Col>
          </Row>          
        </Container>        
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
    dealerBrands: state.dealers.brands,
  }
}

export default withTranslation()(connect(mapStateToProps)(DealerFooter));