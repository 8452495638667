import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'

import MetaTags from '../../../components/MetaTags';
import Faq from '../components/Faq';

class Warning extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">Warning</h1>						
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Warning of Fraudulent Offers Online</h2>
								<p>SCOTT would like to warn all consumers about offers of fake SCOTT products online. Such fake products are often offered by users of various online auction websites, but also occur on independent homepages. Suppliers of fake products often attract consumers with low priced goods.</p>
								<p>Users of such platforms and homepages risk that they receive fake products of poor quality, often containing components hazardous to health and environment. It also occurs that users make payments in advance, but never receive the ordered goods (especially when using “B2B” platforms located in Asian countries)</p>
								<p>SCOTT would like to point out that it can only guarantee the originality of its products purchased through an official SCOTT dealer. Therefore, SCOTT recommends consumers to verify the origin of the products and reliability of the respective online supplier beforehand. SCOTT shall be exempted from any liability in this matter.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">The Concequences of Technical Manipulations on Scott E-Bikes</h2>
								<p>The technical manipulation of E-Bikes, especially the enhancement of the maximum speed, may not only reduce the safety level, but also cause severe legal implications:</p>
								<ul>
									<li>The warranty of the E-Bike and the assist system may become void</li>
									<li>The CE-approval may become void</li>
									<li>The legal classification of the product may change and other requirements (e.g. authorisation, insurance, number plate) may occur.</li>
								</ul>
								<p>Therefore, we strongly advise our dealers not to make any technical manipulations on SCOTT E-Bikes.</p>	
							</div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Warning;