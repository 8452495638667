import React from 'react';
import { Container, Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../../assets/css/style"
import CoreHeader from '../../../../core/components/header/CoreHeader';
import SubBannerOne from '../../../assets/images/banners/discover-winter.jpg';
import SubBannerTwo from '../../../assets/images/banners/no-addict-ride.jpg';
import LogoImg from '../../../assets/images/logos/sns-logo-red.png';
import ContentLoaderLogo from "../../ContentLoaderLogo";

class CustomerHeader extends CoreHeader {
  // componentDidUpdate(){
  //   console.log(this.props.settings.image_url + '/application_images/original/' + this.props.settings.header_logo)
  //   console.log({...this.props.settings})
  //   console.log(this.props.settings.image_url)
  //   console.log(this.props.settings.header_logo)
  // }
  render() {

    // console.log(this.state.openMenuValues)
    const { categories, subCategories, subSubCategories } = this.props;
    // console.log(this.props.categories);
    // console.log(this.props.subCategories);
    // console.log(this.props.subSubCategories);
    // console.log(this.props)
    
    return (
      <>
        <div className="logo_div">
          {(typeof this.props.settings.header_logo != 'undefined') ? 
            <a href="/" className="navbar-brand" >
              <img src={this.props.settings.image_url + '/application_images/original/' + this.props.settings.header_logo} width="130px" />
            </a> 
            : 
            <ContentLoaderLogo />
          }
        </div>
        <nav id="menu" className={`main_menus ${this.props.isOpen == true ? "open" : ""}`} >
          <ul className='menu-link'>
            {categories.length > 0 && categories.map((category, menuIndex) => (
              <li key={menuIndex} className={`nav-item bold ${typeof this.state.openMenuValues[menuIndex] != 'undefined' && this.state.openMenuValues[menuIndex].isOpen == true ? "active" : ""}`}>
                <a className="nav-link" onClick={() => this.menuToggle(menuIndex)}>{category.name}</a>
                <div className="subnav">
                  <Container>
                    <Row>
                      <Col lg={8} className="subnav-list">
                        <Row className="no-gutters">
                          {Object.keys(subCategories).length > 0 && typeof subCategories[category.id] != 'undefined' && (subCategories[category.id]).map((subCategory, subIndex) => (
                            <Col key={subIndex} lg={4} className={`${typeof this.state.openMenuValues[menuIndex] != 'undefined' && this.state.openMenuValues[menuIndex].isOpen == true ? "sub_active" : ""}`}>
                              <ul className="subnav-list-item">
                                <React.Fragment>
                                  <li className="submenu_desk right_col">
                                    <Link to={"/pl/" + category.slug + '/' + subCategory.slug}>{subCategory.name}</Link>
                                  </li>
                                  <li className={`submenu_mob right_col ${typeof this.state.openSubMenuValues[subIndex] != 'undefined' && this.state.openSubMenuValues[subIndex].isOpen == true ? "sub_active" : ""}`}>
                                    <a onClick={() => this.subMenuToggle(subIndex)}>{subCategory.name}</a>
                                  </li>

                                  <div className="pushmenu">
                                    {Object.keys(subSubCategories).length > 0 && typeof subSubCategories[subCategory.id] != 'undefined' && (subSubCategories[subCategory.id]).map((subSubCategory, index) => (
                                      <li key={index}>
                                        <Link to={"/pl/" + category.slug + '/' + subCategory.slug + '/' + subSubCategory.slug}>{subSubCategory.name}</Link>
                                      </li>
                                    ))}
                                  </div>
                                </React.Fragment>
                              </ul>
                            </Col>
                          ))}
                        </Row>
                      </Col>


                      <Col lg={4} className="subnav-promos">
                        <Row className="no-gutters">
                          <Col lg={12} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_1} className="img-fluid" />
                            </a>
                          </Col>

                          <Col lg={6} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_2} className="img-fluid" />
                            </a>
                          </Col>
                          <Col lg={6} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_3} className="img-fluid" />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                  </Container>
                </div>
              </li>

            ))}
            {/* <Link to="#"><li className="nav-link nav-pre-li "><Link to="#" >Pre Booking</Link></li></Link> */}
            {/* <Link to="/pre-booking" className="nav-link nav-pre-li "><li className='nav-pre-booking'>Pre Booking</li></Link> */}

            {/* <li className=' nav-pre-booking text-uppercase text-dark' onClick={() => window.location.href = "/pl/bikes/bikes/pre-booking"}>Pre Booking</li> */}
            {/* <li className=' nav-pre-booking text-uppercase text-dark' onClick={() => window.location.href = "/triathlon-store"}>Triathlon</li> */}
            <li className=' nav-pre-booking text-uppercase text-dark' onClick={() => window.location.href = "/events"}>Strava</li>
          </ul>

        </nav>
      </>
    );
  };
};

export default CustomerHeader;