import React from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import GalleryProductZoomImg from '../../../assets/images/products/scott-bike.jpg?h=2000';
import GalleryProductLinkImg from '../../../assets/images/products/scott-bike.jpg?h=1400';
import GalleryProductDataImg from '../../../assets/images/products/scott-bike.jpg?h=800';
import GalleryProductDataImgX from '../../../assets/images/products/scott-bike.jpg?h=800 2x';
import GalleryProductImg from '../../../assets/images/products/scott-bike.jpg?h=400';
import GalleryThumbImgLink from '../../../assets/images/products/scott-bike.jpg?h=120';
import GalleryThumbImgLinkX from '../../../assets/images/products/scott-bike.jpg?h=120 2x';
import GalleryThumbImg from '../../../assets/images/products/scott-bike.jpg?h=60';
import BlankImg from '../../../assets/images/products/blank-img.png';

class QuickviewModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div id="quickview-modal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button type="button" className="close" data-dismiss="modal">
                  <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col sm={7}>
                  <div className="product_gallery">
                    <div className="app-figure" id="zoom-fig">
                      <a id="Zoom-1" className="MagicZoom" 
                        style={{minHeight:"450px",display:'block',margin:"0 auto"}}
                        href={GalleryProductLinkImg}
                        data-zoom-image-2x={GalleryProductZoomImg}
                        data-image-2x={GalleryProductDataImg}
                      >
                        <img src={GalleryProductImg} srcSet={GalleryProductDataImgX}  className="img-fluid mx-auto d-block" />
                      </a>
                      <div className="selectors">
                        <a
                          data-slide-id="product-1"
                          data-zoom-id="Zoom-1"
                          href={GalleryProductLinkImg}
                          data-image={GalleryProductImg}
                          data-zoom-image-2x={GalleryProductZoomImg}
                          data-image-2x={GalleryProductDataImg}
                        >
                          <img srcSet={GalleryThumbImgLinkX} src={GalleryThumbImg} />
                        </a>
                        <a
                          data-slide-id="product-2"
                          data-zoom-id="Zoom-1"
                          href={GalleryProductLinkImg}
                          data-image={GalleryProductImg}
                          data-zoom-image-2x={GalleryProductZoomImg}
                          data-image-2x={GalleryProductDataImg}
                        >
                          <img srcSet={GalleryThumbImgLinkX} src={GalleryThumbImg}  />
                        </a>                         
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={5}>
                  <div className="product_detail">
                    <h4 className="bold text-uppercase hiphen">scott scale rc 900 world cup axs bike</h4>
                    <div className="ref">
                      <Row>
                        <Col xs={6}>
                          {t('model')} : 280457
                        </Col>
                        <Col xs={6} className="category_tip">
                          <span className="span_tip">condition of usage 3</span>
                          <div className="tip_content">
                            <span>Example Cross country and marathon<br/> <a href="">Please check the detail for more information</a></span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <h5 className="bold black text-uppercase mt-5 mb-2">{t('keyFeatures')}</h5>
                      <ul className="key-features-list list-unstyled pl-0">
                        <li>Scale RC Carbon HMX Frame</li>
                        <li>RockShox SID SL Ultimate RD3 100mm</li>
                        <li>RideLoc 3-Position Remote</li>
                        <li>SRAM XX1 AXS Eagle 12 Speed</li>
                        <li>SRAM Level TLM Disc Brakes</li>
                        <li>Syncros Silverton 1.0 Crbn Wheels</li>
                        <li>Maxxis Rekon Race Folding Tires</li>
                        <li>Syncros Carbon Parts</li>
                      </ul>
                    </div>
                    <div className="product_sizes">
                      <h6 className="bold black text-uppercase mt-5 mb-2">1. {t('size')}</h6>
                      <a href="" className="size_btn">S</a>
                      <a href="" className="size_btn">M</a>
                      <a href="" className="size_btn">L</a>
                      <a href="" className="size_btn">XL</a>
                      <a href="" className="">{t('sizeChart')}</a>
                    </div>
                    <div>
                      <Button type="button" variant="link" className="compare_btn">{t('compareProducts')}</Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="modal-footer border-0">
              <Button type="button" variant="link" className="compare_btn">{t('viewFullDetails')}</Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(QuickviewModal);



