import React, { Component } from 'react'
import { saveCatalog,getCatalogData } from '../../helpers/ServicesHelper'
export default class CoreCatalogs extends Component {
  constructor(props){
    super(props);
    this.state={
      catalogData:[],
      errorMessage:[],
      isEdit:0,
      catalogName:"",
      catalogId:"",
      accessLevel:"",
      catalogs:[]
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount(){
    var catalogId = this.props.match.params.id;
    if(typeof catalogId != 'undefined'){
      var catalogData = await getCatalogData(catalogId);
      if(catalogData.data.status == 'success'){
        this.setState({'catalogName':catalogData.data.data.name,
                        accessLevel:catalogData.data.data.access_level,
                        catalogId:catalogData.data.data.id,
                        isEdit:1,catalogData:catalogData.data.data})
      }
    }
  }

  async enableDisableCatalog(e){
    var formdata = [];
    if(this.state.catalogData.status == 0)
      formdata['catalogs[status]'] = 1;
    else
      formdata['catalogs[status]'] = 0;
    formdata['catalogs[id]'] = this.state.catalogId;
    this.handleSubmit(e,true,formdata);
  }

  goBack(){
    window.location.href = '/catalogs';
  }

  handleChange (event) {
    this.setState({[event.target.name]: event.target.value })
  }

  async handleSubmit(e,pageReload=false,formdata=[]){
    e.preventDefault();
    formdata['catalogs[name]'] = this.state.catalogName;
    formdata['catalogs[id]'] = this.state.catalogId;
    formdata['catalog_settings[value]'] = this.state.accessLevel;
    formdata['catalog_settings[name]'] = 'access-level';
    var response = await saveCatalog(formdata,this.state.catalogId);
    if(response.data.status == 'success'){
      if(pageReload == false)
        this.props.history.push('/catalogs')
      else
        this.setState({catalogData:response.data.data})
    }else{
      await this.setState({'errorMessage':response.data.errors})
    }
  }


}
