import React, { Component } from 'react';
import { default as converter } from 'number-to-words';

import {getSalesVoucher } from '../../helpers/ServicesHelper';

class CoreReceiptVoucher extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      vouchers:{},
      tallyVoucher:{},
      tallyCompany:{},
      voucherDetails:[],
      voucherReference:[],
      amount:'',
      total:'',
      amounSales:'',
      gst:'',
      gstInWords:'',
      taxInWords:'',
    }
  } 

  async componentDidMount(){
    await this.getSalesVouchers()
  }

  async getSalesVouchers(){
    const voucherId=this.props.match.params.id;
    const vouchers = await getSalesVoucher(voucherId);
    if (vouchers.data.status === 'success') {
      await this.setState({vouchers:vouchers.data.data,tallyVoucher:vouchers.data.data.tally_voucher,
                           tallyCompany:vouchers.data.data.tally_company,
                           voucherDetails:vouchers.data.data.voucher_details,
                           voucherReference:vouchers.data.data.voucher_reference});
      
      if(this.state.voucherReference.length > 0 ){
        var amount=0,sum=0;
        this.state.voucherReference.map((reference)=>{
          amount=Math.abs(reference.debit_amount)>0?
                        (parseFloat(Math.abs(reference.debit_amount))).toFixed(2):
                        (parseFloat(Math.abs(reference.credit_amount))).toFixed(2)
          sum=sum+parseInt(amount);
        })
        const words=converter.toWords(sum);
        sum=parseFloat(sum).toFixed(2);
        this.setState({amount:words,total:sum});
        this.getSalesAmount();
      }
      
    }
    else {
      console.log(vouchers.data.status);
    }
  }

  getSalesAmount(){
    var sum=0,gstAmount=0;
    if(this.state.voucherDetails.length > 0){
        this.state.voucherDetails.map((voucher)=>(
        sum=sum+parseFloat(voucher.amount)
      ))
      this.setState({amounSales:sum});
      if(this.state.voucherReference.length > 0){
        this.state.voucherReference.map((reference)=>(
            gstAmount+=parseFloat(reference.credit_amount-reference.debit_amount)
        ))
        this.setState({gst:gstAmount+sum});
        const amountWords=converter.toWords(this.state.gst);
        this.setState({gstInWords:amountWords});
        const taxWords=converter.toWords(sum*0.18);
        this.setState({taxInWords:taxWords});
      }
    }
  
  }

}

export default CoreReceiptVoucher;