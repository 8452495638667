import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import Tostify from '../../../components/Toaster';
import CoreProfile from '../../../../core/components/users/CoreProfile';
import UserProfileCard from '../components/UserProfileCard';
import BillingAddressCard from '../components/BillingAddressCard';
import StoreCreditBalanceCard from '../components/StoreCreditBalanceCard';

class Profile extends CoreProfile {	
	
	render(){
		const { t,user } = this.props;
		// console.log(user);
		const { billindAddress,storeCreditBalance } = this.state;
		return (
			<React.Fragment>   
				<Tostify />
				<div className="main " style={{marginTop:"50px"}}>
					<Container>		
						<Row>
							<Col lg={12}>
								<div className="mb-3">
									<h1 className="page_heading bold text-uppercase black text-left">My Profile</h1>
								</div>
							</Col>	
						</Row>				
						<Row>
							<UserProfileCard user={user}/>
							<StoreCreditBalanceCard balance={storeCreditBalance}/>
							<BillingAddressCard billindAddress={billindAddress}/>							
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  }
}

export default withTranslation()(connect(mapStateToProps)(Profile));
