import React, { Component } from 'react';
import { getImageContent, getQuotationStates, getQuotations } from '../../helpers/ServicesHelper';
import { getImageUrl, getImagePath } from  '../../helpers/OperationsHelper';

export default class CoreQuotationsListing extends React.Component {
  constructor(props){
    super(props);
    this.state={
      quotationStates: [],
      quotations:[],
      images:[],
      loader: true,
      where: ''
    }
    this.images=[];
  }
  
  async componentDidMount(){
    window.history.pushState(null, null, window.location.pathname);
    await this.getQuotationStates();
  }

  async getQuotationStates() {
  	var quotationStates = await getQuotationStates();
  	if (quotationStates.data.status == 'success' && quotationStates.data.data.length > 0) {
  		this.setState({ quotationStates: quotationStates.data.data });
  		var where = 'where[order_type]=1&where[status]='+quotationStates.data.data[0].transition_to;
  		this.setState({ where: where });
  		this.getQuotations(where);
  	} 
  }

  async getQuotations(where){
  	this.setState({ loader: true });
    var quotations = await getQuotations(where);
    if (quotations.data.status == 'success') {
      this.setState({quotations:quotations.data.data, loader: false});
      this.getBase64Images(quotations.data.data);
    }
  }

  async getBase64Images(quotations){
    quotations.map((quotation,index)=>{
      quotation.order_details.map((order_detail, index) => {
        if (typeof order_detail.item_images.name != 'undefined' && order_detail.item_images.name != null) {
          var medium_image = getImagePath+'products/images/medium/'+order_detail.item_images.name;
          this.setImageInState(medium_image, order_detail.id);
        } else {
          this.setImageInState(getImagePath+'application_images/original/AseYlMuVFTqz5GR7.png', order_detail.id);
        } 
      })
    })    
  } 

  async setImageInState(base64,id){
    this.images = this.state.images;
    this.images[id] = base64;
    this.setState({ images: this.images });
  }

  async setFilterUrl(value) {
    var where = this.state.where+'&filter='+value;
    this.getQuotations(where);
  }
}
