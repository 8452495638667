import React, { Component } from 'react';
import "../../../assets/css/event.css";
import Aw from "../../../assets/images/event/AW.png";
import NewImage from "../../../assets/images/event/strava.png";
class Event extends React.Component {
  render() {
    return (
      <div class="container-fluid text-center my-container">
        <div class="row">
          {/* <div className="col-lg-12">
            <img src={noEventImage} />
            <h3 className='text-dark bold '>No Events Found</h3>
            <p className='my-4 '>This Page doesn't have Events</p>
          </div> */}
          {/* <div className='col-sm-6 mt-1'>
            <div class="bottom-sec">
              <h1>BERGMAN</h1>
              <a href="https://dscorg.in/events/bergman-113">
                <img
                  src={BergMan}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div className='col-sm-6 mt-1'>
            <div class="bottom-sec">
              <h1>BERGKIDS </h1>
              <a href="https://dscorg.in/events/bergman-113">
                <img
                  src={BergKids}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}


          <div class="col-lg-12 col-12">
            <div class="bottom-sec">
              {/* <h4 className='text-uppercase'>Avanti #BreakFree Challenge</h4> */}
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSeCMrF-q0AIko0Pop_wrXXnxOpXgfErY1LDaY6seA8_b-eqog/viewform?usp=sf_link" >
                <img
                  src={NewImage}
                  className="img-fluid mt-3"
                  alt="not found"
                  style={{ cursor: "pointer" }}
                />
              </a>
            </div>
          </div>

          {/* <div class="col-lg-12 col-12 mt-lg-3 mt-3">
            <div class="bottom-sec">
              <h3 className='text-uppercase'>SUMMER RIDE - HYDERABAD</h3>
              <a href="/pl/book-your-service/scott-summer-ride/summer-ride--hyderabad" >
                <img
                  src={hyderabadEvent}
                  className="img-fluid mt-3"
                  alt="not found"
                  style={{ cursor: "pointer", }}
                />
              </a>
            </div>
          </div> */}

          {/* <div className='col-sm-12 mt-5'>
            <div class="bottom-sec">
              <h3>BERGAMONT-I RIDE CHALLENGE </h3>
              <a href="/pp/event/bergamont/bergamont-event/bergamont-i-ride-challenge-3">
                <img
                  src={Banner1}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-6 mt-5">
            <div class="bottom-sec">
              <h1>SYNCROS TUNEUP BIKE SERVICE</h1>
              <a href="https://dscorg.in/events/bergman-113">
                <img
                  src={Basic_Package}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-6 mt-5">
            <div class="bottom-sec">
              <h1>SYNCROS ADVANCE BIKE SERVICE</h1>
              <a href="/pp/event/syncros-advance-bike-service/syncros-advance/standard-service-package">
                <img
                  src={Standard_Package}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-6 mt-5">
            <div class="bottom-sec">
              <h1>SYNCROS SUPREME BIKE SERVICE</h1>
              <a href="/pp/event/syncros-supreme-bike-service/syncros-supreme-bike-service/complete-service-package">
                <img
                  src={Complete_Package}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-6 mt-5">
            <div class="bottom-sec">
              <h1>BIKE FIT</h1>
              <a href="/pp/event/bike-fit-pan-india/bike-fitting/bike-fitting1037">
                <img
                  src={BikeFit}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-12 mt-5">
            <div class="bottom-sec">
              <h1>BIKE FIT (WITH BIKE SET-UP)</h1>

              <a href="/pp/event/bike-fit-with-bike-setup/bike-set-up/bike-fit-with-adjustment-8573">
                <img
                  src={BikeFit}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
          {/* <div class="col-sm-6 mt-5">
            <h1 className='text-uppercase'>Bergamont I Ride Challenge 2.0</h1>

            <div class="bottom-sec">
              <a href="/pp/event/bergamont/bergamont-event/bergamont-i-ride-challenge-2-0424">
                <img
                  src={Banner1}
                  class="img-fluid"
                  alt="not found"
                />
              </a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default Event;
