import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import CorePreparingCheckout from '../../../../core/components/transactions/CorePreparingCheckout';
import Loader from '../../../components/Loader';
import Tostify from '../../../components/Toaster';

class PreparingCheckout extends CorePreparingCheckout {   

  render() {
    return (
      <React.Fragment> 
        <div className="main home">
          <Tostify />      
          <Loader loaderState={true} />
        </div>
      </React.Fragment>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
    user:state.users.user,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {dispatch({ type: 'SET_CART_COUNT', payload: count})},
    setCartProducts: (products) => {dispatch({ type: 'SET_CART_PRODUCTS', payload: products})},
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PreparingCheckout);
