import React, { Component } from 'react';
import { getOrders, getImageContent, getOrderStates, cancelOrder } from '../../helpers/ServicesHelper';
import { getImageUrl, getImagePath, mobile } from  '../../helpers/OperationsHelper';
import { checkUserValid } from  '../../helpers/ServicesHelper';
import AsyncStorage from '@react-native-async-storage/async-storage';
export default class CoreOrdersListing extends React.Component {
  constructor(props){
    super(props);
    this.state={
      orders:[],
      images:{},
      loader: true,
      where: '',
      reload: false,
      status:'month-3',
      selectedValue: ''
    }
    this.images={};
  }
  
  async componentDidMount(){
    window.history.pushState(null, null, window.location.pathname);
    await this.getOrderStates();
  }

  async getOrderStates() {
		var where = 'where[order_type]=0';
  	this.setState({ where: where });
  	this.getOrders(where);
  }

  async cancelOrder(order_id, order_detail_id) {
    var formdata = [];
    formdata['refund_requests[order_id]'] = order_id;
    if (order_detail_id == '') {
      formdata['refund_requests[type]'] = 'order';
    } else {
      formdata['refund_requests[order_detail_id]'] = order_detail_id;
      formdata['refund_requests[type]'] = 'order_detail';
    }
    var response = await cancelOrder(formdata);
    if (response.data.status == 'success') {
      await this.getOrders(this.state.where);
    }
  }

  async getOrders(where){
    // this.checkIsUserValid();
  	this.setState({ loader: true });
    var orders = await getOrders(where);
    if (orders.data.status == 'success') {
      console.log(orders);
      this.setState({orders:orders.data.data, loader: false,reload:false});
      this.getBase64Images(orders.data.data);
    }
  }

  // async checkIsUserValid(){
  //   if(mobile == true){
  //     var skipLogin = await AsyncStorage.getItem('skipLogin');
  //     if(skipLogin != "1"){
  //       var userData = await AsyncStorage.getItem('userData');
  //       if(userData == null)
  //         this.props.navigation.navigate('Login')
  //       userData = JSON.parse(userData);
  //       var validUser = await checkUserValid(userData.authToken);
  //       if(validUser.data.status != 'success')
  //         this.props.navigation.navigate('Login')
  //     }else{
  //       this.props.navigation.navigate('Login')
  //     }
  //   }
  // }

  async getBase64Images(orders){
    orders.map((order,index)=>{
      order.order_details.map((order_detail, index) => {
        if (typeof order_detail.item_images.name != 'undefined' && order_detail.item_images.name != null) {
          var medium_image = this.props.settings.image_url+'products/images/medium/'+order_detail.item_images.name;
          this.setImageInState(medium_image, order_detail.id);
        } else {
          this.setImageInState(this.props.settings.image_url+'/application_images/original/'+this.props.settings.error_image, order_detail.id);
        } 
      })
    })    
  }
 
  async setImageInState(base64,id){
    this.images = this.state.images;
    this.images[id] = base64;
    this.setState({ images: this.images });
  }

  async setFilterUrl(value) {
    var where = this.state.where+'&filter='+value;
    this.setState({status:value})
    this.getOrders(where);
  }
}
