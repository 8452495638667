import React from 'react';
import { Container, Row, Col, Carousel, Button, Image } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import AvantiNewsLetter from "./AvantiNewsLetter";
import BergamontNewsLetter from './BergamontNewsLetter';
import ScottNewsLetter from "./ScottNewsLetter";


class NewsLetter extends CoreHome {
  constructor() {
    super()
    this.state = {
      activeTab: 0
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  handleTabClick(index) {
    this.setState({ activeTab: index });
  }

  render() {
    const { activeTab } = this.state;
    return (
      <React.Fragment>
        <div style={{ paddingTop: "112px" }} >
          <div className='d-flex flex justify-content-center p-3' style={{ backgroundColor: "black", color: 'white', }} >
            <h2 style={{ fontWeight: "600" }}>NEWSROOM</h2>
          </div>

          <div className='d-flex flex justify-content-center mt-1 '>
            <h6 className='' style={{ color: 'black', fontSize: "20px", marginTop: "18px", fontWeight: "500", textAlign: "center" }}>For any press and media queries, write to us at cs@sportnetwork.in</h6>
          </div>
        </div>



        <ul className="nav nav-tabs d-flex justify-content-center mt-5">
          <li className="active mr-3" onClick={() => this.handleTabClick(0)}>
            <a className=" text-uppercase bold" style={{ textAlign: "center", backgroundColor: activeTab === 0 ? "#ffb300" : "", padding: "6px 14px" }} data-toggle="pill" href="#AvantiNewsLetter">Avanti</a>
          </li>

          <li className=" mr-3" onClick={() => this.handleTabClick(1)}>
            <a className=" text-uppercase bold" style={{ textAlign: "center", backgroundColor: activeTab === 1 ? "#ffb300" : "", padding: "6px 14px" }} data-toggle="pill" href="#BergamontNewsLetter">Bergamont</a>
          </li>

          <li className="mr-3" onClick={() => this.handleTabClick(2)}>
            <a className="text-uppercase bold" style={{ textAlign: "center", backgroundColor: activeTab === 2 ? "#ffb300" : "", padding: "6px 14px" }} data-toggle="pill" href="#ScottNewsLetter">Scott</a>
          </li>
        </ul>


        <div className="tab-content">
          <div id="AvantiNewsLetter" className="tab-pane fade active show" >
            <AvantiNewsLetter />
          </div>
          <div id="BergamontNewsLetter" className="tab-pane fade" >
            <BergamontNewsLetter />
          </div>
          <div id="ScottNewsLetter" className="tab-pane fade" >
            <ScottNewsLetter />
          </div>
        </div>

      </React.Fragment>
    )
  }
}

export default (NewsLetter);
