import React from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import LogoImg from '../../../../web/assets/images/logos/shilpi.png'; 
import CoreUser from '../../../../core/components/users/CoreUser';


class Register extends CoreUser {	
	render(){
		return (
			<React.Fragment> 
				<div className="login">	
					<div className="main_section">
						<Container>
							<div className="mb-5">
								<a href="/"><img src={LogoImg} width="100px" /></a>
							</div>							
							<Row>
								<Col lg={6} className="mx-auto">
									<div className="mb-4">
										<h5 className="bold text-uppercase black text-center">Edit Profile</h5>
									</div>
									<Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4">Name :</Form.Label>
									    <Col lg="8">
									    	<Form.Control type="text" className="mb-1" />
											</Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">Email ID :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.email_id} onChange={(e)=>this.setState({email_id:e.target.value,errorRegister:''})}type="email" />	
												<span className="red">{(this.state.errorRegister['customers[email_id]']!= "" && typeof this.state.errorRegister['customers[email_id]']!= 'undefined')?this.state.errorRegister['customers[email_id]']:""}</span>
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">Phone Number :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.mobile_no} onChange={(e)=>this.setState({mobile_no:e.target.value,errorRegister:''})}type="text" />		
									    	<span className="red">{(this.state.errorRegister['customers[mobile_no]']!= "" && typeof this.state.errorRegister['customers[mobile_no]']!= 'undefined')?this.state.errorRegister['customers[mobile_no]']:""}</span>
											</Col>							   
									  </Form.Group>
									  <Form.Group as={Row} >
									    <Form.Label column lg="4">Address :</Form.Label>
									    <Col lg="8">
									    	<Form.Control as="textarea" rows={4} />		
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">GST Number :</Form.Label>
									    <Col lg="8">
									    	<Form.Control type="text" />		
									    </Col>							   
									  </Form.Group>
									</Form>
									<div className="text-center mt-5">
										<Link to='/login'>
											<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>Back</Button>
										</Link>
										<Button className="btn btn-md" style={this.props.styles.primary_btn}>Submit</Button>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {    
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(Register));