import React from "react"
import ContentLoader from "react-content-loader"

const SideLoader = (props) => (
  <ContentLoader 
    speed={1}
    width={(typeof props.width != 'undefined'?props.width:400)}
    height={(typeof props.height != 'undefined'?props.height:450)}
    viewBox={"0 0 "+(typeof props.width != 'undefined'?props.width:400)+" "+(typeof props.height != 'undefined'?props.height:450)}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="9" y="14" rx="0" ry="0" width={(typeof props.width != 'undefined'?props.width:250)} height={(typeof props.height != 'undefined'?props.height:450)} />
  </ContentLoader>
)

export default SideLoader