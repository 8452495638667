import React from 'react';
import {Col, Row, Card} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class UserProfileCard extends React.Component {
  
  render() {
    const { t,user } = this.props;
    return (
      <Col lg={6} className="mb-4 mb-lg-4">                 
        <Card className="shadow-sm">
          <Card.Body>
            <Row className="mb-2">
              <Col lg={12}>
                <h2 className="small_heading black bold text-capitalize mb-0">{user.name}</h2>
              </Col>                        
            </Row>
            <hr/>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('contactNo')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold">{(user.contact_no)?user.contact_no:'-'}</h6>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('email')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold">{user.email_id}</h6>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col sm={5}>
                <h6 className="">{t('gst')} :</h6>
              </Col>
              <Col sm={7}>
                <h6 className="black bold text-end">{user.gst_number?user.gst_number:'-'}</h6>
              </Col>
            </Row>                      
          </Card.Body>
        </Card>
      </Col>    
    );
  }
}
export default withTranslation()(UserProfileCard);



