import React from 'react';
import { Container, Row, Col, Carousel, Button, Image } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import ScottArticleOne from '../../../assets/images/media-press-page/scott-article-one.png';
import ScottArticleTwo from '../../../assets/images/media-press-page/scott-article-two.png';
import ScottArticleThree from '../../../assets/images/media-press-page/scott-article-three.png';
import ScottArticleFour from '../../../assets/images/media-press-page/scott-article-four.png';
import ScottArticleFive from '../../../assets/images/media-press-page/scott-article-five.png';
import ScottArticleSix from '../../../assets/images/media-press-page/scott-article-six.png';

class ScottNewsLetter extends CoreHome {
    render() {
        return (
            <React.Fragment>

                {/* <div className='d-flex flex justify-content-center mt-5 p-3' style={{backgroundColor:"black",color:'white'}}>
                  <h2 style={{fontWeight:"600" }}>NEWSROOM</h2>
              </div>  
               */}
                {/* <div className='d-flex flex justify-content-center mt-1 '>
                  <h6 className='' style={{color:'black',fontSize:"20px",fontWeight:"500",textAlign:"center" }}>For any press and media queries, write to us at cs@sportnetwork.in</h6>
              </div> */}

                <div className='d-flex flex justify-content-center flex-wrap p-2 ' style={{ margin: "20px" }}>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://www.moneycontrol.com/news/luxury-lifestyle/moneycontrol-recommends-january-8-2023-9828521.html">
                        <div className="card NewsLetterCard " style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ScottArticleOne} alt="Card image cap" />
                            <div className="card-body">
                                <div style={{ margin: "0%", padding: "0%" }}>
                                    <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium", }}><b>Pedal Power: SCOTT Scale 940</b></h6>

                                    <div class="card-footer">
                                        <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Money Control</small></p>
                                        <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://www.financialexpress.com/lifestyle/a-fitter-healthier-and-happier-you-in-2023-top-trends-and-innovative-techniques/2932767/">
                        <div className="card NewsLetterCard" style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "16.8rem" }} src={ScottArticleTwo} alt="Card image cap" />
                            <div className="card-body">
                                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Few Fitness Trends for 2023 </b></h6>

                                <div class="card-footer">

                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Financial Express</small></p>
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                                </div>

                            </div>
                        </div>
                    </a>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://www.sportskeeda.com/sports/news-vinolee-ramalingam-mother-11-year-old-son-thanjavur-trend-setter-extreme-sports-india">
                        <div className="card NewsLetterCard" style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "16.6rem" }} src={ScottArticleThree} alt="Card image cap" />
                            <div className="card-body">
                                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b> Vinolee Ramalingam, is a trend setter in extreme sports in India </b></h6>

                                <div class="card-footer">
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Sportskeeda</small></p>
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 20223</small></p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://turbocharged.in/gearwatchview/164/scott-motocross-body-protector">
                        <div className="card NewsLetterCard" style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ScottArticleFour} alt="Card image cap" />
                            <div className="card-body">
                                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Scott Motocross Body Protector</b></h6>

                                <div class="card-footer">

                                    <p className="card-text" style={{ textAlign: "center" }} ><small class="text-muted">By: Turbo Charged</small></p>
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://www.gqindia.com/look-good/content/an-experts-guide-on-preparing-for-an-ironman-triathlon">
                        <div className="card NewsLetterCard" style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ScottArticleFive} alt="Card image cap" />
                            <div className="card-body">
                                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>An experts guide on preparing for an Ironman Triathlon</b></h6>

                                <div class="card-footer">


                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: GQ India</small></p>
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                                </div>
                            </div>
                        </div>
                    </a>

                    <a target="_blank" style={{ textDecoration: "none" }} href="https://www.gqindia.com/look-good/content/an-experts-guide-on-preparing-for-an-ironman-triathlon">
                        <div className="card NewsLetterCard" style={{}}>
                            <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ScottArticleSix} alt="Card image cap" />
                            <div className="card-body">
                                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b> In Ultra Cycling and Ironman races you need the willingness to suffer: Dr Amit Samarth</b></h6>

                                <div class="card-footer">


                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: CNBC TV</small></p>
                                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                                </div>
                            </div>
                        </div>
                    </a>





                </div>


            </React.Fragment>
        )
    }
}

export default (ScottNewsLetter);
