import React, { Component } from 'react'
import MasterProduct from '../products/MasterProduct'

import { getWishList, getImageContent, placeOrder } from '../../helpers/ServicesHelper';
import { getImageUrl, getImagePath } from  '../../helpers/OperationsHelper';

export default class CoreWishLists extends MasterProduct {
  constructor(props){
		super(props);
		this.state={
			wishList:[],
			compareProducts:[],
      quantity:1,
			subTotal:0,
			itemsCount:0,
			images: {},
			unit: '',
			loader: true,
			currency: '',
			isFetching:false,
			moveToCartModalVisibility: false,
			selectedItem: '',
			remark: '',
		}
		this.images={};
	}
	
	async componentDidMount(){
		window.history.pushState(null, null, window.location.pathname);
		await this.getWishList()
		await this.getCompareProducts();
		this.setState({loader:false})
	}

	async getWishList(){
    var wishList = await getWishList();
		if (wishList.data.status == 'success') {
			this.setState({wishList:wishList.data.data,loader: true,isFetching:false})
			this.getSubtotal();
			this.getItemsCount();	
			this.convertToBlob(wishList.data.data);
		}
		this.setState({ loader: false ,isFetching:false});
	}

	async convertToBlob(wishlists){
    wishlists.map((wishlist,index)=>{
      if (wishlist.item_details.item_images != null && typeof wishlist.item_details.item_images.name != 'undefined' && wishlist.item_details.item_images.name != null) {
        this.fetchBlob(wishlist.id, wishlist.item_details.item_images.id,wishlist.item_details.item_images.name);
      } else {
        var imageUrl = this.props.settings.image_url+'/application_images/original/'+this.props.settings.error_image;
        this.setImageInState(imageUrl, wishlist.id);
      }
    })    
  }

  async fetchBlob(id, item_id,image_name) {  	
  	var medium_image = this.props.settings.image_url+'/products/images/medium/'+image_name;
  	this.setImageInState(medium_image, id);
  }

  async setImageInState(base64,id){
  	this.images = this.state.images;
    this.images[id] = base64;
     if (Object.keys(this.images).length == this.state.wishList.length) {
      this.setState({ images: this.images });
    }
  }

  async moveItemToCart(id){
		await this.moveToCart(id);
		await this.getWishList();
	}

	async deleteWishListItem(id){
		await this.deleteWishList(id);
		await this.getWishList();
	}

	getSubtotal(){
		var sum=0
		this.state.wishList.map((total, index)=>{
			if (index == 0) 
				this.setState({ unit: total.product_types.unit, currency: total.product_types.currency });
			
			if(typeof total.product_types !='undefined' && total.product_types !='')
				sum+=(total.quantity * total.product_types.field_value);
    })
		
    this.setState({subTotal:sum})
	}

	getItemsCount(){
		var sum=0
		this.state.wishList.map((total)=>{
			sum+=parseInt(total.quantity)
		})
		this.setState({itemsCount:sum})
	}

	setMoveToCartModalVisibility(value, id='') {
    if (value == true)
      this.setState({ selectedItem: id })
    this.setState({ moveToCartModalVisibility: value });
  }
}
