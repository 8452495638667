// import React from 'react';
// import { Helmet } from 'react-helmet';
// import { getMetaTags } from '../../core/helpers/ServicesHelper';

// class MetaTags extends React.Component {
// 	constructor(props){
// 		super(props);
//         this.state = { metatags: [] }
// 	}
// 	async componentDidMount(){
// 		console.log("Meta Tags mount")
//         await this.getMetaTags();
//     }

// 	async componentDidUpdate(prevProps, prevState){
// 		console.log("meta tag state: ", this.state.metatags);
// 		if (prevProps.match.url !== this.props.match.url || prevProps.location.search !== this.props.location.search) {
// 			await this.getMetaTags();
// 		}
// 	}

// 	async getMetaTags(params) {
// 		let routesArr = this.props.location.pathname.split('/');
// 		// let where = 'where[page_name]='+routesArr[1];
// 	    // if(typeof routesArr[2] != 'undefined') 
// 		// 	where = 'where[page_name]='+routesArr[1]+'/'+routesArr[2];
// 	    // if (typeof routesArr[3] != 'undefined')
// 	    // 	where = 'where[page_name]='+routesArr[1]+'/'+routesArr[2]+'/'+routesArr[3];
// 		let route = this.props.location.pathname;
// 		let where = 'where[page_name]='+route;
// 		try{
// 			let metatags = await getMetaTags(where);
// 			console.log("metatags", metatags);
// 			if (metatags.data.status === 'success'){
// 				// we got a data for metatag
// 				console.log("metatag data for current page : ", metatags.data.data);
// 				await this.setState({ metatags: metatags.data.data });
// 			} 
// 			else{
// 				console.log("Metatags data fetched gave error status:  ", metatags.data.status, " metatags data fetched : ", metatags.data.data);
// 			}
// 			// if (metatags.data.status === 'success') {
// 			// 	await this.setState({ metatags: metatags.data.data });
// 			// 	console.log('Updated metatags:', this.state.metatags)
// 			// } else {
// 			// 	console.error('Error fetching meta tags:', metatags.data.status);
// 			// }
// 		} catch (e){
// 			console.log(`error fetching metaTags from api: ${e}`);
// 		}
// 		/*
// 			var metatags = await getMetaTags('where[page_name]='+this.props.location.pathname);
// 			if (metatags.data.status == 'success') {
// 				console.log('inside success getMetaTags');
// 				console.log("metatags data: ", metatags.data.data)
// 			this.setState({ metatags: metatags.data.data });
// 			} else {
// 				console.log('inside error getMetaTags');
// 			console.log(metatags.data.status);
// 			}
// 		*/

// 		// console.log(`inside get meta tags function, current path: ${this.props.location.pathname}`)
// 		// console.log(`routesArr: [${routesArr.length > 0 ? routesArr.join(", ") : "/"}]`)
// 		// console.log(`routesArr[1]: ${routesArr[1]}`)
// 		// console.log(`routesArr[2]: ${routesArr[2]}`)
// 		// console.log(`routesArr[3]: ${routesArr[3]}`)
// 		// console.log(`where: ${where}`)
// 		// // return null;
// 	}
// 	render(){

// 		const { metatags } = this.state;
// 		console.log(metatags)
// 		console.log(metatags.length);
// 		const currentUrl = window.location.href;

// 		return (
// 			<Helmet>
// 						{metatags.length > 0 ?
//             metatags.map((metatag, index) => (
//               <title key={index}>{metatag.meta_title}</title>
//             )):
//             <title>Sport Network</title>
//           }
//           {metatags.length > 0 && 
//             metatags.map((metatag, index) => (
//               <meta key={index} name="keywords" content={metatag.meta_title} />
//             ))
//           }
//           {metatags.length > 0 && 
//             metatags.map((metatag, index) => (
//               <meta key={index}  name="description" content={metatag.description} />
//             ))
//           }
//           {metatags.length > 0 && 
//             metatags.map((metatag, index) => (
//               <meta key={index} name="robots" content={metatag.robot_name} />
//             ))
//           }
//           <meta property="og:image" content="https://admin.sportnetwork.in/assets/luma/images/sport-logo.png" /> 
//           {metatags.length > 0 && 
//             metatags.map((metatag, index) => (
//               <meta property="og:title" content={metatag.meta_title} />
//             ))
//           }
//           {metatags.length > 0 && 	
//             metatags.map((metatag, index) => (
//               <meta property="og:description" content={metatag.description} />
//             ))
//           }
//           <meta property="og:type" content="website" />
//           <meta property="og:url" content={currentUrl} />
//           <link rel="canonical" href={currentUrl} />
          
//           {metatags.length > 0 && 
//             metatags.map((metatag, index) => (
// 	          <script type="application/ld+json">
// 		          {`
// 						    {
// 						      "@context": "http://schema.org",
// 						      "@type": "website",
// 						      "name": "${metatag.meta_title}"
// 						      "description": "${metatag.description}",
// 						      "url":"${currentUrl}"
// 						    }
// 						  `}
// 					  </script>
// 					  ))
//           }
// 			</Helmet>
// 		);
// 	}
// }

// export default MetaTags;

import React from 'react';
import { Helmet } from 'react-helmet';
import { getMetaTags } from '../../core/helpers/ServicesHelper';

class MetaTags extends React.Component {
	constructor(props) {
		super(props);
		this.state = { metatags: [] };
	}

	async componentDidMount() {
		console.log("Meta Tags mount");
		await this.getMetaTags();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.match.url !== this.props.match.url || prevProps.location.search !== this.props.location.search) {
			await this.getMetaTags();
		}
	}

	async getMetaTags() {
		let route = this.props.location.pathname;
		let where = 'where[page_name]=' + route;
		try {
			let metatags = await getMetaTags(where);
			console.log("metatags", metatags);
			if (metatags.data.status === 'success') {
				console.log("metatag data for current page: ", metatags.data.data);
				this.setState({ metatags: metatags.data.data });
			} else {
				console.log("Metatags data fetched gave error status: ", metatags.data.status, " metatags data fetched: ", metatags.data.data);
			}
		} catch (e) {
			console.log(`error fetching metaTags from api: ${e}`);
		}
	}

	render() {
		const { metatags } = this.state;
		const currentUrl = window.location.href;

		return (
			<Helmet>
				{metatags.length > 0 ? (
					metatags.map((metatag, index) => (
						<title key={index}>{metatag.meta_title}</title>
					))
				) : (
					<title>Sport Network</title>
				)}
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<meta key={index} name="keywords" content={metatag.meta_title} />
					))}
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<meta key={index} name="description" content={metatag.description} />
					))}
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<meta key={index} name="robots" content={metatag.robot_name} />
					))}
				<meta property="og:image" content="https://admin.sportnetwork.in/assets/luma/images/sport-logo.png" />
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<meta key={index} property="og:title" content={metatag.meta_title} />
					))}
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<meta key={index} property="og:description" content={metatag.description} />
					))}
				<meta property="og:type" content="website" />
				<meta property="og:url" content={currentUrl} />
				<link rel="canonical" href={currentUrl} />
				{metatags.length > 0 &&
					metatags.map((metatag, index) => (
						<script type="application/ld+json" key={index}>
							{`
								{
									"@context": "http://schema.org",
									"@type": "website",
									"name": "${metatag.meta_title}",
									"description": "${metatag.description}",
									"url": "${currentUrl}"
								}
							`}
						</script>
					))}
			</Helmet>
		);
	}
}

export default MetaTags;
