import React, { useEffect } from 'react';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';

import ProductOne from '../../../assets/images/products/spark-rc-cycle.png';
import Loader from '../../../assets/images/icons/circle-loader.gif';

const Pagination = props => {
  var {productCount,productTypeFilters,changeSort} = props;
  // console.log('productCount: ', productCount)
  return (
    <Row className="sorting_bar">
      <Col md={6}>
        <div className="total_result">
        {(isNaN(parseInt(productCount)) === false)?
          <p className="mb-0">{(productCount <= 1)?productCount+' Product':productCount+' Products'}</p>:
          <p className="mb-0">{productCount}</p>
        }
        </div>        
      </Col>
     
      <Col md={6} className="d-none d-md-block text-right">
        <div className="p-0 text-right d-flex align-items-center justify-content-end">
          <React.Fragment>
            <select className="form-control pagination_select custom-select" onChange={(e,sortName)=>changeSort(e.target.value,e.target.name)}>
              {productTypeFilters?.map((value,index)=>((value.type === 'range' || value.text === 'range')&&
                <React.Fragment key={index}>
                  <option value={'&order_column=CAST(fl_item_product_type_fields.field_value as unsigned)&order_by=desc&is_filter_applied=1&field_sequence='+value.field_sequence}>{value.name} :- High to Low</option>
                  <option value={'&order_column=CAST(fl_item_product_type_fields.field_value as unsigned)&order_by=asc&is_filter_applied=1&field_sequence='+value.field_sequence}>{value.name} :- Low to High</option>
                </React.Fragment>
              )) 
              }
            </select> 
          </React.Fragment> 
        </div>
      </Col>
    </Row>
  );
};

export default Pagination;