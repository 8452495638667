import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import Header from '../../../../src/web/components/headers/scott/Header';
import Footer from '../../../../src/web/components/footers/scott/Footer';
import { Provider } from 'react-redux';
import store from '../../../store';

const CorePrivateRoute = ({component: Component, ...rest}) => {
  return (
    <div>
      <Route {...rest} render={props => (
        (typeof cookie.load('authToken') != 'undefined') ? 
          (typeof rest.header != 'undefined' && rest.header == false) ? 
            <Component {...props} />
          :
          <div>
            <header className="header fixed-top"> 
              <Provider store={store}>
                <Header {...props}/>
              </Provider>  
            </header>
            <Component {...props} />
            <Footer/>
          </div>          
        : <Redirect to="/login" />
      )} />
    </div>
  );
};

export default CorePrivateRoute;