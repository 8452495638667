import React from 'react';
import {Container, Row, Col, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Redirect } from 'react-router';

import SportsImgOne from '../../../assets/images/banners/sports_img1.jpg'; 
import SportsImgTwo from '../../../assets/images/banners/sports_img2.jpg'; 
import SportsImgThree from '../../../assets/images/banners/sports_img3.jpg'; 
import SportsImgFour from '../../../assets/images/banners/sports_img4.jpg'; 

import LogoImg from '../../../assets/images/logos/scott-logo.png'; 
import GoogleIcon from '../../../assets/images/icons/google.png'; 
import FacebookIcon from '../../../assets/images/icons/facebook.png'; 

import CoreDealer from '../../../../core/components/dealer/CoreDealer';

import Button from 'react-bootstrap-button-loader';

class DealerLogin extends CoreDealer {	

	responseGoogle = (response) => {
		console.log(response);
	}

	responseFacebook = (response) => {
		console.log(response);
	}

	async componentDidMount(){
		var otpCount = localStorage.getItem('otpCount');
		if(otpCount != null || otpCount > 0) {
			this.props.setOtpCount(otpCount);
			this.time();
		}

		var param = new URLSearchParams(this.props.location.search).get("checkout")
		if(param == 1)
			this.setState({'isCommingFromCheckout':1})

		await this.checkLoginType();
	}

	async checkLoginType() {
		const paramOne=this.props.match.params.paramOne;
		if (typeof paramOne != 'undefined' && paramOne == 'access-token') {
			this.setState({ isAccessTokenLogin: true });
		} else {
			this.setState({ isAccessTokenLogin: false });
		}
	}

	render(){
		const { t } = this.props;
		return (
			(this.state.redirectTo != "")?<Redirect to={this.state.redirectTo}/>:
			<React.Fragment> 
				<div className="login dealer_login">	
					<div className="main_section">
						<Container>
							<Col lg={5} sm={9} xs={12} className="mx-auto mt-4">																
								<Card>
									<Card.Body className="py-4">		
										<div className="text-center mb-4">
											<a href=""><img src={this.props.settings.image_url+'/application_images/original/'+this.props.settings.dealer_header_logo} /></a>
										</div>														
										<h5 className="black mt-4 text-center">{t('welcomeMsg')} <span className="blue">{t('dealerNetwork')}</span></h5>
										<div className="px-2">
											<Form className="fields-group-md mt-4">
												{this.state.isAccessTokenLogin == false ? 
													<React.Fragment>
														<Form.Group>
												    	<Form.Control type="text" className="mb-1" value={this.state.loginUser} onChange={(e)=>this.setState({loginUser:e.target.value,error:''})} disabled={this.state.isOtpClick} placeholder="Email ID"/>	
												    	<span className="red">{(this.state.error['login[username]']!= "" && typeof this.state.error['login[username]']!= 'undefined')?this.state.error['login[username]']:""}</span>
															<span className="red">{(this.state.error['send_otp[username]']!= "" && typeof this.state.error['send_otp[username]']!= 'undefined')?this.state.error['send_otp[username]']:''}</span>
															<Link to={'#'} className="text-underline text-right d-block" style={{fontSize:'0.95rem'}} onClick={()=>this.checkOtp()}>{this.state.otpText}</Link>
													  </Form.Group>
													  <Form.Group>
												    	<Form.Control type="password" value={this.state.loginPassword} onChange={(e)=>this.setState({loginPassword:e.target.value,error:''})} placeholder="Password / OTP" />	
															<span className="red">{(this.state.error['password'] != "" && typeof this.state.error['password']!='undefined' )?this.state.error['password']:""}</span>	
												    	<span class ="red">{(this.state.error['otp']!="" && typeof this.state.error['otp']!='undefined')?this.state.error['otp']:""}</span>
												    	<Link to="/forgot-password" className="d-block text-right text-underline mt-1">{t('forgotPassword')}?</Link>					   
													  </Form.Group> 
													 </React.Fragment> : 
													 <React.Fragment>
												 		<Form.Group>
												    	<Form.Control type="text" className="mb-1" value={this.state.accessToken} onChange={(e)=>this.setState({accessToken:e.target.value, error:''})} placeholder="Access Token"/>	
											    		<span className="red">{(this.state.error['login[admin_access_token]']!= "" && typeof this.state.error['login[admin_access_token]']!= 'undefined')?this.state.error['login[admin_access_token]']:""}</span>						   
													  </Form.Group>
													 </React.Fragment>
												}
											{(this.state.otpSent)&&<span className="mx-auto d-block mt-3 text-right green ">{"OTP sent to your registered email "}</span>}	
											</Form>
											<div className="mt-3">
												<Button loading={this.state.loginButtonLoader} className="btn mx-auto d-block circle_btn" onClick={()=>this.checkCredential()} style={this.props.styles.primary_btn}>{t('signIn')}</Button>
												<div className="text-center mt-4"><a href="https://customerconnect.sportnetwork.in/dealership_enquiries" className="text-underline black">{t('dealerShip')}</a></div>
											</div>
											
										</div>
									</Card.Body>
								</Card>
							</Col>
						</Container>
					</div>				
					
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    otpCount: state.otpCount,
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    decrementOtpCount: () => dispatch({ type: 'DECREMENT_OTP_COUNT'}),
    setOtpCount: (otpCount) => {dispatch({ type: 'SET_OTP_COUNT', payload: otpCount})},
    setUser: (user) => {dispatch({ type: 'SET_USER', payload: user})}
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DealerLogin));