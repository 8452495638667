import React, { Component } from 'react'

import { getCustomerHomeBanner } from '../../helpers/ServicesHelper'

export default class CoreHome extends Component {
  constructor(props){
    super(props);
    this.state={
      homeBanner:[],
      staticHome:false
    }
  }

  componentDidMount(){
    this.getHomeBanner();
  } 

  async getHomeBanner(){
    var banner = await getCustomerHomeBanner();
    if (banner.data.status == 'success') {
      this.setState({homeBanner:banner.data.data.home_banners})
    }
  }
}
