import React from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { connect } from "react-redux";

import { getImageUrl, numberWithFloat, getParentRoute, dealersPortal } from '../../../../core/helpers/OperationsHelper';
import loader from '../../../assets/loader/circle-loader.gif';
import VariationModal from './VariationModal';

class Product extends React.Component {
  addRemoveProductFromCatalog(productId, id = "") {
    var catalogId = this.props.catalogId;
    this.props.addRemoveProductFromCatalog(productId, catalogId, id);
  }

  //on click show variation modal
  async showVariationModalFunction(productId, productName, type = 1, isDelete = 0) {
    // console.log("clicked")
    await this.props.setCurrentProductParams(productId, productName);
    if (dealersPortal)
      await this.props.getItemInventory(productId, 1, type, isDelete);
    else
      await this.props.getItemVariations(productId, type, isDelete);
  }

  //on click add remove wishlist
  async addRemoveWishlist(productId, productName, isDelete = 0) {
    this.showVariationModalFunction(productId, productName, 0, isDelete);
  }

  //on click add remove cart
  async addRemoveCart(productId, productName) {
    this.showVariationModalFunction(productId, productName, 1);
  }

  // on click product redirection listing to detail page
  async handleRedirection(product) {
    var url = '';
    var parentRoute = await getParentRoute(this.props.route.url);
    // console.log(parentRoute);
    const paramOne = this.props.route.params.paramOne;

    const paramTwo = this.props.route.params.paramTwo;

    const paramThree = this.props.route.params.paramThree;

    const paramFour = this.props.route.params.paramFour;

    if (parentRoute === 'pl') {
      url = '/pp'

      if (typeof paramOne != 'undefined')
        url += '/' + paramOne;

      if (typeof paramTwo != 'undefined')
        url += '/' + paramTwo;

      if (typeof paramThree != 'undefined')
        url += '/' + paramThree;
    }

    if (parentRoute === 'bpl') {
      url = '/bpp'

      if (typeof paramOne != 'undefined')
        url += '/' + paramOne;
    }

    if (parentRoute === 'cpl') {
      url = '/cpp';

      if (typeof paramOne != 'undefined')
        url += '/' + paramOne;
    }

    url = url + '/' + product.slug;
    this.props.setRedirectUrl(url)

    // window.open(url);
  }

  render() {
    var { product } = this.props;
    // console.log(product);
    const { t } = this.props;
    // console.log(this.props.buttonIcon)
    return (
      (this.props.states.redirectUrl != "") ? <Redirect to={this.props.states.redirectUrl} /> :
        <Col lg={this.props.buttonIcon} md={3} sm={4} xs={6}>
          <div className="product_card ">
            <div className="product_inside text-left">
              <div className="product_img">
                {(dealersPortal == true) &&
                  <a onClick={() => this.handleRedirection(product)} href="" className={(product.dealer_stock_status != 1) ? "disabled_product" : ""}>

                    {((typeof this.props.states.imageLoading[product.id] != 'undefined' && this.props.states.imageLoading[product.id] === false) ?
                      <img src={this.props.images[product.id]} className="product_img img-fluid" /> :
                      <img src={loader} onLoad={() => this.props.handleImageLoaded(product.id)} className="product_img img-fluid" />)
                    }
                    {/* <img src="http://staging-sportnetwork.ascratech.com/uploads/product/images/original/mfqi6U2OCyLFjlAh.png" className="product_img img-fluid" /> */}
                    {(dealersPortal == true && product.dealer_stock_status != 1) &&
                      <div className="outstock_ribbon"><p>Out of stock</p></div>
                    }

                    {(dealersPortal == false && product.customer_stock_status != 1) &&
                      <div className="outstock_ribbon"><p>Out of stock</p></div>
                    }
                  </a>
                }
                {(dealersPortal == false) &&
                  <a onClick={() => this.handleRedirection(product)} href="" className={(product.customer_stock_status != 1) ? "disabled_product" : ""}>
                    {((typeof this.props.states.imageLoading[product.id] != 'undefined' && this.props.states.imageLoading[product.id] === false) ?
                      <img src={this.props.images[product.id]} className="product_img img-fluid" /> :
                      <img src={loader} onLoad={() => this.props.handleImageLoaded(product.id)} className="product_img img-fluid" />)
                    }
                    {/* <img src="http://staging-sportnetwork.ascratech.com/uploads/product/images/original/mfqi6U2OCyLFjlAh.png" className="product_img img-fluid" /> */}
                    {(dealersPortal == true && product.dealer_stock_status !== "1") &&
                      <div className="outstock_ribbon"><p>Out of stock</p></div>
                    }

                    {(dealersPortal == false && product.customer_stock_status !== "1") &&
                      <div className="outstock_ribbon"><p>Out of stock</p></div>
                    }
                  </a>
                }
                {(Object.keys(this.props.user).length == 0 || Object.keys(this.props.user).length > 0 && (this.props.user.user_type == 1 || this.props.user.user_type == 2)) && (
                  ((this.props.settings.add_to_wishlist_from_list == 'Yes') && (
                    <div>
                      {(typeof this.props.wishlistProducts != "undefined" &&
                        typeof this.props.wishlistProducts[product.id] != 'undefined' &&
                        this.props.wishlistProducts[product.id].added_into_transaction === 1) ?
                        <a onClick={() => this.addRemoveWishlist(product.id, product.product_name, 1)} className="action_icon wishlist">
                          <i className="fas fa-heart"></i>
                        </a> :
                        <a onClick={() => this.addRemoveWishlist(product.id, product.product_name, 0)} className="action_icon wishlist">
                          <i className="fal fa-heart"></i>
                        </a>
                      }
                    </div>
                  ))
                )}
              </div>

              <div className="d-flex mt-1" style={{ minHeight: '38px' }}>
                <div className="flex-fill" style={{ position: 'relative' }}>
                  {(product.display_tag != '' && product.display_tag != null) &&
                    <a href="" className="new_link">{product.display_tag != '' ? product.display_tag : ''}</a>
                  }
                  <a onClick={() => this.handleRedirection(product)} href="" className="product_label mt-1 d-block">
                    <p className="mb-0">{product.product_name}</p>
                  </a>
                </div>

                {(Object.keys(this.props.user).length > 0 && this.props.user.user_type == 0 && this.props.catalogId != '') && (
                  <div>
                    <a
                      onClick={
                        () => {
                          console.log("product added");
                          this.addRemoveProductFromCatalog(product.id, (typeof this.props.catalogProducts != "undefined" &&
                            this.props.catalogProducts != "" &&
                            typeof this.props.catalogProducts[product.id] != 'undefined' &&
                            this.props.catalogProducts[product.id].id != "") ? this.props.catalogProducts[product.id].id : "")
                        }}
                      className="action_icon"
                    >
                      {(typeof this.props.catalogProducts != "undefined" &&
                        typeof this.props.catalogProducts[product.id] != 'undefined' &&
                        this.props.catalogProducts[product.id].is_added_catalog === 1) ? <i className="fas fa-minus-circle"></i> : <i className="fas fa-plus-circle"></i>
                      }
                    </a>
                  </div>
                )}

                {(Object.keys(this.props.user).length == 0 || Object.keys(this.props.user).length > 0 && (this.props.user.user_type == 1 || this.props.user.user_type == 2)) && (
                  (this.props.settings.add_to_cart_from_list == 'Yes' && product.disable_ordering == 0 && dealersPortal == true && product.dealer_stock_status == 1) && (
                    <div>
                      {(typeof this.props.cartProducts != "undefined" &&
                        typeof this.props.cartProducts[product.id] != 'undefined' &&
                        this.props.cartProducts[product.id].added_into_transaction === 1) ?
                        <a onClick={() => this.showVariationModalFunction(product.id, product.product_name, 1, 1)} className="action_icon">
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                        :
                        <a onClick={() => this.showVariationModalFunction(product.id, product.product_name, 1, 0)} className="action_icon">
                          <i className="fal fa-shopping-cart"></i>
                        </a>
                      }
                    </div>
                  )
                )}

                {(Object.keys(this.props.user).length == 0 || Object.keys(this.props.user).length > 0 && (this.props.user.user_type == 1 || this.props.user.user_type == 2)) && (
                  (this.props.settings.add_to_cart_from_list == 'Yes' && product.disable_ordering == 0 && dealersPortal == false && product.customer_stock_status == 1) && (
                    <div>
                      {(typeof this.props.cartProducts != "undefined" &&
                        typeof this.props.cartProducts[product.id] != 'undefined' &&
                        this.props.cartProducts[product.id].added_into_transaction === 1) ?
                        <a onClick={() => this.showVariationModalFunction(product.id, product.product_name, 1, 1)} className="action_icon">
                          <i className="fas fa-shopping-cart"></i>
                        </a>
                        :
                        <a onClick={() => this.showVariationModalFunction(product.id, product.product_name, 1, 0)} className="action_icon">
                          <i className="fal fa-shopping-cart"></i>
                        </a>
                      }
                    </div>
                  )
                )}

              </div>
              {product.product_type_currency == 1 && (
                <p className="mb-0">
                  <i className="fal fa-rupee-sign" style={{ fontSize: '11px', verticalAlign: 'text-top' }}></i>
                  <span className="main_price pr-2 black">{numberWithFloat(parseFloat((product.product_types - (product.product_types * (product.dealer_discount / 100)))))}</span>
                  <span className="main_price pr-2 black">{(product.secondary_product_types != null && parseInt(product.secondary_product_types) > parseInt(product.product_types)) && <strike>{numberWithFloat(parseFloat(product.secondary_product_types))}</strike>}</span>
                </p>
              )}

              {product.product_type_currency == 0 && (
                <p className="mb-0">
                  <span className="main_price pr-2 black">{numberWithFloat(product.product_types)}</span>
                  <span style={{ color: '#565959', fontSize: '11.5px' }}> {product.product_type_unit}</span>
                </p>
              )}

              <div className="item_action mt-0 pb-0">
                <div className="item_action_link"></div>
                {(this.props.settings.is_compare_enable === 'Yes') && <div className="">
                  <Link to={'#'} onClick={() => this.props.addCompareProduct(product.id)} className="text-underline">{t('compare')}</Link>
                </div>}
              </div>

            </div>
          </div>

        </Col>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    cartProducts: state.carts.products,
    wishlistProducts: state.wishlists.products,
    catalogProducts: state.catalogs.products,
    user: state.users.user,
    catalogId: state.catalogs.id,
    catalogSymbol: state.catalogs.symbol,
    catalogName: state.catalogs.name
  }
}

export default withTranslation()(connect(mapStateToProps)(Product));