import { checkHash } from './ServicesHelper';
import { domain, isDealerPortal, isMobile, isDigitalCatalog, localhost, underMaintenance } from './ConfigHelper';
import cookie from 'react-cookies';
import AsyncStorage from '@react-native-async-storage/async-storage';
/*import { ToastAndroid , AlertIOS ,Platform} from 'react-native';*/

export const dealersPortal = isDealerPortal;

export const mobile = isMobile;

export const digitalCatalog = isDigitalCatalog;

export const siteUnderMaintenance = underMaintenance;

const testingServer = false;

export async function getLocalHostUrl() {
  var getDomain = await domain();

  if (localhost == false)
    return 'http://' + getDomain + '.faberlounge.com/';
  else
    // return ;
    return testingServer ? 'http://localhost/dc.sport/' : 'https://dc.sportnetwork.in/';
}

export async function getApiUrl() {
  var getDomain = await domain();
  // console.log(getDomain)
  if (localhost == false)
    return 'http://' + getDomain + '.faberlounge.com/';
  else
    // return 'http://localhost/dc.sport/';
    return testingServer ? 'http://localhost/dc.sport/' : 'https://dc.sportnetwork.in/';
}

export async function getClientApiUrl() {
  var getDomain = await domain();
  // return 'http://localhost/admin.sportnetwork';
  return testingServer ? 'http://localhost/admin.sportnetwork' : 'https://admin.sportnetwork.in/';
}

export async function getImageUrl() {
  var getDomain = await domain();
  if (localhost == false)
    return 'http://' + getDomain + '.faberlounge.com/';
  else
    // return 'http://localhost/dc.sport/';
    return testingServer ? 'http://localhost/dc.sport/' : 'https://dc.sportnetwork.in/';
}

export async function getImagePath() {
  var getDomain = await domain();
  return 'https://' + getDomain + '.faberlounge.com/uploads/sportnetworks/';
}

/*export const getProductImageUrl="http://bhaskar.faberlounge.com/uploads/product/images"

export const getDigitalCatalogApiUrl = 'http://bhaskar.faberlounge.com/';

export const getProductUrl='http://bhaskar.faberlounge.com/';*/

export const checkValidHash = async () => {
  if (mobile == false) {
    if (navigator.onLine) {
      if (cookie.load('hash')) {
        var hash = await checkHash();
        if (hash.data.status == 'success') {
          if (hash.data.data.hash_exists == true) {
            cookie.save('hash', Math.random().toString(36).substring(2), { path: '/' });
          }
        }
      }
      else
        cookie.save('hash', Math.random().toString(36).substring(2), { path: '/' });
    }
  }

  if (mobile == true) {
    var previousHash = await AsyncStorage.getItem('hash');
    if (previousHash) {
      var hash = await checkHash();
      if (hash.data.status == 'success') {
        if (hash.data.data.hash_exists == true) {
          await AsyncStorage.setItem('hash', JSON.stringify(makeid(16)));
        }
      }
    }
    else
      await AsyncStorage.setItem('hash', JSON.stringify(makeid(16)));
  }
}


export const changeUserHash = () => {
  return AsyncStorage.setItem('hash', JSON.stringify(makeid(16)));
}

export const getAsyncItems = async (key) => {
  if (mobile == true) {
    try {
      var data = await AsyncStorage.getItem(key);
      if (data !== null) {
        data = JSON.parse(data);
      }
      return data;
    }
    catch (error) {
      console.log(error);
    }
  }
}

export const getParentRoute = async (route) => {
  var parentRoute = route.split('/');
  return parentRoute[1];
}

function makeid(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getMobileToast = (msg) => {
  /*if (Platform.OS === 'android') {
    ToastAndroid.show(msg, ToastAndroid.SHORT)
  } else {
    AlertIOS.alert(msg);
  }*/
}

export const numberWithFloat = (n, toFixes = 0) => {
  //console.log(n);
  if (isNaN(n)) {
    return n;
  }
  else
    return parseFloat(n).toFixed(toFixes);
}
