import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

class TechnologiesModal extends React.Component {
  constructor(props){
    super(props);
    this.state={
      isData:true,
    }
  }
  render() {
    return (
      <div id={"tech-modal-"+this.props.id} className="modal fade" tabIndex="-1">
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">             
              <button type="button" className="close btn-lg" onClick={()=>this.setState({isData:false})} data-dismiss="modal">
                <span>&times;</span>
              </button>
            </div>  
            {this.props.technologies.length > 0 && 
              this.props.technologies.map((technology,key)=>(
                <div key={key}>
                  {technology.title != "" && (
                  <div className="modal-header border-0">
                    <h5 className="modal-titile bold text-uppercase">{technology.title}</h5>
                  </div>
                  )}
                  <div className="modal-body">
                    {technology.image != null && (
                      <img src={this.props.settings.image_url+'technologies/original/'+technology.image} width="100%" className="mb-3" /> 
                    )}
                    {this.state.isData ?
                      <div>
                        <div className="spec_video" dangerouslySetInnerHTML={{__html: technology.video_url}} />
                      </div>
                      :
                      this.setState({isData:true})
                    } 
                    {technology.description.split('|').map((value, key) => (
                      <p dangerouslySetInnerHTML={{ __html: value }} key={key}></p>
                    ))}
                  </div>
                </div>                             
              ))
            }
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(TechnologiesModal));