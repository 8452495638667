import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class SyncrosWarranty extends React.Component {	
	render(){
		return (		
			<div className="mt-5">
		  	<h2 className="small_heading bold black text-center mb-5 text-uppercase">Syncros Warranty</h2>
		  	<div className="mb-4">
		  		<p>This limited two-year warranty covers defects in materials and workmanship on SYNCROS branded products sold by SCOTT or an authorized SCOTT/ SYNCROS dealer (“Product”).</p>
		  		<h3 className="content_heading black bold">HOW LONG DOES COVERAGE LAST?</h3>
		  		<p>This limited warranty lasts for two years from the date of purchase of the Product and is limited to the first purchaser of the Product. Transfer of the Product from the first purchaser to another person terminates this limited warranty. Any warranties implied by law are limited in duration to two years from the date of purchase of the Product and are limited to the first purchaser of the Product. Repaired or replaced Products are covered for the remainder of the original warranty period and subject to the conditions outlined in the original warranty.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT WILL SCOTT DO?</h3>
		  		<p>SCOTT will replace or repair any defective Product, or will refund your purchase price (as evidenced by your tendered receipt of purchase of the Product), at SCOTT’s option. You must pay charges in connection with replacement of any non-defective parts. In such a case, you will be alerted to the advisability of replacing non-defective parts, so you can pre-authorize the costs.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT DOES THIS LIMITED WARRANTY NOT COVER?</h3>
		  		<p>This limited warranty does not cover products used in rental operations. This limited warranty does not cover any defect caused by “wear and tear”, improper handling, abuse, misuse, an act of God, non-compliance with recommended maintenance and care procedures, improper or incorrectly performed maintenance or repairs performed by someone other than an authorized SCOTT/ SYNCROS dealer, and alteration of the Product. All Products come with a hangtag; please carefully follow the care instructions located there or affixed elsewhere to the Product. Consequential and incidental damages are not recoverable under this limited warranty. To the extent permitted by law, this limited warranty is exclusive and in lieu of all other warranties; any and all other warranty is excluded.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">HOW DO YOU MAKE A CLAIM UNDER THIS LIMITED WARRANTY?</h3>
		  		<p>To make a claim under this limited warranty, you must notify SCOTT or authorized SCOTT dealer of the claimed defect within the warranty period and timely return the Product to SCOTT or authorized SCOTT dealer at your expense for inspection. Please contact your authorized SCOTT/ SYNCROS dealer, call SCOTT’s customer service at <a href="tel:8080754321" target="_blank">8080754321</a> or via <a href="https://customerconnect.sportnetwork.in/warranties" target="_blank">File Your Warranty</a> to make inquiries about this limited warranty and return procedures. All returned Product must be accompanied by proof of purchase (receipt) from an authorized SCOTT/ SYNCROS dealer or this limited warranty will not apply. In case of replacement or refund, returned Product becomes the property of SCOTT.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">HOW DO STATE LAWS AFFECT YOUR RIGHTS UNDER THIS LIMITED WARRANTY?</h3>
		  		<p>This limited warranty gives you specific legal rights, and you may also have other rights, which vary from state to state. Recommendation: SCOTT strongly recommends you to use only authorized SCOTT/ SYNCROS dealers for maintenance services and repairs. While necessary maintenance or repairs on your Product can be performed by any company, we recommend that you use only authorized SCOTT/ SYNCROS dealers, as improper or incorrectly performed maintenance or repairs voids the two-year limited warranty set forth above. Costs for maintenance service have to be borne by the consumer.</p>		  		
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">CAN THE WARRANTY TERMS BE TRANSFERRED TO A SECOND BUYER?</h3>
		  		<p>The warranty conditions expire if a product is resold by the original customer.</p>
		  	</div>
		  	<div className="mb-4">
		  		<h3 className="content_heading black bold">WHAT IS THE STATUS OF MY WARRANTY CLAIM?</h3>
		  		<p>We keep your SYNCROS dealer informed on the progress of your warranty claim. Please contact your SYNCROS dealer for further information.</p>
		  	</div>
		  </div>
		);
	}
}

export default SyncrosWarranty;