import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom'


class Sms extends React.Component {	
	render(){
		return (			
		  <div className="tab-pane fade" id="sms_faq" role="tabpanel" aria-labelledby="sms_faq_tab">
		  	<Card>
		  		<Card.Body>
		  			<div className="text-center pt-5">
			  			<h5 className="black bold text-center text-uppercase mb-4">Chat with us</h5>
			  			<h5>Hello their!</h5>
			  			<p>"You can get in touch with us and connect with the sportnetwork team from the chatbot given below"</p>			  			
			  		</div>
		  		</Card.Body>
		  	</Card>
		  </div>
		);
	}
}

export default Sms;