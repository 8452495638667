import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import BootsImg from '../../../assets/images/products/boots.jpg'; 
import GogglesImg from '../../../assets/images/products/goggles.jpg'; 
import HelmetImg from '../../../assets/images/products/helmet.jpg';
import ProtectionImg from '../../../assets/images/products/protection.jpg'; 
import ApparelImg from '../../../assets/images/products/moto-apparel.jpg'; 
import GlovesImg from '../../../assets/images/products/gloves.jpg'; 

class ManualsMoto extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3"><Link to="/manuals"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Motosports Manuals and Documentation</h1>	
              <Row className="sports_ride box_card mt-4">
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={BootsImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>boots</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-boots-view">Boots Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col> 
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={GogglesImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>Goggles</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-goggles-view">Goggles Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={HelmetImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>helmets</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-helmets-view">Helmets Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col> 
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={ProtectionImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>protection</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-protection-view">Protection Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col> 
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={ApparelImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>Apparel</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-apparel-view">Apparel Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>      
                <Col md={6} className="item mb-5">
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <img src={GlovesImg} className="img-fluid" />
                        <div className="img_btn text-center">
                          <span>Gloves</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div>
                        <Link to="/moto-manuals-gloves-view">Gloves Product Manuals</Link><br/>
                        <Link to="/declaration-of-confirmity-moto">Declaration of Conformity</Link>
                      </div>
                    </Col>
                  </Row>                  
                </Col>                          
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ManualsMoto;