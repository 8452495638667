import React from 'react';
import {
	Container,
	Row,
	Col,
	Button,
	Table,
	Card,
	Form,
	ToastBody,
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import CoreLedger from '../../../../core/components/dealer/CoreLedger';
import checkedImg from '../../../assets/images/icons/checked.png';
import excelIcon from '../../../assets/images/icons/excel.png';
import pdfIcon from '../../../assets/images/icons/pdf.png';
import Loader from '../../../components/Loader';
import DueAmountTable from '../controllers/DueAmountTable';

class DealerLedger extends CoreLedger {
	// let response;
	constructor(props) {
		super(props);
		this.onButtonClick = this.onButtonClick.bind(this);
	}

	async updateVoucher() {
		var response;
		console.log('update voucher');
		console.log(this.state.datePicker);
		// if(this.state.datePicker)
		// {
		// 	console.log("avail");
		// }
		// else
		// {
		// 	console.log("null");
		// }
		if (this.state.isDateRange) {
			this.setState({ errors: '' });
		} else {
			this.setState({ errors: '' });
		}
		await this.setState({
			month: this.state.datePicker,
			dateRange: this.state.isDateRange,
			accountName: this.state.name,
			image: this.state.import_files,
		});
		var monthName = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		var res = monthName.indexOf(this.state.month.split(' -')[0]) + 1;
		console.log(this.state.accountName, this.state.import_files);
		// this.setState({ response: await this.getTallyVoucher(res) })
		response = await this.getTallyVoucher(res);
		console.log(response);
		// if(response[0] === "import_files")
		// {
		// 	this.setState({getImagePath:response[0]})
		// }
		// else
		// {
		// this.setState({getImagePath: response[0].import_files});
		// console.log(response.image_file_name);
		// console.log(response[0].import_files);
		// console.log(this.state.getImagePath);
		// }
		if (response) {
			if (response[0].import_files) {
				this.setState({ getImagePath: response[0].import_files });
				console.log(response.image_file_name);
				console.log(response[0].import_files);
				console.log(this.state.getImagePath);
			}
		}
		console.log(this.state);
		// console.log(this.state.vouchers);
		// else
		// {
		// 	this.setState({getImagePath:response[0]})
		// }
		// GARBAGE CODE
		// if (response[0].import_files) {
		//   console.log('file available');
		//   this.setState({getImagePath: response[0].import_files});
		// } else {
		//   console.log('null');
		//   this.setState({getImagePath: null});
		// }
		// MAIN CODE
		// this.setState({ getImagePath: response[0].import_files });
		// console.log(response.image_file_name);
		// console.log(response[0].import_files);
		// console.log(this.state.getImagePath);
		// console.log("res here", this.state.response);
	}

	// Function will execute on click of button
	onButtonClick(value) {
		// using Java Script method to get PDF file
		fetch(value).then((response) => {
			response.blob().then((blob) => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				console.log(fileURL);
				// Setting various property values
				let alink = document.createElement('a');
				alink.href = `http://staging-sportnetwork.ascratech.com/uploads/account_ledger/original/${value}`;
				// alink.href = fileURL;
				alink.setAttribute('target', '_blank');
				// alink.download = `http://staging-sportnetwork.ascratech.com/uploads/account_ledger/original/${value}`;
				alink.download = value;
				// alink.href = `http://staging-sportnetwork.ascratech.com/uploads/account_ledger/original/${value}`;
				alink.click();
			});
		});
	}
	render() {
		const options = [];
		var monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];
		var today = new Date();
		var last3Months = [];
		for (var i = 0; i < 4; ++i) {
			options.push(
				<option>
					{monthNames[today.getMonth() - i] + ' - ' + today.getFullYear()}
				</option>,
			);
		}
		const { dueDetails } = this.state;
		var start = this.state.startDate,
			end = this.state.endDate;
		start =
			start.getDate() +
			'-' +
			(start.getMonth() + 1) +
			'-' +
			start.getFullYear();
		end = end.getDate() + '-' + (end.getMonth() + 1) + '-' + end.getFullYear();

		return (
			<React.Fragment>
				<div className="main mb-5 mt-5">
					<Container>
						{this.state.loader == false ? (
							<React.Fragment>
								<Row className="mt-3 mb-5 pd7">
									<Col sm={4} className="mb-3 mb-sm-0">
										<Card
											className="h-100"
											style={{ backgroundColor: '#fbe9e7' }}>
											<Card.Body className="text-center">
												<div className="">
													<h6 className="bold mb-2 black">
														Remaining Credit Balance
													</h6>
													<h4 className="mb-0 black">
														<i className="fal fa-rupee-sign"></i>
														{parseFloat(
															this.state.totalCreditAvailable,
														).toFixed(2)}
													</h4>
												</div>
												<hr />
												<div>
													<h6 className="bold mb-2 black">
														Total Credit Amount
													</h6>
													<h4 className="mb-0 black">
														<i className="fal fa-rupee-sign"></i>
														{parseFloat(this.state.totalCredit).toFixed(2)}
													</h4>
												</div>
											</Card.Body>
										</Card>
									</Col>
									<Col sm={8}>
										<DueAmountTable
											dueDetails={dueDetails}
											totalCredit={this.state.totalCredit}
										/>
									</Col>
								</Row>
								<Row>
									<Col lg={6}>
										<Form.Group as={Row} className="ledger_field">
											<Form.Label column sm="3">
												Account Name :
											</Form.Label>
											<Col sm="9">
												<select
													name="accountName"
													className="custom-select"
													onChange={(e) => {
														this.setState({ name: e.target.value, errors: '' });
													}}
													required>
													<option value="">Select Account Name</option>
													{this.state.sellerAccount.length > 0 &&
														this.state.sellerAccount.map((item, index) => (
															<option key={index} value={item}>
																{item}
															</option>
														))}
												</select>
												<span className="red">
													{this.state.errors['account_name'] != ''
														? this.state.errors['account_name']
														: ''}
												</span>
											</Col>
										</Form.Group>
									</Col>
								</Row>
								<Col lg={6}>
									<Form.Group as={Row} className="ledger_field">
										<Col sm="3" className="col-form-label">
											<div class="custom-control custom-radio">
												<input
													type="radio"
													class="custom-control-input"
													name="flexRadioDefault"
													id="flexRadioDefault1"
													onChange={() =>
														this.setState({ isDateRange: false, errors: '' })
													}
												/>
												<label
													class="custom-control-label"
													for="flexRadioDefault1">
													Month :
												</label>
											</div>
										</Col>
										<Col sm="9" className="pl-1">
											<select
												name="accountName"
												className="custom-select"
												onChange={(e) => {
													this.setState({
														datePicker: e.target.value,
														errors: '',
													});
												}}
												disabled={this.state.isDateRange}
												required>
												<option value="">Select Month</option>
												{options}
											</select>
											<span className="red">
												{this.state.errors['month'] != ''
													? this.state.errors['month']
													: ''}
											</span>
										</Col>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group as={Row} className="ledger_field">
										<Col sm="3" className="col-form-label">
											<div class="custom-control custom-radio">
												<input
													type="radio"
													class="custom-control-input"
													name="flexRadioDefault"
													id="flexRadioDefault2"
													onChange={() =>
														this.setState({ isDateRange: true, errors: '' })
													}
												/>
												<label
													class="custom-control-label"
													for="flexRadioDefault2">
													Date Range :
												</label>
											</div>
										</Col>
										<Col sm="9" className="pl-1">
											<Row>
												<Col sm={6} className="mb-3 mb-sm-0">
													<DatePicker
														selected={this.state.startDate}
														onChange={(date) =>
															this.setState({ startDate: date })
														}
														dateFormat={'dd/MM/yyyy'}
														className={'some-custom-class form-control'}
														minDate={new Date('01/01/2021')}
														maxDate={new Date()}
														disabled={!this.state.isDateRange}
													/>
												</Col>
												<Col sm={6}>
													<DatePicker
														selected={this.state.endDate}
														onChange={(date) => this.setState({ endDate: date })}
														dateFormat={'dd/MM/yyyy'}
														className={'some-custom-class form-control'}
														minDate={new Date('01/01/2021')}
														maxDate={new Date()}
														disabled={!this.state.isDateRange}
													/>
												</Col>
											</Row>
										</Col>
										<Col sm="9">
											<span className="red">
												{this.state.errors['from'] != ''
													? this.state.errors['from']
													: ''}
											</span>
										</Col>
									</Form.Group>
								</Col>
								<Row>
									<Col lg={6} className="text-center mt-3">
										<Button
											type="submit"
											onClick={() => this.updateVoucher()}
											style={this.props.styles.primary_btn}>
											Submit
										</Button>
									</Col>
								</Row>
								<br />
								<Card>
									<Card.Header style={{ backgroundColor: '#c3e6ff' }}>
										<div className="d-flex">
											<h5 className="bold black mb-0 flex-fill">Ledger</h5>
											<div>
												{this.state.getPdfData ? (
													<a
														target="_blank"
														href={
															'https://admin.sportnetwork.in/uploads/account_ledger/original/' +
															this.state.getPdfData
														}
														className="black text-underline mr-5"
														download>
														Download Pdf
													</a>
												) : null}

												{this.state.isDateRange == false && this.state.name !== "" ? (
													<a
														target="_blank"
														href={
															'https://admin.sportnetwork.in/tally_vouchers/account_ledger/index?account_ledger=1&account_name=' +
															this.state.name +
															'&month=' +
															this.state.datePicker +
															'&seller_id=' +
															this.props.user.dealer_id
														}
														className="black mr-4 text-underline">
														Export as
														<i className="fal fa-file-excel export_icon"></i>
													</a>
												) : null}
												{this.state.isDateRange == true && this.state.name !== "" ? (
													<a
														target="_blank"
														href={
															'https://admin.sportnetwork.in/tally_vouchers/account_ledger/index?account_ledger=1&account_name=' +
															this.state.name +
															'&month=' +
															this.state.datePicker +
															'&seller_id=' +
															this.props.user.dealer_id
														}
														className="black mr-4 text-underline">
														Export as
														<i className="fal fa-file-excel export_icon"></i>
													</a>
												) : null}

												{/* {this.state.isDateRange == false &&
													(
														<a
															target="_blank"
															href={
																'https://admin.sportnetwork.in/tally_vouchers/account_ledger/index?account_ledger=1&account_name=' +
																this.state.name +
																'&month=' +
																this.state.datePicker +
																'&seller_id=' +
																this.props.user.dealer_id
															}
															className="black mr-4 text-underline">
															Export as
															<i className="fal fa-file-excel export_icon"></i>
														</a>
													)} */}
												{/* {this.state.isDateRange == true &&
													(
														<a
															target="_blank"
															href={
																'https://admin.sportnetwork.in/tally_vouchers/account_ledger/index?account_ledger=1&account_name=' +
																this.state.name +
																'&from=' +
																start +
																'&to=' +
																end +
																'&seller_id=' +
																this.props.user.dealer_id
															}
															className="black mr-4 text-underline">
															Export as
															<i className="fal fa-file-excel export_icon"></i>
														</a>
													)} */}
											</div>
										</div>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col sm={6}>
												<h6 className="mb-0 bold">{this.state.accountName}</h6>
												{/* {response ? <h6>{response.import_files}</h6> : null} */}
												{/* <h3>{this.state.getImagePath ? <button onClick={() => this.onButtonClick(this.state.getImagePath)} style={{ marginTop: "30px",backgroundColor:"yellow", color:"black" }}>Test</button> : null}</h3> */}
												{/* {this.state.getImagePath ? <a href={`http://admin.sportnetwork.in/uploads/account_ledger/original/${this.state.getImagePath}`} target="_blank" style={{ display: "inline-block", marginTop: "20px", backgroundColor: "rgb(255,199,0)", color: "black", bordercolor: "rgb(255,199,0)", padding: "10px 14px", fontWeight: "600" }} download>Download Pdf</a> : null} */}
											</Col>
											<Col
												sm={6}
												className="text-sm-right mt-3 mt-sm-0 text-left">
												<p className="bold mb-0">
													{this.state.month.length > 0
														? this.state.month
														: null}
												</p>
												<p className="bold mb-0">
													{this.state.range.length > 0
														? this.state.range
														: null}
												</p>
											</Col>
										</Row>
									</Card.Body>
									<Card.Body className="pt-0">
										<div className="table-responsive">
											{this.state.errors.length == 0 &&
												this.state.vouchers.length > 0 ? (
												<Table
													className="voucher_table mb-0 table table-hover"
													style={{ cursor: 'pointer' }}
													size="sm">
													{/* {this.state.vouchers.debit_opening_balance && this.state.vouchers.credit_opening_balance ? <tr>
														<td colspan="4" className="text-right bold">
															Opening Balance
														</td>
														{this.state.errors.length == 0 &&
															this.state.vouchers.map((item, key) =>
																key == this.state.vouchers.length - 1 ? (
																	<React.Fragment key={key}>
																		<td className="text-right bold">
																			{parseFloat(
																				item.debit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.debit_opening_balance,
																				).toFixed(2)}
																		</td>
																		<td className="text-right bold">
																			{parseFloat(
																				item.credit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.credit_opening_balance,
																				).toFixed(2)}
																		</td>
																	</React.Fragment>
																) : null
															)}
													</tr> : null} */}
													<tr>
														<td colspan="4" className="text-right bold">
															Opening Balance
														</td>
														{/* NEW CODE BELOW */}
														{this.state.errors.length == 0 && this.state.vouchers.length > 0 ?
															this.state.vouchers.map((item, key) =>

																key == this.state.vouchers.length - 1 ? (
																	<React.Fragment key={key}>
																		<td className="text-right bold">
																			{parseFloat(
																				item.debit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.debit_opening_balance,
																				).toFixed(2)}
																		</td>
																		<td className="text-right bold">
																			{parseFloat(
																				item.credit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.credit_opening_balance,
																				).toFixed(2)}
																		</td>
																	</React.Fragment>
																)
																	: null,
															) : null
														}
														{/* OLD CODE BELOW */}
														{/* {this.state.errors.length == 0 &&
															this.state.vouchers.map((item, key) =>
																key == this.state.vouchers.length - 1 ? (
																	<React.Fragment key={key}>
																		<td className="text-right bold">
																			{parseFloat(
																				item.debit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.debit_opening_balance,
																				).toFixed(2)}
																		</td>
																		<td className="text-right bold">
																			{parseFloat(
																				item.credit_opening_balance,
																			).toFixed(2) == '0.00'
																				? '-'
																				: '₹' +
																				parseFloat(
																					item.credit_opening_balance,
																				).toFixed(2)}
																		</td>
																	</React.Fragment>
																) : null,
															)} */}
													</tr>
													<thead>
														{this.state.errors.length == 0 ? (
															<tr>
																<th width="10%" className="text-nowrap">
																	Date
																</th>
																<th width="50%" className="text-nowrap">
																	Particulars
																</th>
																<th width="10%" className="text-nowrap">
																	Vch Type
																</th>
																<th
																	width="10%"
																	className="text-right text-nowrap">
																	Vch No.
																</th>
																<th
																	width="10%"
																	className="text-right text-nowrap">
																	Debit
																</th>
																<th
																	width="10%"
																	className="text-right text-nowrap">
																	Credit
																</th>
															</tr>
														) : null}
													</thead>
													<tbody>

														{this.state.errors.length == 0 && this.state.vouchers.length > 0 ?
															this.state.vouchers.map((item, index) => (
																<tr
																	key={index}

																	onClick={() =>
																		this.getUrl(item?.voucher_type, item?.id)
																	}
																>

																	<td className="text-nowrap">
																		{item?.date.split(' ')[0]}
																	</td>

																	<td>{item.party_name}</td>

																	<td className="text-nowrap">
																		{item?.voucher_type}
																	</td>

																	<td className="text-right text-nowrap">
																		{item?.voucher_no}
																	</td>

																	<td className="text-right text-nowrap">
																		{parseFloat(item?.debit_amount).toFixed(2) ==
																			'0.00'
																			? '-'
																			: '₹' +
																			Math.abs(item?.debit_amount).toFixed(2)}
																	</td>

																	<td className="text-right text-nowrap">
																		{parseFloat(item?.credit_amount).toFixed(
																			2,
																		) == '0.00'
																			? '-'
																			: '₹' +
																			Math.abs(item?.credit_amount).toFixed(2)}
																	</td>
																</tr>
															)) : null}

														{/* {this.state.errors.length == 0 &&
															this.state.vouchers.length > 0 &&
															this.state.vouchers.map((item, index) => (
																<tr
																	key={index}
																
																	onClick={() =>
																		this.getUrl(item?.voucher_type, item?.id)
																	}
																>
																	
																	<td className="text-nowrap">
																		{item?.date.split(' ')[0]}
																	</td>
																	
																	<td>{item.party_name}</td>
																	
																	<td className="text-nowrap">
																		{item?.voucher_type}
																	</td>
																	
																	<td className="text-right text-nowrap">
																		{item?.voucher_no}
																	</td>
																	
																	<td className="text-right text-nowrap">
																		{parseFloat(item?.debit_amount).toFixed(2) ==
																			'0.00'
																			? '-'
																			: '₹' +
																			Math.abs(item?.debit_amount).toFixed(2)}
																	</td>
																	
																	<td className="text-right text-nowrap">
																		{parseFloat(item?.credit_amount).toFixed(
																			2,
																		) == '0.00'
																			? '-'
																			: '₹' +
																			Math.abs(item?.credit_amount).toFixed(2)}
																	</td>
																</tr>
															))} */}
													</tbody>
													<tfoot>
														<tr>
															<td colspan="4" className="text-right bold">
																Current Total
															</td>
															{/* {this.state.debit ? <td className="text-right bold">
																{parseFloat(this.state.debit).toFixed(2) ==
																	'0.00'
																	? '-'
																	: '₹' + Math.abs(this.state.debit).toFixed(2)}
															</td> : null} */}
															<td className="text-right bold">
																{parseFloat(this.state.debit).toFixed(2) ==
																	'0.00'
																	? '-'
																	: '₹' + Math.abs(this.state.debit).toFixed(2)}
															</td>
															{/* {this.state.credit ? <td className="text-right bold">
																{parseFloat(this.state.credit).toFixed(2) ==
																	'0.00'
																	? '-'
																	: '₹' +
																	Math.abs(this.state.credit).toFixed(2)}
															</td> : null} */}
															<td className="text-right bold">
																{parseFloat(this.state.credit).toFixed(2) ==
																	'0.00'
																	? '-'
																	: '₹' +
																	Math.abs(this.state.credit).toFixed(2)}
															</td>
														</tr>
														{/* {this.state.closing_balance ? <tr>
															<td colspan="4" className="text-right bold">
																Closing Balance
															</td>
															{this.state.errors.length == 0 &&
																this.state.vouchers.map((item, key) =>
																	key == this.state.vouchers.length - 1 ? (
																		item.closing_balance >= 0 ? (
																			<td className="text-right bold">
																				{parseFloat(
																					item.closing_balance,
																				).toFixed(2) == '0.00'
																					? '-'
																					: '₹' +
																					parseFloat(
																						item.closing_balance,
																					).toFixed(2)}
																			</td>
																		) : (
																			<React.Fragment>
																				<td></td>
																				<td className="text-right bold">
																					{parseFloat(
																						item.closing_balance,
																					).toFixed(2) == '0.00'
																						? '-'
																						: '₹' +
																						parseFloat(
																							item.closing_balance,
																						).toFixed(2)}
																				</td>
																			</React.Fragment>
																		)
																	) : null,
																)}
															<td></td>
														</tr> : null} */}
														<tr>
															<td colspan="4" className="text-right bold">
																Closing Balance
															</td>
															{this.state.errors.length == 0 && this.state.vouchers.length > 0 ?
																this.state.vouchers.map((item, key) =>
																	key == this.state.vouchers.length - 1 ? (
																		item.closing_balance >= 0 ? (
																			<td className="text-right bold">
																				{parseFloat(
																					item.closing_balance,
																				).toFixed(2) == '0.00'
																					? '-'
																					: '₹' +
																					parseFloat(
																						item.closing_balance,
																					).toFixed(2)}
																			</td>
																		) : (
																			<React.Fragment>
																				<td></td>
																				<td className="text-right bold">
																					{parseFloat(
																						item.closing_balance,
																					).toFixed(2) == '0.00'
																						? '-'
																						: '₹' +
																						parseFloat(
																							item.closing_balance,
																						).toFixed(2)}
																				</td>
																			</React.Fragment>
																		)
																	) : null,
																) : null
															}
															{/* {this.state.errors.length == 0 &&
																this.state.vouchers.map((item, key) =>
																	key == this.state.vouchers.length - 1 ? (
																		item.closing_balance >= 0 ? (
																			<td className="text-right bold">
																				{parseFloat(
																					item.closing_balance,
																				).toFixed(2) == '0.00'
																					? '-'
																					: '₹' +
																					parseFloat(
																						item.closing_balance,
																					).toFixed(2)}
																			</td>
																		) : (
																			<React.Fragment>
																				<td></td>
																				<td className="text-right bold">
																					{parseFloat(
																						item.closing_balance,
																					).toFixed(2) == '0.00'
																						? '-'
																						: '₹' +
																						parseFloat(
																							item.closing_balance,
																						).toFixed(2)}
																				</td>
																			</React.Fragment>
																		)
																	) : null,
																)} */}
															<td></td>
														</tr>
													</tfoot>
												</Table>
											) : (
												<th>There are no transactions</th>
											)}
										</div>
									</Card.Body>
								</Card>
							</React.Fragment>
						) : (
							<Loader loaderState={this.state.loader} />
						)}
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.users.user,
		styles: state.styles.styles,
	};
};

export default connect(mapStateToProps)(DealerLedger);
