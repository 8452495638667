
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import LogoMini from '../../../assets/images/icons/logo_mini.png';
import Separator from '../../../assets/images/icons/separator.png';
import visa from '../../../assets/images/icons/visa.png';
import mastercard from '../../../assets/images/icons/mastercard.png';
import paypal from '../../../assets/images/icons/paypal.png';
import { dealersPortal } from '../../../../core/helpers/OperationsHelper';
import { subscribeNewsletter } from '../../../../core/helpers/ServicesHelper';

class CustomerFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addClass: false,
      openDiv: 1,
      isOpen: false,
      email_id: '',
      msg: [],
      error: 0
    }
  }
  navToggle(openDiv, isOpen = false) {
    if (isOpen == true)
      this.setState({ openDiv: openDiv, isOpen: false });
    else
      this.setState({ openDiv: openDiv, isOpen: true });
  }

  async submitNewsletter() {
    var formdata = [];
    formdata['news_letter_subscribers[email_id]'] = this.state.email_id;
    var response = await subscribeNewsletter(formdata);
    if (response.data.status == 'success') {
      this.setState({ 'msg': 'Your Email-id has been registered for new updates', error: 0 })
    } else {
      this.setState({ 'msg': response.data.errors['news_letter_subscribers[email_id]'], error: 1 })
    }
  }

  render() {
    const addClass = this.state.addClass;
    const { t } = this.props;
    var d = new Date();
    var currentYear = d.getFullYear();
    return (
      <div className="footer">
        {dealersPortal == false && (
          <div className="footer_top">
            <Container>
              <Row>
                <Col md={12} sm={12} className="mx-auto">
                  <Row>
                    <Col lg={5} md={12} className="">
                      <p className="footer_title hiphen">{t('findDealer')}</p>
                      <p className="footer_text mb-0">{t('likeOurGear')}?</p>
                      <p className="footer_text">{t('dealerLocator')}!</p>
                      <div className="footer_actions">
                        <Link to="/find-dealer" className="btn btn-xs transparent center-block btn-primary" style={this.props.styles.secondary_btn} >{t('findDealer')}</Link>
                      </div>
                    </Col>
                    <Col lg={7} md={12} className="">
                      <p className="footer_title hiphen">{t('customerConnect')}</p>
                      <div className="d-flex">
                        <div>
                          <ul className="custom_list mb-0 text-left pl-0 footer_actions">
                            <li className="mb-1">
                              <a href="https://customerconnect.sportnetwork.in/registrations/basic_informations" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100" style={this.props.styles.secondary_btn}>{t('bikeRegister')}</a>
                            </li>
                            <li> 
                              <a href="https://customerconnect.sportnetwork.in/bike_orders/basic_customer_details" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100" style={this.props.styles.secondary_btn}>{t('bikeOrder')}</a>
                            </li>
                          </ul>
                        </div>
                        <div>
                          <ul className="custom_list mb-0 text-left footer_actions">
                            <li className="mb-1">
                              <a href="https://customerconnect.sportnetwork.in/warranties" target="_blank" className="btn btn-xs transparent center-block btn-primary w-100" style={this.props.styles.secondary_btn}>file your warranty</a>
                            </li>
                            <li>
                              <Link to="/contact-us" className="btn btn-xs transparent center-block btn-primary w-100" style={this.props.styles.secondary_btn}>{t('contact US')}</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        )}
        <Container className="subscribe_sec mt-4">
          <Form>
            <Row className="align-items-center justify-content-md-center">
              <Col md={{ span: 12, offset: 6 }} sm={12}>
                <h5 className="black">Subscribe for latest update</h5>
                <Row>
                  <Col md={5} sm={9} xs={9} className="pr-0">
                    <Form.Group className="mb-0">
                      <Form.Control onChange={(e) => this.setState({ 'email_id': e.target.value })} type="email" id="" placeholder="Enter your email address here" />
                    </Form.Group>
                  </Col>
                  <Col md={3} sm={3} xs={3}>
                    <Button onClick={() => this.submitNewsletter()}>Subscribe</Button>
                  </Col>
                  <Col md={12} sm={12} xs={12} className="pr-0">
                    {(this.state.error == 0) ?
                      <span className="green">{this.state.msg}</span> :
                      <span className="red">{this.state.msg}</span>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
        <Container>
          <Row>
            <Col sm={12} className="text-center my-3">
              <img src={Separator} className="img-fluid" />
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="footer_bottom">
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 1 && this.state.isOpen == true ? "open" : ""}`} onClick={() => this.navToggle(1, this.state.isOpen)}>warranty</p>
              <ul>
                <li>
                  <Link to={"/warranty"}>{t('warranty')}</Link>
                </li>
                <li>
                  <Link to={"/buyer-return-policy"}>{t('policy')}</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 2 && this.state.isOpen == true ? "open" : ""}`} onClick={() => this.navToggle(2, this.state.isOpen)}>Products</p>
              <ul>
                <li>
                  <Link to={"/manuals"}>{t('documentation')}</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 3 && this.state.isOpen == true ? "open" : ""}`} onClick={() => this.navToggle(3, this.state.isOpen)}>legal</p>
              <ul>
                <li>
                  <Link to={"/privacy-policy"}>{t('privaryPolicy')}</Link>
                </li>
                <li>
                  <Link to={"/terms-conditions"}>{t('conditions')}</Link>
                </li>
                <li>
                  <Link to={"/warning"}>{t('warning')}</Link>
                </li>
                <li>
                  <Link to={"/nocost_emi"}>{t('No Cost EMI T&C')}</Link>
                </li>
              </ul>
            </Col>
            <Col xs={12} lg={2} md={3}>
              <p className={`footer_title hiphen ${this.state.openDiv == 4 && this.state.isOpen == true ? "open" : ""}`} onClick={() => this.navToggle(4, this.state.isOpen)}>Brands</p>
              <ul>
                {(dealersPortal == true) ?
                  this.props.dealerBrands.map((brand, index) => (
                    <li>
                      <Link to={"/bpl/" + brand.brand_name.toLowerCase()}>{brand.brand_name}</Link>
                    </li>
                  ))
                  :
                  <React.Fragment>
                    <li>
                      <Link to={"/bpl/scott"}>Scott</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/bergamont"}>Bergamont</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/syncros"}>Syncros</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/probike"}>Probike</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/avanti"}>Avanti</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/BACH"}>Bach</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/HYPERICE"}>Hyperice</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/EXUSTAR"}>Exustar</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/BLUB"}>Blub</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/TACX"}>Tacx</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/GARMIN"}>Garmin</Link>
                    </li>
                    <li>
                      <Link to={"/bpl/ELITE"}>Elite</Link>
                    </li>
                  </React.Fragment>
                }
              </ul>
            </Col>
            <Col xs={12} lg={4} md={12} className="social_sec">
              <p className="footer_title hiphen open">{t('aboutUs')}</p>
              <p className="mb-0">At SPORTNETWORK we want to get everyone to take up a sport and get outdoor.</p>
              <ul>
                <li>
                  <Link to={"/about-us"}>More About Us</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
    dealerBrands: state.dealers.brands,
  }
}


export default withTranslation()(connect(mapStateToProps)(CustomerFooter));



