
import React from 'react';
import { Container, Row, Col, Carousel, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import CoreHome from '../../../../core/components/home/CoreHome';
import MetaTags from '../../../components/MetaTags';
import AvantiHome from './AvantiHome';

class Home extends CoreHome {
  render() {
    const { homeBanner, tags, staticHome } = this.state;
    console.log(homeBanner);
    return (
      (typeof staticHome != 'undefined' && staticHome == false) ?
        <React.Fragment>
          /* <MetaTags {...this.props} /> */
          <div className="main">

            <Carousel className="banner_carousel"
            >
              {homeBanner.length > 0 && homeBanner.map((banner) => (
                <Carousel.Item style={{ cursor: "pointer" }}>
                  <a onClick={() => window.location.assign(banner.btn_1_url)}> <img
                    className="d-block w-100"
                    src={this.props.settings.image_url + 'home_banners/original/' + banner.image}
                  />
                    <Carousel.Caption className="btn-div" >
                      <div style={{ display: "flex" }}>
                        {banner.btn_1_url != '' && banner.btn_1_title != '' && (
                          <div  >
                            <Button className="caption_btn transparent " onClick={() => window.location.assign(banner.btn_1_url)} >{banner.btn_1_title}</Button>
                          </div>
                        )}
                        {banner.btn_2_url != '' && banner.btn_2_title != '' && (
                          <div>
                            <Button className="caption_btn transparent" onClick={() => window.location.assign(banner.btn_2_url)} >{banner.btn_2_title}</Button>
                          </div>
                        )}
                      </div>
                    </Carousel.Caption>
                    {/* <div style={{ position: "relative" }}>
                      <div style={{ border: "1px solid green", position: "absolute", bottom: "20px", right: "0px" }}>
                        {banner.btn_1_url != '' && banner.btn_1_title != '' && (
                          <Button className="caption_btn transparent " onClick={() => window.location.assign(banner.btn_1_url)} >{banner.btn_1_title}</Button>
                        )}
                        {banner.btn_2_url != '' && banner.btn_2_title != '' && (
                          <Button className="caption_btn transparent" onClick={() => window.location.assign(banner.btn_1_url)} >{banner.btn_2_title}</Button>
                        )}
                      </div>
                    </div> */}
                  </a>
                </Carousel.Item>
              ))}
            </Carousel>

            {/*<div className="flashDiv">
            <marquee className="flashContent">
              <p className="text-uppercase">Free shipping on order above <i class="fal fa-rupee-sign"></i>1000</p>
            </marquee>
          </div>*/}

            <div className="main_section">
              <Container>
                <h2 className="sec_heading text-center">shop by brand</h2>
                <div className="text-center">
                  {
                    <ul className="list-unstyled brand_list second">
                      {(this.props.brands.length > 0) &&
                        this.props.brands.map((brand, index) => (
                          <React.Fragment>
                            <li key={index}>
                              <Link to={'/bpl/' + brand.slug}>
                                <img src={this.props.settings.image_url + "brands/original/" + brand.image} className="img-fluid" />
                              </Link>
                            </li>
                            {(index == 0) && <br />}
                          </React.Fragment>
                        ))
                      }
                    </ul>
                  }
                </div>
              </Container>
              <div className="text-center"><h3 className="hiphen mb-0 mt-2"></h3></div>
            </div>

            <div className="row mt-5">
              <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                <div id="carousel-example" className="carousel slide" data-interval="false">

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/Hz1YtK3vdUA" allowfullscreen></iframe>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/_32DY9d46Jg" allowfullscreen></iframe>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="embed-responsive embed-responsive-16by9">

                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/b__KeCcH-Hw" allowfullscreen></iframe>
                      </div>
                    </div>

                    <div className="carousel-item">
                      <div className="embed-responsive embed-responsive-16by9">

                        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/ibUTqjHJDdk" allowfullscreen></iframe>
                      </div>
                    </div>


                  </div>
                  <a className="carousel-control-prev" href="#carousel-example" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carousel-example" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>

              </div>
            </div>

            <div className="gcse-search"></div>


            <div className="main_section">
              <Container>
                <h2 className="sec_heading text-center">choose your universe</h2>
                <p className="text-center">Check out what's new in the world of SCOTT</p>
                <Row className="sports_ride">
                  {
                    (this.props.categories.length > 0) &&
                    this.props.categories.map((category, index) => (
                      <Col xs={6} className="item mt15 mb15">
                        <Link to={'/pl/' + category.slug}>
                          <img src={this.props.settings.image_url + "categories/original/" + category.image} className="img-fluid d-none d-sm-block" />
                          <div className="img_btn text-center">
                            <span>{category.name}</span>
                          </div>
                        </Link>
                      </Col>
                    ))
                  }
                </Row>
              </Container>

              {/* <div className="sideRibbon">
              <p>Free shipping on order above <i class="fal fa-rupee-sign"></i>1000</p>
            </div> */}

              <div className="sideRibbon2">
                <Link to="/NewsLetter">
                  <p>Media and Press</p>
                </Link>
              </div>
            </div>



          </div>

        </React.Fragment > :
        <React.Fragment>
          /* <MetaTags {...this.props} /> */
          <AvantiHome />
        </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
    categories: state.categories.categories,
    brands: state.brands.brands,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(Home));

