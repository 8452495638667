import React from 'react';
import {Container, Button} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import logo from '../../../assets/images/avanti/logo.jpg';
import bannerHeading from '../../../assets/images/avanti/banner-heading.png';
import product from '../../../assets/images/avanti/cycle.png';

import {Link} from 'react-router-dom'
class Product extends React.Component {
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
  render() {
    return (
      <div className="main">
        <div className="banner">
          <div className="banner_text">
            <img src={logo} className="img-fluid mx-auto d-block mb-5" />
            <img src={bannerHeading} className="img-fluid mx-auto d-block" />
          </div>
        </div>
        <div className="main-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 product_img_card">
                <h2 className="bold mb-3 black">Product one</h2>
                <a className="btn btn-md radius_btn">View</a><br/>
                <img src={product} className="img-fluid" />             
              </div>
              <div className="col-lg-6 product_img_card">
                <h2 className="bold mb-3 black">Product two</h2>
                <a className="btn btn-md radius_btn">View</a><br/>
                <img src={product} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>    
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings
  }
}


export default withTranslation()(connect(mapStateToProps,null)(Product));