import React from "react"
import ContentLoader from "react-content-loader"
import {Col} from 'react-bootstrap';
const ProductLoader = (props) => (
  <Col lg={3} md={3} sm={4} xs={6}>
    <ContentLoader 
      speed={2}
      width={"100%"}
      height={300}
      viewBox="0 0 100% 100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#a3a3a3"
      {...props}
    >
      <rect x="22" y="230" rx="2" ry="2" width="140" height="10" /> 
      <rect x="23" y="250" rx="2" ry="2" width="140" height="10" /> 
      <rect x="16" y="64" rx="2" ry="2" width="200" height="150" />
    </ContentLoader>
  </Col>  
)

export default ProductLoader