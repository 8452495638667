import React from 'react';
import loaderImage from  '../assets/loader/circle-loader.gif'

const Loader = props => {
   return (
    (props.loaderState == true) && (
      <div style={{position:"relative",display: 'flex',  justifyContent:'center', alignItems:'center', top: '15vh'}}>
        <img height="200" width="200"  src={loaderImage}/>
      </div>
      
    )
  );
}

export default Loader;