import AsyncStorage from '@react-native-async-storage/async-storage';

// export const isDealerPortal = false;
export const isDealerPortal = false;

export const isMobile = false;

export const isDigitalCatalog = false;
export const localhost = true;

export const underMaintenance = false;

export const razorPayTestKey = 'rzp_test_hDK51JL2sosHL5';
export const razorPayLiveKey =  'rzp_live_UkzZnx3cZ9MnPa';

export async function domain() {
  var domain = await AsyncStorage.getItem('domain');
  if (isDigitalCatalog == true && domain != null)
    return JSON.parse(domain)
  else if (isDigitalCatalog == true && domain == null)
    return domain;
  else
    return 'bhaskar';
}
