import React, { Component } from 'react'
import { Row,Col,Container} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Product from './Product';

class SimilarProduct extends Component {
  constructor(props){
		super(props);
  }
  
  render() {
    const { t } = this.props; 
    return (
      this.props.recommendation.length > 0 && (
        <Container> 
          <h2 className="bold text-center mt-5">{t('youMayAlsoLike')} </h2>
          <Row>
            <Col lg={9}>
              {
                <Row className="product_list pd7">
                  {
                    this.props.recommendation.map((recommendation, index)=>(
                      <Col key={index} lg={3} md={3} xs={6}>
                        <Product key={index} product={recommendation}/>
                      </Col> 
                    ))
                  }
                </Row>
              }
            </Col>
          </Row>
        </Container>
      )
    )
  }
}

export default withTranslation()(SimilarProduct);