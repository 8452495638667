import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

import LogoImg from '../../../assets/images/icons/scott_network_logo.jpeg'; 

import CoreDealer from '../../../../core/components/dealer/CoreDealer';

import { connect } from "react-redux";

class Register extends CoreDealer {	
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<div className="login">	
					<div className="main_section">
						<Container>
							<div className="mb-5">
								<a href="/"><img src={this.props.settings.image_url+'/application_images/original/'+this.props.settings.dealer_header_logo} width="80px" /></a>
							</div>							
							<Row>
								<Col lg={6} className="mx-auto">
									<div className="mb-4">
										<h5 className="bold text-uppercase black text-center">{t('register')}</h5>
									</div>
									<Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4">{t('name')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.registerUser} onChange={(e)=>this.setState({registerUser:e.target.value,errorRegister:''})} type="text" className="mb-1" />
									    	<span className="red">{(this.state.errorRegister['dealers[name]']!= "" && typeof this.state.errorRegister['dealers[name]']!= 'undefined')?this.state.errorRegister['dealers[name]']:""}</span>
											</Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">{t('email')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.email_id} onChange={(e)=>this.setState({email_id:e.target.value,errorRegister:''})}type="email" />	
												<span className="red">{(this.state.errorRegister['dealers[email_id]']!= "" && typeof this.state.errorRegister['dealers[email_id]']!= 'undefined')?this.state.errorRegister['dealers[email_id]']:""}</span>
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">{t('phoneNo')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.mobile_no} onChange={(e)=>this.setState({mobile_no:e.target.value,errorRegister:''})}type="text" />		
									    	<span className="red">{(this.state.errorRegister['dealers[mobile_no]']!= "" && typeof this.state.errorRegister['dealers[mobile_no]']!= 'undefined')?this.state.errorRegister['dealers[mobile_no]']:""}</span>
											</Col>							   
									  </Form.Group>
									  <Form.Group as={Row} >
									    <Form.Label column lg="4">{t('address')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control as="textarea" rows={4} />		
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">{t('gst')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control type="text" />		
									    </Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">{t('password')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.registerPassword} onChange={(e)=>this.setState({registerPassword:e.target.value,errorRegister:''})}type="password" />		
									    	<span className="red">{(this.state.errorRegister['dealers[password]']!= "" && typeof this.state.errorRegister['dealers[password]']!= 'undefined')?this.state.errorRegister['dealers[password]']:""}</span>
											</Col>							   
									  </Form.Group>
									  <Form.Group as={Row}>
									    <Form.Label column lg="4">{t('confirmPassword')} :</Form.Label>
									    <Col lg="8">
									    	<Form.Control value={this.state.confirm_password} onChange={(e)=>this.setState({confirm_password:e.target.value,errorRegister:''})}type="password" />		
												<span className="red">{(this.state.errorRegister['confirm_password']!= "" && typeof this.state.errorRegister['confirm_password']!= 'undefined')?this.state.errorRegister['confirm_password']:""}</span>
											</Col>							   
									  </Form.Group>
									</Form>
									<div className="text-center mt-5">
										<p>{t('registrationDescription')}.</p>
										<Link to='/login'>
											<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>{t('back')}</Button>
										</Link>
										<Button className="btn btn-md" onClick={()=>this.registration()} style={this.props.styles.primary_btn}>{t('submit')}</Button>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(Register));