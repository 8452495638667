export default{
  aboutName: "Padamji Soni",
  aboutTextFive: "In the coming years, we look forward to explore our creativity by Crafting Exclusive Designs and setting new benchmark for our Retail jewellers across india and Overseas",
  aboutTextFour: "We have great respect for Ethics. Craftsmenship. Traditions & we strive to merge it with New Energy. Innovation & Technology",
  aboutTextOne: "Our Belief has always been to do everything with purity and Perfection.",
  aboutTextThree: "The major factor behind Shilpi jewels success is the Trust of our Craftsmen & Clients who have been associated with our brand since many years",
  aboutTextTwo: "This belief has turned into our passion of Delivering Exquisite Products and Satisfying the Aesthetic Demands of the customers with Transparency & Dedication.",
  aboutUs: "about us",
  accessLevel: "Access Level",
  accountCheckout: "You can create an account after checkout",
  accounts: "Account",
  active: "active",
  addCatalog: "Add Catalog",
  addProduct: "Add product",
  address: "Address",
  addressLine: "Shilpi Jewels. 29/31. Meena Apartment 2nd floor, Dhanji Street, Mumbai 400003.",
  allProducts: "All Products",
  alreadyACustomer: "Already a Customer ?",
  applyCoupon: "Apply Coupon",
  approvalMsg: "Your account is pending for approval, We will get back to you shortly",
  approvalPending:'Apporval Pending',
  approvalStatus: "Approval Status",
  asc: "Ascending",
  status:'Status',
  searchResult:'Search results',
  ascending: "Ascending",
  assemblyCharges: "Assembly Charges",
  back: "Back",
  backToLogin: "Back to login",
  bangles: "Bangles",
  bikeOrder: "bike on order",
  bikeRegister: "bike registration",
  billingAddress: "Billing Address",
  bracelet: "Bracelet",
  cameraInstructionText: "Sorry, we need camera roll permissions to make this work!",
  cancel: "Cancel",
  catalog: "Catalog",
  catalogCode: "Catalog Code",
  catalogName: "Catalog Name",
  catalogNotAffectText: "Products under other catalogs will not be affected by disabling this catalog",
  categories: "Categories",
  category: "category",
  NocategoryFound: "No category assigned",
  noCatalogFound: "No catalog found",
  chain: "Chain",
  changePassword: "Change Password",
  checkout: "Checkout",
  city: "City",
  clearAll: "Clear All",
  privaryPolicy: "Privacy policy",
  clearFilter: "Clear Filter",
  clickHere: "Click here",
  clickToViewCatalog: "Click on the link to view the catalog.",
  code: "apply coupon code",
  collections: "Collections",
  companyName: "company name",
  compare: "Compare",
  compareProducts: "Compare products",
  conditions: "terms & condition",
  confirmNewPassword: "Confirm New Password",
  confirmOrder: "confirm order",
  confirmPassword: "Confirm Password",
  contactNo: "Contact Number",
  contactUs: "Contact Us",
  continueSearch: "Continue Search",
  copyRights: "CopyRights",
  deliveryNote: "Delivery Preferences",
  country: "Country",
  couponAmount: "Coupon Amount",
  couponApplied: "Coupon Applied",
  createCatalog: "Create Catalog",
  creatingYourCatalog: "Creating Your Catalog",
  creditBalance: "Store credit balance",
  currentStock: "Current Stock",
  customOrder: "Custom Order",
  customerConnect: "customer connect",
  date: "Date",
  dealerLocator: "Check out our dealer locator to find the dealer nearest you",
  dealerNetwork: "Dealer Network",
  dealerShip: "Enquire for Dealership",
  decending: "Descending",
  delete: "Delete",
  deliveryAddress: "Delivery Address",
  deliveryDate: "Delivery Date",
  desc: "Descending",
  designCode: "Design Code",
  details: "Details",
  disableCatalogText: "You can disable the catalog if you do not want anyone to view the products added under this catalog. Click on the disable link below to do so",
  documentation: "Manuals & Product Documentation",
  domain: "domain",
  dontHaveAnAccount: "Don't have an account?",
  dueOn: "Due on",
  earrings: "Earrings",
  edit: "Edit",
  editProfile: "Edit Profile",
  email: "Email ID",
  mobile:"Mobile No",
  accessToken: "Access Token",
  password:"Password",
  RememberMe: "Remember Me",
  carts: "Carts",
  wishlist: "Wishlist",
  emptyCartSubText: "Items added to your cart will show up here",
  emptyCartText: "Your cart is empty!",
  emptyCatalogText: "There is no any product in this catalog",
  emptyOrder: "There is no order found",
  emptyWishSubText: "Explore more and shortlist some iems",
  emptyWishText: "Your wishlist is empty!",
  enableCatalogText: "Enable catalog if you again want anyone to view the product under this catalog.  Click on the enable link below to do so",
  enter: "Enter",
  enterDesignCode: "Enter Design Code",
  enterNewCatalogCode: "Enter New Catalog Code",
  excludeTax: "Excl. Tax",
  exploreNow: "Explore now",
  exploreProducts: "Explore more to find products",
  filter: "Filter",
  findDealer: "find a dealer",
  findProduct: "Explore more and find products to purchase now",
  followUs: "follow us",
  forAccounts: "For Accounts",
  forOrder: "For Order",
  forgotPassword: "Forgot Password ",
  forgotYourPassword: "Forgot Your Password",
  futureAvailabilityDate: "Future Availability Date",
  futureAvailabilityQuantity: "Future Availability Quantity",
  getOtp: "Get Otp",
  getQuote: "get quote",
  goto: "Go to",
  gst: "GST Number",
  hasShareProductCatalog: "has shared with you a product catalog.",
  hello: "Hello",
  hi: "Hi",
  highToLow: "High to Low",
  home: "Home",
  inStock: "In Stock",
  includeTax: "Incl. Tax",
  itemCode: "Item Code",
  items: "Items",
  keyFeatures: "Key features",
  last30days: "last 30 days",
  learnMore: "learn more",
  lengthInc: "Length (inc)",
  likeOurGear: "Like our gear",
  linkExpired: "Link expired or tempered",
  loadMore: "Load More",
  loading: "Loading",
  loadingSimilarDesigns: "Loading Similar Designs",
  loggesIn: "Stay Logged In",
  login: "Login",
  loginInSuccessfully: "Login in successfully",
  loginWithFacebook: "LOGIN WITH FACEBOOK",
  loginWithGoogle: "LOGIN WITH GOOGLE",
  loginWithOtp: "Login with OTP ?",
  loginWithPassword: "Login with Password ?",
  logout: "Logout",
  logoutALLDevice: "Logout From All The Device",
  logoutCurrentDevice: "Logout From The Current Device",
  lookingForSomething: "Looking for something",
  lowToHigh: "Low to High",
  mangalsutra: "Mangalsutra",
  mobile: "Mobile No",
  modalHeading: "Modal heading",
  model: "Model No",
  moveAllToCart: "MOVE ALL TO CART",
  moveToCart: "Move to cart",
  moveToWishlist: "Move to wishlist",
  myCatalogs: "Catalogs",
  myOrders: "My Orders",
  myProfile: "my profile",
  mobileNo: "Mobile No",
  name: "Name",
  necklace: "Necklace",
  newPassword: "New Password",
  noCatalogsFound: "No Catalogs Found!",
  noCategoriesFound: "No categories found!",
  noGeometry: "No geometry found",
  noOrderFound: "No orders yet!",
  noOrderFoundSubText: "After placing order you will be show up here",
  noProductsFound: "No products found!",
  noProductsFoundSubText: "Explore more to find your product",
  noQuotationsFound: "No quotations found!",
  noRecord: "No Record Found",
  noSubCategoriesFound: "No sub categories found!",
  notAnAccount: "Not an account",
  ok: "Ok",
  oldPassword: "Old Password",
  or: "or",
  order: "Order",
  orderDetails: "order details",
  orderName: "Order Name",
  orderPlaced: "Order Placed",
  orderReceive: "Your order has been received",
  orderShare: "Share",
  orderSummary: "Order Summary",
  orderTotal: "Order Total",
  others: "Others",
  otp: "OTP",
  outStock: "Out Stock",
  page: "Page",
  password: "Password",
  passwordChangedText: "Successfully password changed",
  passwordIssue: "We are not able to reset your password due to technical issue.",
  passwordReset: "Password has been reset successfully, Redirecting to login page.",
  passwordResetRequest: "Password reset request",
  past3Month: "past 3 months",
  paymentDue: "Payment Due",
  paymentNotRequired: "Payment not required at this time",
  pendants: "Pendants",
  phoneNo: "Phone No.",
  pieces: "Pieces",
  pinCode: "Pin Code",
  placeOrder: "Place Order",
  placeReplacementOrder: "Place Replacement Order",
  placingYourOrder: "Placing your order",
  pleaseConfirmYourEmail: "Please Confirm Your Email",
  pleaseEnterEmail: "Please Enter Email",
  pleaseEnterRemark: "Please Enter a Remark (optional)",
  pleaseSelectOption: "Please select option",
  pleaseWait: "Please, wait...",
  pleaseWaitWeAreUpdatingYourProfile: "Please wait we are updating your profile",
  policy: "buyer return policy",
  pressBackAgainToExit: "Press back again to exit",
  privacyPolicy: "privacy policy",
  productName: "product name",
  productNotFound: "Product not found",
  products: "Products",
  public: "Public",
  purity: "purity",
  qty: "Qty",
  quality: "quality",
  quantity: "Quantity",
  quickView: "quickview",
  quotation: "Quotation",
  quotationDetails: "quotation details",
  refineSearch: "Refine Search",
  register: "Register",
  registerSuccessfully: "Register successfully",
  registrationDescription: "By registering with us you agree to our terms and conditions",
  remainingCreditBal: "Remaining Credit Balance",
  remark: "Enter a Remark",
  rememberMe: "Remember Me",
  remove: "Remove",
  addToCart: "Add to cart",
  addToWishlist: "Add to wishlist",
  removeAllCartText: "Are you sure you want to remove all the design from cart?",
  removeAllFromCart: "remove all from cart",
  removeAllFromWishlist: "remove all from wishlist",
  removeAllWishText: "Are you sure you want to remove all the design from wishlist?",
  removeCartText: "Are you sure you want to remove the design from cart?",
  removeFromCart: "remove from cart",
  removeFromWishlist: "remove from wishlist",
  removeWishText: "Are you sure you want to remove the design from wishlist?",
  reorder: "Reorder",
  reserveRights: "We reserve the right to make changes to the product information contained on this site at any time without notice, including but not limited to equipment, specifications, models, colors and materials",
  reset: "Reset",
  resetMail: "Please enter your email address associated with your account and we will email you instructions to reset your password",
  resetPassword: "Reset Your Password",
  resetPasswordMsg: "Reset password link has been sent to your email id, Please check email and follow instruction to reset password",
  returnTOShopping: "Return to Shopping",
  revertQuoteText: "We will revert with the quote shortly",
  rights: "All Rights Reserved",
  ring: "Rings",
  save: "save",
  searchDesignCode: "Search Design Code",
  select: "Select",
  selectCatalog: "Select Catalog",
  selectCountry: "Please select a country",
  selectRegion: "Please select a region, state, or province",
  seller: "Seller",
  settingText: "Please wait while we are setting up your application.",
  shape: "shape",
  share: "Share",
  shareCatalog: "Share Catalog",
  shareOrder: "Share order",
  shareQoute: "Share Quotation",
  shipping: "Shipping",
  shippingAddress: "Shipping Address",
  shopByBrand: "shop by brand",
  shopByCategory: "shop by category",
  shopNow: "Shop Now",
  shoppingCart: "Shopping cart",
  signIn: "Sign In",
  signInFb: "Sign in with Facebook",
  signUp: "sign up",
  size: "Size",
  sizeChart: "Size chart",
  skip: "skip login",
  skipAccess: " You doesn't have access to view all products.To proceed please login with an account for unlimited access",
  skucode: "Skucode",
  sorryText: "We're sorry. The Web address you entered is not a functioning page on our site",
  sortBy: "sort by",
  state: "State/Province",
  storeCredit: "Store Credit Use",
  street: "Street",
  subTotal: "Sub total",
  submit: "Submit",
  tapImageForOneMoreView: "Tap image for 1 more view",
  thankYou: "Thank You",
  tone: "tone",
  total: "TOTAL",
  totalCreditAmount: "Total Credit Amount",
  unableToResetPassword: "Unable to reset password",
  update: "update",
  updateText: "This version is no longer supported. Please update your application.",
  updatingYourCatalog: "Updating Your Catalog",
  uploadMsg: "Tap on image to upload new profile picture.",
  variation: "Variation",
  verifiedSuccessfully: "Verified successfully",
  version: "Version",
  view: "Views",
  viewAll: "View all",
  viewAllProducts: "View all products",
  viewDetails: "View Details",
  viewFullDetails: "view full details",
  viewGeometry: "To view geometry",
  viewStmt: "View Statement",
  warning: "warning",
  warrant: "warranty",
  website: "Website",
  weight: "Weight",
  weightCts: "Weight (cts)",
  weightGms: "Weight (gms)",
  welcomeMsg: "Welcome to our",
  widthMm: "Width (mm)",
  wishlist: "Wishlist",
  writeHere: "Write here...",
  youMayAlsoLike: "You may also like",
  yourAddingProduct: "Your adding products to",
  yourOrders: "Your Orders",
  zipCode: "Zip/Postal Code",
  existingAddress: "Existing Addresses",
  deliveryToAddress: "Deliver to this address",
  addAddress: "Add new address",
  updateAddress: "Update address",
  createNewAddress: "Create new address",
  selectedAddress: "Address selected",
  payNow: "Pay Now",
  cancelOrder: "Cancel Order",
  returnReplaceRequest: "Return OR Replace",
  deliveredAt: "Delivery Date"
}