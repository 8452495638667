import React from 'react';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import { getRedirectUrl } from '../../helpers/ServicesHelper';
class CoreRedirects extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: false,
      to: '',
    }
  } 

  async componentDidMount(){   
    await this.getRedirectUrl();
  }

  async getRedirectUrl(){
    var fromUrl = this.props.location.pathname;
    var url = await getRedirectUrl(fromUrl);
    if (url.data.status == 'success' && url.data.data.length > 0) {   
      //this.props.history.push(url.data.data[0].to);  
      this.setState({ redirect: true, to: url.data.data[0].to });
    } else {
      this.setState({ redirect: true, to: '/404' })
    }
  }

  render() {
    return (
      this.state.to != '' && (
        <Redirect status={301} from={this.props.location.pathname} to={{pathname:this.state.to,state:{status:301}}} />
      )
    );
  }

}

export default withRouter(CoreRedirects);
