import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import SportsImgOne from '../../../assets/images/banners/sports_img1.jpg'; 
import SportsImgTwo from '../../../assets/images/banners/sports_img2.jpg'; 
import SportsImgThree from '../../../assets/images/banners/sports_img3.jpg'; 
import SportsImgFour from '../../../assets/images/banners/sports_img4.jpg'; 

class Manuals extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-4">Product Manuals and Documentation</h1>						
							<h2 className="small_heading sec_heading text-center">choose your universe</h2>
              <Row className="sports_ride">
                <Col xs={6} className="item mt15 mb15">
                  <Link to="/manuals-bike">
                    <img src={SportsImgOne} className="img-fluid" />
                    <div className="img_btn text-center">
                      <span>bike</span>
                    </div>
                  </Link>
                </Col>
                <Col xs={6} className="item mt15 mb15">
                  <Link to="/manuals-moto">
                    <img src={SportsImgFour} className="img-fluid" />
                    <div className="img_btn text-center">
                      <span>motosports</span>
                    </div>
                  </Link>
                </Col>                      
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Manuals;