
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from '../utils/i18n';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';
import cookie from 'react-cookies';
import ProgressBar from 'react-bootstrap/ProgressBar';

import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../assets/plugins/fontawesome-pro-5.6.1-web/css/all.css';
import { styleSheet } from '../assets/css/style.js';
import '../assets/css/index.js';
import CustomerRoutes from '../components/routes/scott/CustomerRoutes';
import DealerRoutes from '../components/routes/scott/DealerRoutes';
import { setCategoryData } from '../../reducers/categories';
import { setBrandData } from '../../reducers/brands';
import {
  getSettings, getCartProducts, getWishlistProducts,
  checkUserValid, getDealerCategories, getDealerBrands, getDealerCatalogs, getCustomerHeaderFields, getDealerHeaderFields, getOrderStates, getCategories
} from '../../core/helpers/ServicesHelper';
import { getImagePath, dealersPortal, checkValidHash, siteUnderMaintenance } from '../../core/helpers/OperationsHelper';
import loaderImage from '../assets/loader/circle-loader.gif'
import underMaintenance from '../assets/images/banners/maintainance.jpg';

import pdf from '../../web/assets/images/avanti/C.pdf';
import Home from '../pages/home/views/Home';
import Avanti2 from '../pages/home/views/Avanti2';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: '',
      settings: [],
      user: {},
      loader: true,
    }
  }

  async componentDidMount() {
    await this.checkValidUser();
    await this.getSettings();
    this.setState({ loader: false });
    await this.checkValidHash();
    await this.changeLanguage();

    (function () {
      var cx = '9051947c729f04103';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();

    if (dealersPortal == true && typeof this.state.user != 'undefined' &&
      Object.keys(this.state.user).length > 0 && this.state.user.dealer_id != null) {
      await this.getDealerHeaderFields();
      await this.getDealerCatalogs();
      await this.getDealerCategories();
      await this.getDealerBrands();
      await this.getCartProducts();
      await this.getWishlistProducts();
      await this.getOrderStates();
    } else if (dealersPortal == false) {
      await this.getCustomerHeaderFields();
      await this.getCartProducts();
      await this.getWishlistProducts();
      await this.getOrderStates();
      await this.props.setCategories();
      await this.props.setBrands();
    }
  }

  async checkValidHash() {
    await checkValidHash();
  }

  async checkValidUser() {
    var authToken = cookie.load('authToken');
    if (typeof authToken != 'undefined') {
      await this.setUser(authToken);
    }
  }

  setUser = async (authToken) => {
    var response = await checkUserValid(authToken);
    // console.log("====> response", response);
    if (response.data.status == 'success') {
      await this.props.setUser(response.data.data[0]);
      // console.log(response.data)
      this.setState({ user: response.data.data[0] });
    } else {
      cookie.remove('authToken', { path: '/' });
      cookie.remove('sales_login', { path: '/' });
      window.location.reload();
    }
  }

  async getDealerCategories() {
    var where = 'where[seller_categories.seller_id]=' + this.state.user.dealer_id;
    var response = await getDealerCategories(where);
    if (response.data.status == 'success')
      await this.props.setDealerCategories(response.data.data);
    else
      console.log(response.data.status);
  }

  async getDealerBrands() {
    var where = 'where[seller_brands.seller_id]=' + this.state.user.dealer_id;
    var response = await getDealerBrands(where);
    if (response.data.status == 'success')
      await this.props.setDealerBrands(response.data.data);
    else
      console.log(response.data.status);
  }

  async getDealerCatalogs() {
    var response = await getDealerCatalogs();
    if (response.data.status == 'success')
      await this.props.setDealerCatalogs(response.data.data);
    else
      console.log(response.data.status);
  }

  async getCustomerHeaderFields() {
    const fields = await getCustomerHeaderFields();
    if (fields.data.status == 'success') {
      await this.props.setUserCategories(fields.data.data.categories);
      await this.props.setUserSubCategories(fields.data.data.sub_categories);
      await this.props.setUserSubSubCategories(fields.data.data.sub_sub_categories);
    }
  }

  async getDealerHeaderFields() {
    const fields = await getDealerHeaderFields();
    if (fields.data.status == 'success') {
      await this.props.setUserCategories(fields.data.data.categories);
      await this.props.setUserSubCategories(fields.data.data.sub_categories);
      await this.props.setUserSubSubCategories(fields.data.data.sub_sub_categories);
    }
  }

  async getCartProducts() {
    var cartProducts = await getCartProducts();
    if (cartProducts.data.status == 'success') {
      await this.props.setCartCount(cartProducts.data.count);
      await this.props.setCartProducts(cartProducts.data.data);
    } else {
      console.log(cartProducts.data.status);
    }
  }
  async getWishlistProducts() {
    var wishlistProducts = await getWishlistProducts();
    if (wishlistProducts.data.status == 'success') {
      await this.props.setWishListCount(wishlistProducts.data.count);
      await this.props.setWishListProducts(wishlistProducts.data.data);
    } else {
      console.log(wishlistProducts.data.status);
    }
  }

  async getOrderStates() {
    var orderStates = await getOrderStates();

    if (orderStates.data.status == 'success' && orderStates.data.data.length > 0) {
      this.props.setOrderStates(['orders', 'cancelled']);
    }
  }

  async getSettings() {
    var response = await getSettings();
    if (response.data.status == 'success')
      this.setSettings(response.data.data);
    else
      console.log(response.data.status);
  }

  async setSettings(settings) {
    var newSettings = [];
    settings.map((setting, index) => {
      newSettings[setting.name] = setting.value;
    });
    this.setState({ 'settings': newSettings })
    var styles = await styleSheet(newSettings);
    await this.props.setStyle(styles)
    await this.props.setSettings(newSettings);
  }

  changeLanguage = () => {
    i18n.changeLanguage('en');
  };

  render() {
    return (
      siteUnderMaintenance == true ? (
        <div>
          <img width="100%" height="100%" src={underMaintenance} />
        </div>
      ) : (
        <>
        <div className="gcse-search "></div>
        <Router>

          {!dealersPortal && window.location.pathname != '/customer-feedback' && (<div className='strip-container'>
            <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" data-interval="3000">
              <div class="carousel-inner" style={{ backgroundColor: "#e0e0e0" }}  >
                <div class="carousel-item active mt-3">
                  <p className='' style={{ textAlign: "center", color: "black", fontWeight: "600", fontFamily: "ubuntumedium", fontSize: "12px" }}>No Cost EMI Available</p>
                </div>
                <div class="carousel-item mt-3">
                  <a href={pdf} style={{ textDecoration: 'none' }} target="_blank" >
                    <p className='' data-toggle="modal" data-target="#exampleModalLong" style={{ textAlign: "center", color: "black", fontWeight: "600", fontFamily: "ubuntumedium", fontSize: "12px" }}>Click and Collect for Bike</p>
                  </a>
                </div>
                <div class="carousel-item mt-3">
                  <p className='' style={{ textAlign: "center", color: "black", fontWeight: "600", fontFamily: "ubuntumedium", fontSize: "12px" }}>Free shipping on order above <i class="fal fa-rupee-sign"></i>1000</p>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                  <span className="carousel-control-prev-icon" style={{ height: '10px' }} aria-hidden="true"></span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                  <span className="carousel-control-next-icon" style={{ height: '10px' }} aria-hidden="true"></span>
                </a>
              </div>
            </div>
          </div>)}

          <div>
            {this.state.loader == true ? (
              <div style={{ position: "relative", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh', top: '15vh' }}>
                <img height="200" width="200" src={loaderImage} />
              </div>
            ) : <Switch>
              {
                (window.location.pathname === '/pp/bikes/bikes/city-urban-hybrid/scott-sub-cross-40-men-bike') && (<Redirect push to={Home} />)
              }
              {(dealersPortal == true) && <DealerRoutes settings={this.state.settings} />}
              {(dealersPortal == false) && <CustomerRoutes settings={this.state.settings} />}
            </Switch>
            }
          </div>
        </Router></>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    user: state.users.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setStyle: (styles) => { dispatch({ type: 'SET_STYLES', payload: styles }) },
    setUser: (user) => { dispatch({ type: 'SET_USER', payload: user }) },
    setDealerCategories: (categories) => { dispatch({ type: 'SET_DEALER_CATEGORIES', payload: categories }) },
    setDealerBrands: (brands) => { dispatch({ type: 'SET_DEALER_BRANDS', payload: brands }) },
    setDealerCatalogs: (catalogs) => { dispatch({ type: 'SET_DEALER_CATALOGS', payload: catalogs }) },
    setCartCount: (count) => { dispatch({ type: 'SET_CART_COUNT', payload: count }) },
    setCartProducts: (products) => { dispatch({ type: 'SET_CART_PRODUCTS', payload: products }) },
    setWishListCount: (count) => { dispatch({ type: 'SET_WISHLIST_COUNT', payload: count }) },
    setWishListProducts: (products) => { dispatch({ type: 'SET_WISHLIST_PRODUCTS', payload: products }) },
    setCatalogCount: (count) => { dispatch({ type: 'SET_CATALOG_COUNT', payload: count }) },
    setCatalogProducts: (products) => { dispatch({ type: 'SET_CATALOG_PRODUCTS', payload: products }) },
    setSettings: (settings) => { dispatch({ type: 'SET_SETTINGS', payload: settings }) },
    setUserCategories: (categories) => { dispatch({ type: 'SET_CATEGORIES', payload: categories }) },
    setCategories: (categories) => { dispatch(setCategoryData()) },
    setBrands: (categories) => { dispatch(setBrandData()) },
    setUserSubCategories: (subCategories) => { dispatch({ type: 'SET_SUB_CATEGORIES', payload: subCategories }) },
    setUserSubSubCategories: (subSubCategories) => { dispatch({ type: 'SET_SUB_SUB_CATEGORIES', payload: subSubCategories }) },
    setOrderStates: (states) => { dispatch({ type: 'SET_ORDER_STATES', payload: states }) }
  }
}

export default connect(null, mapDispatchToProps)(App);
