import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';


import { updateWishList } from  '../../../../core/helpers/ServicesHelper'
import EmptyWishlist from '../../../components/EmptyWishlist';
import CoreWishLists from '../../../../core/components/transactions/CoreWishLists';
import MoveToCartModal from '../components/MoveToCartModal';
import Tostify from '../../../components/Toaster';
import loader from '../../../assets/loader/circle-loader.gif';
import Loader from '../../../components/Loader';
import Variations from '../components/Variations';
import CompareModal from '../../../pages/products/components/CompareModal'

class WishListing extends CoreWishLists {
	updateWishList = async (value, id) => {
		var formdata=[]
		formdata['wishlists[cart]'] = 1;
		formdata['wishlists[action_type]'] = 1;
		formdata['wishlists[quantity]'] = value;
		formdata['wishlists[item_id]'] = 1;
		formdata['wishlists[id]'] = id;
		formdata['wishlists[user_id]'] = "";
		await updateWishList(formdata, id);
		this.getWishList()
	}
	render (){
		const {compareProducts,wishList} = this.state;
		const {t} = this.props;
		return (
			<React.Fragment> 
				<Tostify/>
				<div className="main mb-5 mt-5">	
					{this.state.loader == false && (				
						<Container>
							<h1 className="page_heading bold text-uppercase black mb-3">{t('shoppingCart')}</h1>
							{wishList.length>0?	
								<Row className="flex-column-reverse flex-sm-row">
									<Col lg={8} sm={8} className="mt-4 mt-sm-0">
										<Card className="cart_table shadow-sm">									
											<Card.Body>										
												{wishList.map((item,index)=>(
													<div key={index} className="cart_item">
														<Row className="">
															<Col xs={4}>
																<Link to={"/pp/"+item.item_details.category_slug+'/'+item.item_details.slug} title={item.item_details.name} className="product_item_photo">
																	<span className="product_item_wrapper">
																		{(typeof this.state.images[item.id] != 'undefined' && this.state.images[item.id] != "") ?
																			<img src={this.state.images[item.id]} className="product_item_img img-fluid" /> : 
																			<img src={loader} className="product_img img-fluid" /> 
																		}
																	</span>
																</Link>
															</Col>
															<Col xs={8}>
																<div className="product_item_details">
																	<div className="d-flex align-items-center">
																		<div className="flex-fill w-50">
																			<Link to={"/pp/"+item.item_details.category_slug+'/'+item.item_details.slug} className="product_item_name">{item.item_details.name}</Link>
																			<div className="item_options">
								                        <p className="text-capitalize black mb-0">{t('model')} : {item.item_details.item_code}</p>
								                      </div>
									    								<div className="item_options">
									    									<p className="text-capitalize black mb-0">{item.item_details.product_type_field_name} : <i className="fal fa-rupee-sign"></i> {item.item_details.product_type_field_value - ((item.item_details.product_type_field_value * item.dealer_discount) / 100)}</p>
									    								</div>
																		</div>
																		{item.item_details.product_type_field_currency == 0 && (
																			<div className="price_div flex-fill">
																				<span className="item_price bold"> 
																					{(typeof item.item_details.product_type_field_value != 'undefined') && (
																						item.item_details.product_type_field_value+' '+item.item_details.product_type_field_unit
																					)}
																				</span>
																			</div>
																		)}
																	</div>
																	<div className="d-flex">
																		<div className="flex-fill">
																		

																		<Variations variations={item.variations} />
																			
																			<div className="item_options">
									    									{item.item_details.in_stock_status == 1 ? 
																					<span className="green bold" style={{fontSize:'12px'}}>{t('inStock')}</span> : 
																					<span className="red bold" style={{fontSize:'12px'}}>{t('outStock')}}</span>
																				}
									    								</div>
									    								<div className="item_options">
																				<span className="gray" style={{fontSize:'12px'}}>Remark : {item.remark}</span>
								    									</div>
								    									<div className="item_action pb-0">
																				<div className="item_action_link" >
																					<a onClick={()=>this.deleteWishListItem(item.id)}>{t('delete')}</a>
																				</div>
																				{(this.props.settings.is_compare_enable == 'Yes') && 
																					<div className="item_action_link" >
																						<a onClick={()=>this.addCompareProduct(item.item_details.id)} >{t('compare')}</a>
																					</div>		
																				}
																				<div className="item_action_link" >
																					<a onClick={()=>this.setMoveToCartModalVisibility(true,item.id)} >{t('moveToCart')}</a>
																				</div>	
																			</div>

																		</div>
																	</div>
																</div>
															</Col>
														</Row>
													</div>
												))}
											</Card.Body>
										</Card>
									</Col>
									<MoveToCartModal 
										setMoveToCartModalVisibility={(value)=>this.setMoveToCartModalVisibility(value)}
										selectedItem={this.state.selectedItem} 
										setRemark={(remark)=>this.setState({'remark':remark})} 
										moveItemToCart={(cartId)=>this.moveItemToCart(cartId)}
										moveToCartModalVisibility={this.state.moveToCartModalVisibility}/>
								</Row>
							:
							<EmptyWishlist/>}
							{(compareProducts.length > 0) && 
								<CompareModal 
									removeAllCompareItem={()=>this.removeAllCompareItem()}
									removeCompareItem={(compare_id)=>this.removeCompareItem(compare_id)} 
									products={compareProducts}/> 
							}
						</Container>
					)}
					<Loader loaderState={this.state.loader} />
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartCount: (count) => {dispatch({ type: 'SET_CART_COUNT', payload: count})},
    setCartProducts: (products) => {dispatch({ type: 'SET_CART_PRODUCTS', payload: products})},
    setWishListCount: (count) => {dispatch({ type: 'SET_WISHLIST_COUNT', payload: count})},
		setWishListProducts: (products) => {dispatch({ type: 'SET_WISHLIST_PRODUCTS', payload: products})},
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(WishListing));