import React from 'react';
import {Container, Row, Col, Tabs, Tab} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import CoreOrdersListing from '../../../../core/components/transactions/CoreOrdersListing';
import Orders from '../components/Orders';
import Loader from '../../../components/Loader';

class OrderListing extends CoreOrdersListing {
	
	async handleClick (key) {
		if (key == 'cancelled') {
			var where = 'where[order_type]=0&where[status]='+key;
			this.setState({ where: where });
		}
		this.getOrders(where);
	}

	render(){
		const { t } = this.props;
		console.log(this.state.orderStates);
		return (
			<React.Fragment> 
				<div className="main">	
					<div className="main_section">
						<Container>
							<Row className='mt-4'>  
								<Col md={6}>
									<h1 className="page_heading bold text-uppercase black mb-3 flex-fill">{t('yourOrders')}</h1>
								</Col>								
							</Row>
							<Row>
								<Col lg={12}>
									{this.props.orderStates.length > 0 && (
										<Tabs defaultActiveKey={this.props.orderStates[0]}  className="order_tabs text-capitalize" onSelect={(k) => this.handleClick(k)}>
											{this.props.orderStates.map((order, index) => (
												<Tab key={index} eventKey={order} title={order} >
											    {this.state.loader == false ? (
														<React.Fragment>
															<Orders 
																status={this.state.status} 
																parentProps={this.props} 
																setFilterUrl={(value) => this.setFilterUrl(value)} 
																orders={this.state.orders} 
																images={this.images} 
															/>
														</React.Fragment>
													):<Loader loaderState={this.state.loader} />}
											  </Tab>
											))}
										</Tabs>
									)}
								</Col>
							</Row>
						</Container>
					</div>		
				</div>
			</React.Fragment
>		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
    orderStates: state.orders.states,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(OrderListing));
