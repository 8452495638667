import React from 'react';

class Dropdown extends React.Component {
    constructor(props){
      super(props);
      this.values = this.props.values;
    }

    /*get_selected = (param_one, param_two) => {
      if(param_one == param_two)
        return 'selected';
    }*/

    /*changeValue = event => {
      this.setState({defaultvalue:event.target.value})
    }*/

    render(){
      return (
        <div>
          <select onChange={(event)=>this.props.onChange(event)} name={this.props.name} className="custom-select" value={this.props.defaultValue} required>
            <option value='' >{this.props.placeholder}</option>
            {
              this.values.map((value,index) => (
                value.id == this.props.defaultValue ? (<option key={index}  value={value.id} selected>{value.name}</option>) : (<option key={index}  value={value.id} >{value.name}</option>) 
              ))
            }
          </select>
            
            <span className="errors">{(typeof this.props.errorMessage!== 'undefined' && this.props.errorMessage[this.props.name])?this.props.errorMessage[this.props.name]:''}</span>
            
        </div>
      
      );
    }
}
 
export default Dropdown;