import React from 'react';
import { Container, Row, Col, Carousel, Button, Image } from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CoreHome from '../../../../core/components/home/CoreHome';
import news2 from '../../../assets/images/avanti/news2.jpg';
import news3 from '../../../assets/images/avanti/news3.jpg';
import news4 from '../../../assets/images/avanti/news4.jpg';
import ArticleOne from '../../../assets/images/media-press-page/article-one.png';
import ArticleTwo from '../../../assets/images/media-press-page/article-two.png';
import ArticleThree from '../../../assets/images/media-press-page/article-three.png';
import ArticleFour from '../../../assets/images/media-press-page/article-four.png';
import ArticleFive from '../../../assets/images/media-press-page/article-five.png';
import ArticleSix from '../../../assets/images/media-press-page/article-six.png';




class AvantiNewsLetter extends CoreHome {
  render() {
    return (
      <React.Fragment>

        {/* <div className='d-flex flex justify-content-center mt-5 p-3' style={{backgroundColor:"black",color:'white'}}>
                  <h2 style={{fontWeight:"600" }}>NEWSROOM</h2>
              </div>  
               */}
        {/* <div className='d-flex flex justify-content-center mt-1 '>
                  <h6 className='' style={{color:'black',fontSize:"20px",fontWeight:"500",textAlign:"center" }}>For any press and media queries, write to us at cs@sportnetwork.in</h6>
              </div> */}

        <div className='d-flex flex justify-content-center flex-wrap p-2 ' style={{ margin: "20px" }}>

          <a target="_blank" style={{ textDecoration: "none" }} href="http://bwmarketingworld.businessworld.in/article/Bergamont-Bikes-Releases-Documentary-The-Das-Bunte-Project-Explores-World-Of-Art-Culture/22-02-2023-466549/">
            <div className="card NewsLetterCard " style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ArticleOne} alt="Card image cap" />
              <div className="card-body">
                <div style={{ margin: "0%", padding: "0%" }}>
                  <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium", }}><b>Das Bunte Projekt</b></h6>

                  <div class="card-footer">
                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: BW Online Bureau</small></p>
                    <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                  </div>
                </div>
              </div>
            </div>
          </a>

          <a target="_blank" style={{ textDecoration: "none" }} href="https://businessnewsweek.in/business/bergamont-bikes-releases-a-documentary-the-das-bunte-project/">
            <div className="card NewsLetterCard" style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "16.8rem" }} src={ArticleTwo} alt="Card image cap" />
              <div className="card-body">
                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Das Bunte Projekt</b></h6>

                <div class="card-footer">

                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Business News Week</small></p>
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                </div>

              </div>
            </div>
          </a>

          <a target="_blank" style={{ textDecoration: "none" }} href="https://mediabulletins.com/business/bergamont-bikes-releases-a-documentary-the-das-bunte-project/">
            <div className="card NewsLetterCard" style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "16.6rem" }} src={ArticleThree} alt="Card image cap" />
              <div className="card-body">
                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b> Das Bunte Projekt </b></h6>

                <div class="card-footer">
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Media Bulletins</small></p>
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 20223</small></p>
                </div>
              </div>
            </div>
          </a>

          <a target="_blank" style={{ textDecoration: "none" }} href="https://onlinemediacafe.com/business/bergamont-bikes-releases-a-documentary-the-das-bunte-projec">
            <div className="card NewsLetterCard" style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ArticleFour} alt="Card image cap" />
              <div className="card-body">
                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Das Bunte Projekt </b></h6>

                <div class="card-footer">

                  <p className="card-text" style={{ textAlign: "center" }} ><small class="text-muted">By: Online Media Cafe</small></p>
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                </div>
              </div>
            </div>
          </a>

          <a target="_blank" style={{ textDecoration: "none" }} href="https://www.adgully.com/bergamont-bikes-releases-a-documentary-the-das-bunte-project-128868.html">
            <div className="card NewsLetterCard" style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ArticleFive} alt="Card image cap" />
              <div className="card-body">
                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Das Bunte Projekt</b></h6>

                <div class="card-footer">


                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Adgully</small></p>
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                </div>
              </div>
            </div>
          </a>

          <a target="_blank" style={{ textDecoration: "none" }} href="https://biznewsdesk.com/business/bergamont-bikes-releases-a-documentary-the-das-bunte-project/">
            <div className="card NewsLetterCard" style={{}}>
              <img className="card-img-top img-fluid bg p-1" style={{ height: "18rem" }} src={ArticleSix} alt="Card image cap" />
              <div className="card-body">
                <h6 className="" style={{ color: "black", fontFamily: "ubuntumedium" }}><b>Das Bunte Projekt </b></h6>

                <div class="card-footer">


                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">By: Biz News Desk</small></p>
                  <p className="card-text" style={{ textAlign: "center" }}><small class="text-muted">Published on: February 22, 2023</small></p>
                </div>
              </div>
            </div>
          </a>





        </div>


      </React.Fragment>
    )
  }
}

export default (AvantiNewsLetter);
