import React from 'react';
import { getApiUrl } from '../../helpers/OperationsHelper';
import { getCompareProducts } from '../../helpers/ServicesHelper';
class CoreProductCompare extends React.Component {
	constructor(props){
		super(props);
		this.state = {products:[], specifications: []}
	}	

	async componentDidMount(){
		await this.getCompareProducts();
	}

	async getCompareProducts() {
    var compareProducts = await getCompareProducts();
    if(typeof compareProducts.data.data !== 'undefined' && compareProducts.data.status === 'success'){
		console.log(compareProducts);
    	this.setState({'products':compareProducts.data.data.products, 'specifications': compareProducts.data.data.product_type_names});
    }
	}
}

export default CoreProductCompare;