import React, { Component } from 'react';
import { Container, Row, Col, Modal, Form } from 'react-bootstrap';
import TransactionButton from 'react-bootstrap-button-loader';
import { RWebShare } from "react-web-share";
import { Route, Redirect, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import Button from 'react-bootstrap-button-loader';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

import Breadcrumbs from '../../../components/Breadcrumbs';
import Filter from '../components/Filter';
import VariationFilter from '../components/VariationFilter';
import Pagination from '../components/Pagination';
import CoreProductListing from '../../../../core/components/products/CoreProductListing'
import Product from '../components/Product';
import ProductLoader from '../../../components/ProductLoader';
import Loader from '../../../components/Loader';
import SideLoader from '../../../components/SideLoader';
import SizeChartModal from '../components/SizeChartModal';
import FindSizeModal from '../components/FindSizeModal';
import CompareModal from '../components/CompareModal';
import QuickviewModal from '../components/QuickviewModal';
import FilterModal from '../components/FilterModal';
import SortModal from '../components/SortModal';
import ProductVariations from '../components/ProductVariations';
import ProductInventory from './ProductInventory';
import { dealersPortal, getClientApiUrl } from '../../../../core/helpers/OperationsHelper';
import NotFound from '../../../components/NotFound';
import ProductNotFound from '../../../components/ProductNotFound';
import Tostify from '../../../components/Toaster';
import MetaTags from '../../../components/MetaTags';
import Search from '../../../components/Search';

class ProductListing extends CoreProductListing {

	render() {
		var getCatalogName = localStorage.getItem("Catalog_Name")
		// if(getCatalogName != null)
		// {

		// }
		// if()
		// localStorage.setItem("Catalog_Name", this.props.catalogName);
		// var getCatalogName = localStorage.getItem("Catalog_Name");
		const { products, compareProducts, productTypeFilters, quantity, productTypeVariationFilters } = this.state;
		const checkConnection = navigator.onLine;
		const { t } = this.props;
		console.log(this.props.catalogId);
		return (
			<React.Fragment>
				<MetaTags {...this.props} />
				<div className="main listing_page mt-4" >
					{checkConnection ?
						<React.Fragment >
							<Tostify />
							{this.state.loader === false && (
								<React.Fragment >
									<Breadcrumbs
										breadcrumbs={this.state.breadcrumbs}
									/>
									<div className="main_section">
										<Container>
											{localStorage.getItem("Catalog_Name") && (
												<Row className="mb-3">
													<Col xs={12} className="text-left">
														<div onClick={() => window.location.href('/cpl/' + this.props.catalogSymbol)}>
															{/* <div onClick={()=>window.location.assign('/products/v1/products_v1/index?catalog_id=13&page_no=1')}> */}
															<span className=" bold text-left">{t('yourAddingProduct')} <a href={window.location.href}> {getCatalogName} </a > {t('catalog')}.</span>
														</div>
													</Col>
												</Row>
											)}
											{
												<Row className="mb-3">
													<Col lg={3} xs={12} className="text-center">
														<div>
															<h1 className="page_heading text-uppercase bold text-left black mb-3">{t('products')}</h1>
														</div>
													</Col>

													<div className="gcse-search "></div>

													{/* <Search
															handleOnSelect={(e) => this.handleOnSelect(e)}
															tags={this.state.tags}
															selectedTag={this.state.selectedTag}
															SearchItem={() => this.SearchItem()}
														/> */}

													{(typeof this.state.showingResultFor != 'undefined' && this.state.showingResultFor != "") &&
														<React.Fragment>
															<Col lg={3} xs={3} className="text-left">
															</Col>
															<Col lg={6} xs={12} className={`text-left`}>
																<span>
																	Showing Result For <b className="text-capitalize bold text-left black mb-3">{this.state.showingResultFor}</b>
																</span>
																<a href="#" onClick={() => this.clearSearch()} className="float-right text-underline">Clear Search</a>
															</Col>
														</React.Fragment>
													}
												</Row>
											}
										</Container>
										<Container>
											<Row>
												<Col lg={3} className="d-none d-lg-block">
													<div className="mb-1">
														<a onClick={() => window.location.reload()} className="btn-sm pl-0 gray text-underline text-capitalize">{t('clearFilter')}</a>
													</div>
													<div className="filter_sec">
														{(productTypeFilters?.length > 0) &&
															<React.Fragment>
																<Filter
																	filters={this.state.filters}
																	category_id={this.props.match.params.categoryId}
																	productTypeFilters={productTypeFilters}
																	changeProductTypeFilters={(values) => this.changeProductTypeFilter(values)}
																	changeFilters={(values) => this.changeFilters(values)}
																/>
															</React.Fragment>
														}

														{(productTypeVariationFilters.length > 0) &&
															<React.Fragment>
																<VariationFilter
																	variationfilterData={this.state.variationFilters}
																	productTypevariationFilters={productTypeVariationFilters}
																	changeProductTypeVariationFilters={(values) => this.changeProductTypeVariationFilters(values)}
																	changeVariationFilters={(values) => this.changeVariationFilters(values)}
																/>
															</React.Fragment>
														}
													</div>

												</Col>

												<Col lg={9}>
													<Pagination changeSort={(changeValue, sortByName) => this.changeSort(changeValue, sortByName)}
														productTypeFilters={this.state.productTypeFilters}
														productCount={(this.state.isLoading === false) ? this.state.totalProducts : 'Loading...'} />

													{
														(this.state.clasifications?.map((classification, index) => (
															<Button key={index} className="mr-2" onClick={() => this.addCategoryClassification(classification.classification)}>{classification.classification}</Button>
														)))
													}

													{(typeof this.props.settings.button_icon != 'undefined' && this.props.settings.button_icon === 'YES') && (
														<div className="product_list_grid">
															<Button className={this.state.active ? "mr-2" : "mr-2 active"} onClick={() => this.setState({ buttonIcon: 6, active: !this.state.active })}><i className="fas fa-th-large"></i></Button>
															<Button className={this.state.active ? "mr-2 active" : "mr-2"} onClick={() => this.setState({ buttonIcon: 4, active: !this.state.active })}><i className="fas fa-grip-horizontal"></i></Button>
														</div>
													)}

													{(this.state.isLoading === false) ?
														<Row className="product_list pd7">
															{products.length > 0 ? 
																products.map((item, index) => {

																	return (
																		<Product
																			key={index}
																			route={this.props.match}
																			addCompareProduct={(item_id) => this.addCompareProduct(item_id)}
																			addRemoveProductFromCatalog={(productId, catalogId, id) => this.addRemoveProductFromCatalog(productId, catalogId, id)}
																			getItemVariations={(productId, type, isDelete) => this.getItemVariations(productId, type, isDelete)}
																			getItemInventory={(productId, showModal, type, isDelete) => this.getItemInventory(productId, showModal, type, isDelete)}
																			product={item}
																			showVariationModalFunction={() => this.showVariationModal()}
																			images={this.state.images}
																			settings={this.props.settings}
																			states={this.state}
																			setRedirectUrl={(url) => this.setState({ redirectUrl: url })}
																			handleImageLoaded={(productId) => this.handleImageLoaded(productId)}
																			setCurrentProductParams={(productId, productName) => this.setCurrentProductParams(productId, productName)}
																			buttonIcon={this.state.buttonIcon}
																		/>
																	)
																}
																) : <ProductNotFound />
															}
														</Row> :
														<Row className="loader pd7">
															<ProductLoader />
															<ProductLoader />
															<ProductLoader />
															<ProductLoader />
														</Row>
													}
													{(this.state.isLoading === false) &&
														<Row>
															<Col lg={12} className="text-center">
																{parseInt(this.state.pageNo) < this.state.maxPageNo && (
																	<Button loading={this.state.loadMoreLoader} onClick={() => this.loadMoreProducts()} className="btn btn-sm" style={this.props.styles.primary_btn}>{t('loadMore')}</Button>
																)}
															</Col>
														</Row>
													}
												</Col>
											</Row>
										</Container>
									</div>
									{(compareProducts.length > 0 && this.props.settings.is_compare_enable === 'Yes') &&
										<CompareModal
											removeAllCompareItem={() => this.removeAllCompareItem()}
											removeCompareItem={(compare_id) => this.removeCompareItem(compare_id)}
											products={compareProducts}
										/>
									}
									<div className="d-block d-lg-none sticky_btn_bar">
										<a data-toggle="modal" data-target="#filter-modal" className="text-uppercase black sticky_btn">
											<i className="fal fa-filter"></i> {t('filter')}
										</a>
										<a data-toggle="modal" data-target="#sort-modal" className="text-uppercase black sticky_btn">
											<i className="fal fa-sort"></i> Sort by
										</a>

										{/*<a type="button" className="text-uppercase dropdown-toggle black sticky_btn" data-toggle="dropdown">
			                    <i className="fal fa-sort"></i> Sort by
			                </a>
			                <ul className="dropdown-menu drop-up" role="menu">
			                    <li><a href="#">Action</a></li>
			                    <li><a href="#">Another action</a></li>
			                </ul>*/}
									</div>
									<QuickviewModal />
									<SortModal
										items={this.props.filters}
										changeSort={(name, value, sortIn) => this.changeSort(name, value, sortIn)}
										popupClose={() => this.setState({ isSortPopupVisible: false })}
										expanded={this.state.isSortPopupVisible}
									/>
									{(productTypeFilters?.length > 0) &&
										<React.Fragment>
											<FilterModal
												filters={this.state.filters}
												category_id={this.props.match.params.categoryId}
												productTypeFilters={productTypeFilters}
												propschangeProductTypeFilters={(values) => this.changeProductTypeFilter(values)}
												propschangeFilters={(values) => this.changeFilters(values)}

												variationfilterData={this.state.variationFilters}
												productTypevariationFilters={productTypeVariationFilters}
												changeProductTypeVariationFilters={(values) => this.changeProductTypeVariationFilters(values)}
												changeVariationFilters={(values) => this.changeVariationFilters(values)}
											/>
										</React.Fragment>
									}
									<Modal show={this.state.showVariationModal} >
										<Modal.Header>
											<Modal.Title>{this.state.productName}</Modal.Title>
											<a onClick={() => this.setState({ showVariationModal: false })} className="action_icon"><i className="far fa-window-close"></i></a>
										</Modal.Header>
										<Modal.Body>
											{!dealersPortal && (
												<ProductVariations
													variations={this.state.variations}
													selectvariation={(name, value, changeImage, variationName) => this.selectvariation(name, value, changeImage, variationName)}
													selectedVariations={this.state.selectedVariations}
													selectedVariationsNames={this.state.selectedVariationsNames}
													selectedVariationKey={this.state.selectedVariationKey}
													selectVariationSkuCode={this.state.selectVariationSkuCode}
													variationSkuCombinations={this.state.variationSkuCombinations}
													settings={this.props.settings}
													quantity={this.state.quantity}
													popupFor={this.state.popupFor}
													changeQuantity={(quantity) => this.changeQuantity(quantity)}
													setRemark={(remark) => this.setState({ remark: remark })}
													t={this.props.t}
												/>
											)}

											{dealersPortal && (
												<ProductInventory
													id={this.state.productId}
													setQuantities={(value, id) => this.setQuantities(value, id)}
													quantities={this.state.quantities}
													errors={this.state.errors}
													inventories={this.state.inventories}
													checkColorOption={false}
												/>
											)}
										</Modal.Body>
										<Modal.Footer>

											{!dealersPortal && (
												<React.Fragment>
													{(this.props.settings.enable_wishlist == 'Yes' && this.state.popupFor == 0) && (
														<TransactionButton
															loading={this.state.wishlistButtonLoader}
															variant="secondary"
															className="btn-lg mr-2 transparent"
															style={this.props.styles.secondary_btn}
															onClick={() => this.addRemoveProductFromWishlist(this.state.productId, 1)}
														>
															Add to Wishlist
														</TransactionButton>
													)}
													{(this.state.popupFor == 1) && (
														<TransactionButton
															loading={this.state.cartButtonLoader}
															variant="primary"
															className="btn-lg "
															style={this.props.styles.primary_btn}
															onClick={() => this.addRemoveProductFromCart(this.state.productId, 0)}
														>
															{t('Add to cart')}
														</TransactionButton>
													)}
												</React.Fragment>
											)}

											{dealersPortal && (
												<React.Fragment>
													<TransactionButton
														loading={this.state.cartButtonLoader}
														variant="primary"
														className="btn-lg "
														onClick={() => this.setBulkCartFromdata(this.state.productId)}
													>
														{t('Add to cart')}
													</TransactionButton>

												</React.Fragment>
											)}

										</Modal.Footer>
									</Modal>
								</React.Fragment>
							)}
							<Loader loaderState={this.state.loader} />
						</React.Fragment>
						:
						<NotFound />
					}
				</div>
				{(typeof this.props.catalogSymbol != 'undefined' && this.props.catalogSymbol != "" && this.props.user.user_type == 0) &&
					<button onClick={() => this.onClickShare(this.props.catalogSymbol)} className="float_icon" variant="dark">
						<i className="fas fa-share-alt pr-2 "></i>
					</button>
				}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings.settings,
		cartProducts: state.carts.products,
		wishlistProducts: state.wishlists.products,
		catalogProducts: state.catalogs.products,
		user: state.users.user,
		catalogId: state.catalogs.id,
		catalogSymbol: state.catalogs.symbol,
		catalogName: state.catalogs.name,
		products: state.products.products,
		page: state.products.page,
		filters: state.filters.filters,
		variationFilters: state.filters.variationFilters,
		styles: state.styles.styles,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setCartCount: (count) => { dispatch({ type: 'SET_CART_COUNT', payload: count }) },
		setCartProducts: (products) => { dispatch({ type: 'SET_CART_PRODUCTS', payload: products }) },
		setWishListCount: (count) => { dispatch({ type: 'SET_WISHLIST_COUNT', payload: count }) },
		setWishListProducts: (products) => { dispatch({ type: 'SET_WISHLIST_PRODUCTS', payload: products }) },
		setCatalogCount: (count) => { dispatch({ type: 'SET_CATALOG_COUNT', payload: count }) },
		setCatalogProducts: (products) => { dispatch({ type: 'SET_CATALOG_PRODUCTS', payload: products }) },
		setProducts: (products) => { dispatch({ type: 'SET_PRODUCTS', payload: products }) },
		setPage: (page) => { dispatch({ type: 'SET_FILTERPAGE', payload: page }) },
		setFilters: (data) => { dispatch({ type: 'SET_FILTERS', payload: data }) },
		setVariationFilters: (data) => { dispatch({ type: 'SET_VARIATION_FILTERS', payload: data }) },
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductListing));