import React, { Component } from 'react'
import MasterProduct from '../products/MasterProduct'

import { getCartListing, getImageContent, getSellers, placeOrder, getOrderSummery,validateSellerSelection, updateSellerId } from '../../helpers/ServicesHelper';
import { getImageUrl, checkValidHash } from  '../../helpers/OperationsHelper';

export default class CoreSellerSelection extends React.Component {
  constructor(props){
		super(props);
		this.state={
			carts:[],
			images:[],
			sellers:[],
      loader:true,
      orderSummery:[],
      errors:[]
		}
		this.images=[];
	}
	
	async componentDidMount(){
    window.history.pushState(null, null, window.location.pathname);
		await this.getCartListing()    
	}

  async validateSellerSelection(){
    this.setState({'errors':[]})
    var response = await validateSellerSelection();
    if(response.data.status == 'error'){
      await this.setState({'errors':response.data.errors});
      return false;
    } else {
      return true
    }
  }

	async getCartListing(){
    var carts = await getCartListing();
    if (carts.data.status == 'success') {
      this.setState({carts:carts.data.data,loader:false});
      this.getBase64Images(carts.data.data);
    }
  }

  async getSellers(){
  	var sellers = await getSellers();
  	if (sellers.data.status == 'success') 
  		this.setState({ 'sellers': sellers.data.data })
  }

  async getBase64Images(carts){
    carts.map((cart,index)=>{
      if (typeof cart.item_details.item_images.name != 'undefined' && cart.item_details.item_images.name != null) {
        var medium_image = this.props.settings.image_url+'products/images/medium/'+cart.item_details.item_images.name;
        this.setImageInState(medium_image, cart.id);
      } else {
        this.setImageInState(this.props.settings.image_url+'/application_images/original/4DKzaX6c97WRJZtN.png', cart.id);
      }
    })    
  }

  async setImageInState(base64,id){
    this.images = this.state.images;
    this.images[id] = base64;
    
    this.setState({ images: this.images });
   
  }
}
