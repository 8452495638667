import React from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import BrandOneImg from '../../../assets/images/logos/scott.png';
import BrandTwoImg from '../../../assets/images/logos/bergamont.png';
import BrandThreeImg from '../../../assets/images/logos/syncros.png';
import BrandFourImg from '../../../assets/images/logos/probike.jpg';
import BrandFiveImg from '../../../assets/images/logos/avanti.png';
import BrandSixImg from '../../../assets/images/logos/bach.png';
import BrandSevenImg from '../../../assets/images/logos/hyperice.png';
import BrandEightImg from '../../../assets/images/logos/exustar.png';
import BrandNineImg from '../../../assets/images/logos/blub.png';
import BrandTenImg from '../../../assets/images/logos/tacx.png';
import BrandElevenImg from '../../../assets/images/logos/garmin.png';
import BrandTwelveImg from '../../../assets/images/logos/elite.jpg';

class AboutUs extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<React.Fragment>
				<MetaTags {...this.props} />
				<div className="main mt-5">
					<div className="main_section">
						<Container className="mt-4">
							<h5 className="bold text-uppercase black mb-3 text-left mb-3">SPORTNETWORK – OUTDOOR IS LIFE.</h5>
							<div className="">
								<p><i>We want to get everyone outdoor.</i></p>
								<p>Sportnetwork is a leading online platform dedicated to promoting sports and outdoor activities. Our mission is to inspire everyone to take up a sport and get outdoors by providing the best outdoor products, equipment, and gear from major sports brands. We believe that outdoor activities are essential for a healthy lifestyle and can greatly improve physical fitness, mental and emotional well-being.</p>
								<p>At Sportnetwork, we offer a wide range of products to cater to different needs and preferences. Whether you are an early beginner or a professional athlete, we have something for everyone. Our focus is to provide top-quality products that add value to your outdoor experience, and we are committed to bringing the best of the brands to our customers.</p>
								<p>Our team is passionate about sports and outdoor activities, and we use our knowledge and expertise to provide excellent customer service and support.</p>
								<p>At Sportnetwork, we believe that outdoor activities are not just a hobby, but a way of life. We are committed to promoting a healthy lifestyle and encouraging more people to take up sports and outdoor activities. Join us on this journey, and let's explore the great outdoors together.</p>
							</div>
							<div className="mt-5">
								<h2 className="sec_heading text-center">Our Brands</h2>
								<div className="text-center">
									<ul className="list-unstyled brand_list first">
										<li>
											<img src={BrandOneImg} className="img-fluid" />
										</li>

									</ul>
									<ul className="list-unstyled brand_list second">
										<li>
											<img src={BrandTwoImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandThreeImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandFourImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandFiveImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandSixImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandSevenImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandEightImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandNineImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandTenImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandElevenImg} className="img-fluid" />
										</li>
										<li>
											<img src={BrandTwelveImg} className="img-fluid" />
										</li>

									</ul>
								</div>
							</div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default AboutUs;
