import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import CoreUser from '../../../../core/components/users/CoreUser';

class Register extends CoreUser {
	render() {
		const { t, settings } = this.props;
		return (
			<React.Fragment>
				<div className="login">
					<div className="main_section">
						<Container>
							<div className="mb-5">
								<a href="/"><img src={settings.image_url + '/application_images/original/' + this.props.settings.header_logo} width="100px" /></a>
							</div>
							<Row>
								<Col lg={6} className="mx-auto">
									<div className="mt-5 mb-4">
										<h1 className="page_heading bold text-uppercase black text-center">{t('register')}</h1>
									</div>
									<Form className="fields-group-md">
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('name')} :</Form.Label>
											<Col lg="8">
												<Form.Control value={this.state.registerUser} onChange={(e) => this.setState({ registerUser: e.target.value, errorRegister: '' })} type="text" className="mb-1" />
												<span className="red">{(this.state.errorRegister['customers[name]'] != "" && typeof this.state.errorRegister['customers[name]'] != 'undefined') ? this.state.errorRegister['customers[name]'] : ""}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('email')} :</Form.Label>
											<Col lg="8">
												<Form.Control value={this.state.email_id} onChange={(e) => this.setState({ email_id: e.target.value, errorRegister: '' })} type="email" />
												<span className="red">{(this.state.errorRegister['customers[email_id]'] != "" && typeof this.state.errorRegister['customers[email_id]'] != 'undefined') ? this.state.errorRegister['customers[email_id]'] : ""}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('mobile')} :</Form.Label>
											<Col lg="8">
												<Form.Control value={this.state.mobile_no} onChange={(e) => this.setState({ mobile_no: e.target.value, errorRegister: '' })} type="text" />
												<span className="red">{(this.state.errorRegister['customers[mobile_no]'] != "" && typeof this.state.errorRegister['customers[mobile_no]'] != 'undefined') ? this.state.errorRegister['customers[mobile_no]'] : ""}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row} >
											<Form.Label column lg="4">{t('address')} :</Form.Label>
											<Col lg="8">
												<Form.Control as="textarea" rows={4} onChange={(e) => this.setState({ address: e.target.value, errorRegister: '' })} />
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('gst')} :</Form.Label>
											<Col lg="8">
												<Form.Control type="text" onChange={(e) => this.setState({ gst: e.target.value, errorRegister: '' })} />
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('password')} :</Form.Label>
											<Col lg="8">
												<Form.Control value={this.state.registerPassword} onChange={(e) => this.setState({ registerPassword: e.target.value, errorRegister: '' })} type="password" />
												<span className="red">{(this.state.errorRegister['customers[password]'] != "" && typeof this.state.errorRegister['customers[password]'] != 'undefined') ? this.state.errorRegister['customers[password]'] : ""}</span>
											</Col>
										</Form.Group>
										<Form.Group as={Row}>
											<Form.Label column lg="4">{t('confirmPassword')} :</Form.Label>
											<Col lg="8">
												<Form.Control value={this.state.confirm_password} onChange={(e) => this.setState({ confirm_password: e.target.value, errorRegister: '' })} type="password" />
												<span className="red">{(this.state.errorRegister['confirm_password'] != "" && typeof this.state.errorRegister['confirm_password'] != 'undefined') ? this.state.errorRegister['confirm_password'] : ""}</span>
											</Col>
										</Form.Group>
									</Form>
									<div className="text-center mt-5">
										<p>{t('registrationDescription')}</p>
										<Link to='/login'>
											<Button className="btn btn-md transparent mr-3" style={this.props.styles.secondary_btn}>{t('back')}</Button>
										</Link>
										<Button className="btn btn-md" onClick={() => this.registration()} style={this.props.styles.primary_btn}>{t('submit')}</Button>
									</div>
								</Col>
							</Row>

						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		settings: state.settings.settings,
		styles: state.styles.styles,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => { dispatch({ type: 'SET_USER', payload: user }) }
	}
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Register));

