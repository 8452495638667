import React, { Component } from 'react'
import { getStates,getDealer,getCities,getBrands } from '../../helpers/ServicesHelper'

export class CoreFindDealers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealers:[],
      countryState:[],
      cities:[],
      name:'',
      city:'',
      state:'',
      brands:[],
      brand:'',
      storeName:'',
      error:'',
      isSearched:0
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    this.getStates();
    this.getBrands();
  }

  async getStates(){
    var states = await getStates('where[countries.name]=india');
    if(states.data.status == "success"){
      this.setState({countryState:states.data.data})
    }
  }

  async getBrands(){
    var brands = await getBrands();
    if(brands.data.status == "success"){
      this.setState({brands:brands.data})
    }
  }
  

  async setDealerState(value){
    this.setState({state:value})
    this.getCities(value)
  }

  async getCities(value){
    var cities = await getCities('where[states.name]='+value);
    if(cities.data.status == "success"){
      this.setState({cities:cities.data.data})
    }
  }

  async submitFindTheDealer(){
    this.setState({error:''})
    var where = '?1=1';
    var isSearchParamCount = 0;
    // console.log(this.state.name)
    if(this.state.name != ''){
      where += '&where[users.name]='+this.state.name;
      isSearchParamCount++;
    }

    if(this.state.city != ''){
      where += '&where[cities.name]='+this.state.city;
      isSearchParamCount++;
    }

    if(this.state.state != ''){
      where += '&where[states.name]='+this.state.state;
      isSearchParamCount++;
    }

    if(this.state.brand != ''){
      where += '&where[brands.name]='+this.state.brand;
      isSearchParamCount++;
    }

    if(this.state.storeName != ''){
      where += '&where[trading_name]='+this.state.storeName;
      isSearchParamCount++;
    }
    if(isSearchParamCount > 0){
      var dealers = await getDealer(where);
      if(dealers.data.status == 'success')
        this.setState({dealers:dealers.data.data,isSearched:1})
    }else{
      this.setState({error:'Please search with atleast one field'})
    }
  }


}

export default CoreFindDealers;

