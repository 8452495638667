import React from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


import Faq from '../components/Faq';
import ContactUsComponent from '../components/ContactUs';
import Call from '../components/Call';
import Sms from '../components/Sms';
import MetaTags from '../../../components/MetaTags';

class ContactUs extends React.Component {
	componentDidMount() {
		window.scrollTo(0, 0)
	}
	render() {
		return (
			<React.Fragment>
				<MetaTags {...this.props} />
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h5 className="bold text-uppercase black mb-3 text-left">Contact Us</h5>
							<Row>
								<Col sm={2}>
									<ul id="faq-tab" className="nav nav-tabs d-flex flex-column contact_tabs" role="tablist">
										<li class="nav-item">
											<a class="nav-link active" id="contact_faq_tab" data-toggle="pill" href="#contact_faq" role="tab" aria-controls="contact_faq" aria-selected="true">
												<div>
													<i className="fal fa-info-square"></i>
													<p className="mb-0">FAQ</p>
												</div>
											</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="email_faq_tab" data-toggle="pill" href="#email_faq" role="tab" aria-controls="email_faq" aria-selected="false">
												<div>
													<i className="fal fa-envelope"></i>
													<p className="mb-0">Email</p>
												</div>
											</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="call_faq_tab" data-toggle="pill" href="#call_faq" role="tab" aria-controls="call_faq" aria-selected="false">
												<div>
													<i className="fal fa-phone-square"></i>
													<p className="mb-0">Call</p>
												</div>
											</a>
										</li>
										<li class="nav-item">
											<a class="nav-link" id="sms_faq_tab" data-toggle="pill" href="#sms_faq" role="tab" aria-controls="sms_faq" aria-selected="false">
												<div>
													<i className="fal fa-comment-dots"></i>
													<p className="mb-0">SMS</p>
												</div>
											</a>
										</li>

									</ul>
								</Col>
								<Col sm={10}>
									<div class="tab-content" id="contact-tabContent">
										<Faq />
										<ContactUsComponent />
										<Call />
										<Sms />
									</div>
								</Col>
							</Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ContactUs;