import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'react-cookies';
import Header from '../../../../src/web/components/headers/scott/Header';
import Footer from '../../../../src/web/components/footers/scott/Footer';
import { Provider } from 'react-redux';
import store from '../../../store';

const CorePublicRoute = ({component: Component, restricted, ...rest}) => {
  return (

    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={props => (
      (typeof cookie.load('authToken') != 'undefined' && restricted) ?
        <Redirect to="/" />
        :  
        (typeof rest.header != 'undefined' && rest.header == false) ?
        <Component {...props} /> :
        <div>
          <header className="header fixed-top"> 
            <Provider store={store}>
              <Header {...props}/>
            </Provider>  
          </header>
          <Component {...props} />
          <Footer/>
        </div>
      )} 
    />
  );
};

export default CorePublicRoute;