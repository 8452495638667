import React from 'react';
import { Container, Row, Col, Carousel, Table, Tabs, Tab, Modal, Form } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';
import { Route, Redirect, Link } from 'react-router-dom';

class VariationSelection extends React.Component {
	render() {
		const { variation, selectedVariationKey, variationSkuCombinations, selectVariationSkuCode, selectvariation, selectedVariations, settings, changeImage } = this.props;
		console.log(selectedVariationKey != variation.name);
		console.log("phase1 issue", variation)
		console.log("phase1 issue", selectedVariationKey)
		console.log("phase1 issue", variationSkuCombinations)
		console.log("phase1 issue", selectvariation)
		console.log("phase1 issue", selectedVariations)
		return (
			variation.options.map((variation_option, variation_option_key) => (
				(variation.name != 'Color') ?
					(variationSkuCombinations.includes(variation_option.sku_no)) ?
						<button
							key={variation_option_key}
							onClick={() => selectvariation(variation.name, variation_option.sku_no, changeImage, variation_option.value)}
							className={(selectedVariations[variation.name] === variation_option.sku_no) ? 'size_btn btn-primary mb-1 mt-1' : 'size_btn mb-1 mt-1'}
						>
							<text>{variation_option.value}</text>
						</button>
						:
						<button
							key={variation_option_key}
							className={'size_btn mb-1 mt-1 disable-variation'}
							disabled={'disabled'}
						>

							<text>{variation_option.value}</text>
						</button>
					: (variationSkuCombinations.includes(variation_option.sku_no)) ?
						<button
							key={variation_option_key}
							onClick={() => selectvariation(variation.name, variation_option.sku_no, changeImage, variation_option.value)}
							className={(selectedVariations[variation.name] === variation_option.sku_no) ? 'size_btn mb-1 mt-1 bg-white' : 'size_btn mb-1 mt-1 bg-white'}
							style={{ border: (selectedVariations[variation.name] === variation_option.sku_no) ? '2px solid #ffc700' : '', }}
						>
							<img width="50" height="50" src={this.props.settings.image_url + "/products/images/thumb/" + variation_option.name}></img>
						</button>
						:
						<button
							key={variation_option_key}
							className={'size_btn mb-1 mt-1 bg-white disable-color'}
							style={{ border: (selectedVariations[variation.name] === variation_option.sku_no) ? '2px solid #ffc700' : '', }}
							disabled={'disabled'}
						>
							<img width="50" height="50" src={this.props.settings.image_url + "/products/images/thumb/" + variation_option.name}></img>
							<i className="fal fa-times"></i>
						</button>
			)
			)
		);
	}
}

export default VariationSelection;
