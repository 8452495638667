import React from 'react';
import cookie from 'react-cookies';
import { Redirect } from 'react-router';
import AsyncStorage from '@react-native-async-storage/async-storage';

import {getAddresses,getStates,getCountries,getCities,updateAddresses,storeAddresses} from '../../helpers/ServicesHelper'
import {mobile, dealersPortal} from '../../helpers/OperationsHelper'
import { toast } from 'react-toastify';

class CoreBillingAddress extends React.Component {
  constructor(props){
		super(props);
    this.state={
    	billingAddress: {},
      addresses: [],
			errors: [],
			countries:[],
			loader:true,
      statesArray: {'billingAddress': { states: [] } },
      citiesArray: {'billingAddress': { cities: [] } } }
	}

	componentDidMount(){
		window.history.pushState(null, null, window.location.pathname);
		this.getBillingAddress();
		this.getCountries();
	}

	async getBillingAddress(){
		var where = 'where[fl_user_addresses.user_id]='+this.props.user.id+'&where[fl_user_addresses.type]=billing'
		var response = await getAddresses(where);
		console.log(response.data)
		if(response.data.status == 'success'){
			if(response.data.data.length > 0)
				this.setState({billingAddress:response.data.data[0]})
		}
	}

	async getCountries(){
    var response = await getCountries();
    if(response.data.status == 'success'){
    	this.setState({countries:response.data.data})
    	this.onChangeCountry('india','country','billingAddress');
    	this.onChangeState(this.state.billingAddress.state,'state','billingAddress');
    }else
    	this.setState({countries:[]})
  }

  async changeFormdataValues(e,name,type,inputType='text'){
  	var setBillingAddressArray = this.state.billingAddress;
  
  	this.onChangeCountry(e.target.value,name,type);
    this.onChangeState(e.target.value,name,type);

    if (inputType == 'checkbox')
		  setBillingAddressArray[name] = (e.target.checked) ? 1 : 0;
    else  
      setBillingAddressArray[name] = e.target.value;
		this.setState({billingAddress:setBillingAddressArray})
  	
  }

  async onChangeCountry(value,name,type){
  	if(name == 'country'){
  		var where = 'where[countries.name]='+value;
  		var states = await getStates(where);
  		var statesArray = this.state.statesArray;
  		if(states.data.status == 'success'){
  			statesArray[type] = {'states':states.data.data}
  			this.setState({statesArray:statesArray})
  		}else{
  			statesArray[type] = {'states':[]}
  			this.setState({statesArray:statesArray})
  		}
  	}
  }

  async onChangeState(value,name,type){
    if(name == 'state'){
      var where = 'where[states.name]='+value;
      var states = await getCities(where);
      var citiesArray = this.state.citiesArray;
      if(states.data.status == 'success'){
        citiesArray[type] = {'cities':states.data.data}
        this.setState({citiesArray:citiesArray})
      }else{
        citiesArray[type] = {'cities':[]}
        this.setState({citiesArray:citiesArray})
      }
    }
  }

  async storeAddresses(id=""){
    var formdata = [];
    if(typeof id != 'undefined' && id != '' && id != null)
    	formdata['shipping_addresses[id]'] = id;
    formdata['shipping_addresses[type]'] = 'billing';
    formdata['shipping_addresses[name]'] = this.state.billingAddress.name;
    formdata['shipping_addresses[street_address]'] = this.state.billingAddress.street_address; 
    formdata['shipping_addresses[full_address]'] = this.state.billingAddress.full_address; 
    formdata['shipping_addresses[city]'] = this.state.billingAddress.city;
    formdata['shipping_addresses[state]'] = this.state.billingAddress.state; 
    formdata['shipping_addresses[postal_code]'] = this.state.billingAddress.postal_code;
    formdata['shipping_addresses[country]'] = this.state.billingAddress.country; 
    formdata['shipping_addresses[mobile_no]'] = this.state.billingAddress.mobile_no; 
    formdata['shipping_addresses[email_id]'] = this.state.billingAddress.email_id;
    //console.log(formdata);
    	if(typeof id != 'undefined' && id != '' && id != null)
     		var result = await updateAddresses(formdata, id);
     	else
     		var result = await storeAddresses(formdata);

	   	if(result.data.status == 'success'){
	        toast.success('Address updated successfully.', {
	          position: "top-center",
	          autoClose: 2000,
	          hideProgressBar: true,
	          closeOnClick: true,
	          pauseOnHover: true,
	          draggable: true,
	          progress: undefined,
	        });
	      setTimeout(()=>{
	      	this.props.history.push('/profile');
	      },2000) 
	    }else{
	      this.setState({'errors':result.data.errors})
	    }

  }

}

export default CoreBillingAddress;