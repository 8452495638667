import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import Faq from '../components/Faq';

class BuyerReturnPolicy extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">Buyers Return Policy</h1>
							<div>
								<p>Scott Network Solutions India PVT LTD. Sales and Return/Exchange Policy. The marketplace <a href={window.location.origin} target="_blank">Sport Network</a> (the “Portal”) is operated by Scott Network Solutions (SNS), India. Our GST Registration No is 27ABACS7981E1Z2.</p>
								<p>This Return/ Exchange Policy (“Policy”) governs the conditions for sale and returns which will apply only to orders placed via the Portal in respect of Products advertised on the Portal for consumers in the India. Please read the following conditions carefully before submitting Your order on <a href={window.location.origin} target="_blank">Sport Network</a></p>
								<p>Please note that those capitalized terms not defined herein, shall have the same meaning ascribed to them in the Terms of Use applicable to the Portal.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Applicability</h2>
								<p>These conditions of sale will apply to any orders that You(“Buyer/You”) place via the Portal in respect of Products advertised on the Portal and any order between You and Seller on our Portal for the sale of such Products. By placing an order with Seller on the Portal, you agree to the application of these conditions of sale to your order. Every time You order Products from the Portal, the conditions of sale in force at that time will apply to your order, so please check these conditions of sale to ensure You understand which terms will apply at that time.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Conditions for Concluding Sale</h2>
								<p>You may only order Products via the Portal if You are purchasing Products as a consumer (which means that You are not purchasing products for trade, business, profession). You are solely responsible for ensuring that all information provided during registration or during the ordering process is correct and complete.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Conclusion of the Sale</h2>
								<p>You can order the products offered by the Sellers on our marketplace in Your shopping basket on the Portal without obligation and correct Your entries at any time before submitting Your order.</p>
								<p>When You submit an order, you should receive an email from us acknowledging that the order has been received (but please contact us if You do not receive this email). This acknowledgment of receipt does not mean that Your order has been accepted. It merely serves to inform You that the order has been received by Seller and upon acceptance You will receive a confirmation mail.</p>
								<p>All orders are subject to acceptance of our Sellers on the Portal. We reserve the right to reject an order without giving a reason. For technical reasons, we cannot accept orders by telephone, email, fax or post.</p>
							</div>	
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Products</h2>
								<p>If You are unable to book Your order online through our Portal (for example, if the Products ordered are out of stock or not available at the time of the order with the Seller), We will inform You of this by email. Payments already made will be refunded as per the Policy.</p>
								<p>The images of Products on the Portal are for illustrative purposes only. Although We have made every effort to bind the Sellers to display the colours accurately, we cannot guarantee that a device's display of the colours accurately reflects the colour of the Products. Products offered by Sellers may vary slightly from those images. You agree and understand that the actual colours that You would see will depend on Your monitor.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Invoice</h2>
								<p>On purchase of the Products offered by the Sellers on the marketplace, you will receive an invoice copy along with Product. We recommend that You keep a hard copy of the same for Your future refer.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Ownership of Products</h2>
								<p>You will own the Products on receipt of payment in full. The conditions of sales and terms is applicable between the Seller and You.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Changing your Order</h2>
								<p>Your order will be fulfilled by the Seller as per the agreed norms as provided under this Policy. Once Your order is processed, you will not be able to cancel or change it before delivery, but You may be able to return Your Product(s) as per the Policy after You have received You Product(s). Buyer shall intimate Seller and SNS Logistics Partners to bring the returned Product back with the Seller. Seller subject to the conditions of this Policy shall either provide a replacement, or reimburse via online store credits equivalent to the invoice value or refund the price paid for the Product to the Buyer through the original mode of payment depending on the nature of the return.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Price and Shipping Charges</h2>
								<p>The prices are stated on the Portal in INR inclusive of GST (where applicable) at the relevant rate chargeable for the time being, but exclusive of delivery charges. The shipping charges will be shown separately during the checkout process and will be payable in addition to the price of the product on sale.</p>
								<p>Sellers can change or revise the prices on the Portal at any time, but changes will not affect orders which have already been accepted.</p>
							</div>	
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Payment Methods All</h2>
								<p>Payments should be made in INR.</p>
								<p>We offer the following payment options: Credit card, Debit Card, Internet Banking, EMI, Pay at Store and other options provided through our Payment Gateway Partner. All payment transaction costs are included in the selling price of the Product. However, with every order We expressly reserve the right not to accept certain payment methods and to refer to other payment forms.</p>
								<p>You will be asked to select Your chosen method of payment during the order process.</p>
							</div>		
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Delivery and Collection</h2>
								<ol type="i">
									<li>
										<p>Delivery directly to You</p>
										<p>All orders that do not include bicycles, skis, ski bindings, ski boots or avalanche backpacks will be delivered to Your specified address within India depending upon the serviceable pin codes.</p>
										<p>Delivery of Your order shall be complete when the Product is delivered to the address specified by You while placing the order.</p>
										<p>If no one is available at Your address to take delivery, we will inform You via email or phone about the next attempt of delivery at Your location or may ask You to collect the Products from Our logistics partner. If after a failed delivery to You, or if You do not collect Your Products from Our logistics partner where they are being held and even after Our reasonable efforts, we are unable to contact You or re-arrange delivery or collection We will cancel the order. Once the Product is received by the Seller, we will initiate the refund of Your order as per the Policy.</p>
									</li>
									<li>
										<p>Collection from Sellers/ SNS Partners</p>
										<p>In order to ensure the quality of the Products, and because assembly, configuration and consultation are required for certain Products, all orders that include bicycle, skis, ski binding, ski boots or avalanche backpack should be collected through our SNS Partners.</p>
										<p>During the ordering process, you will have the ability to choose a SNS Partner near You and Your order will need to be collected by You in person from Your chosen SNS Partner.</p>
										<p>You will be notified by email as soon as the order is ready for you to collect from your chosen SNS Partner. You agree to collect your order within 2 days of receipt of the notification. Delivery of your order shall be complete when the Products are handed over to you by your chosen SNS Partner along with the customer feedback form submitted by You to the chosen SNS Partner.</p>
										<p>You must present the collection notification (email or sms) and an ID (Pan Card/ Aadhar Card/ Voter ID/Driving License) for the collection of the Products.</p>
									</li>
								</ol>
							</div>	
							<div>
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Conditions of Returns/Exchange:</h2>
								<ul>
									<li className="pb-2">You should raise the return request within 7 days from the post receipt of the such Product. The time frame starts from the date when the Product was delivered to You as per confirmation from our logistics partners. All the returns are subjected to Seller’s approval of return.</li>
									<li className="pb-2">Products can be returned in cases of Damage, Manufacturing Defect/Expired Product and In-correct product. Once the return request is raised by You, we will ask the Seller to service the request raised. In cases where repair or replacement is not possible, we will refund the amount to the You as per the Policy. This Policy applies to Products bought from Portal. We, through our marketplace offer quality Products of leading and renowned brands, hence We neither cover nor would be liable to matters subjected to quality. The Products should be returned to us in the original condition with tags intact along with it.</li>
									<li className="pb-2">We do not accept any returns where assembly, configuration and consultation are required for certain Products which includes bicycle, skis, ski binding, ski boots or avalanche backpack which are collected from our SNS Partners. Hence it is advisable that You check that You have received a correct Product and all relevant documents like warranty card, manuals etc. as per Your order. Only after satisfaction of the received Product, you should submit the customer feedback form to the SNS Partner.</li>
									<li className="pb-2">We provide exchange/replacement only in cases where customer may face size issue which falls under product categories i.e. apparels, shoes, running and helmets. The Products should be returned to Us in the original condition with tags intact along with it. Buyers are advised not to accept any open box deliveries. The same should be intimated immediately to SNS and SNS Logistics Partner.</li>
									<li className="pb-2">SNS shall not be liable to refund any fees with respect to open box delivery of Products</li>
									<li className="pb-2">For certain Products, any product related issues will be checked by an authorized Seller’s service personnel and attempted to be resolved by replacing the faulty/ defective part of the Product. Full replacement will be provided only in cases where the Seller’s service personnel opines that replacing the faulty/defective part will not resolve the issue.</li>
									<li className="pb-2">Seller subject to the conditions of this Policy shall either provide a replacement, or reimburse via online store credits equivalent to the invoice value or refund the price paid for the Product to the Buyer through the original mode of payment depending on the nature of the return.</li>
									<li className="pb-2">A return cannot be done if the Buyer simply changes their mind.</li>
									<li className="pb-2">A replacement product will be sent to the buyer only after the return pickup of the concerned product is confirmed. </li>
									<li className="pb-2">Return will only be accepted in their original packaging without removal of any labels and stickers.</li>
								</ul>
							</div>				
							<div>
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Warranty</h2>
								<p>We operate an online market place which acts as an online platform facilitating different Sellers to sell their Products and/or Services and enabling different Buyers to purchase the Products and/or Services offered by Sellers. All commercial/contractual terms in respect of the Product/Services are offered by Seller and agreed upon between Seller and You alone. Warranties, after sales service is offered by Brand/Manufacturer/Seller and We shall have no liability or control and We cannot be held responsible for the same.</p>
							</div>		
							<div>
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Miscellaneous</h2>
								<p>Seller shall be liable for any failure or delay in fulfilling the terms of this Agreement due to fire, strike, war, civil unrest, terrorist action, government regulations, act of Nature or other causes which are unavoidable and beyond the reasonable control of the party claiming force majeure. This Policy shall be governed by and interpreted and construed in accordance with the laws of India. The courts in Mumbai shall have exclusive jurisdiction in respect of any matters arising hereunder. Seller is eligible to terminate the agreement at any time for non-compliance with the Terms of Use or for convenience purpose. No failure by either party hereto, to exercise and no delay in exercising any right hereunder shall operate as a waiver thereof. If any one or more of the provisions of this Agreement is or becomes invalid, void, illegal or unenforceable, the validity, existence, legality and enforceability of the remaining provisions will not in any way be affected, prejudiced or impaired.</p>
							</div>	
							<div>
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Refund Policy</h2>
								<p>Seller subject to the conditions of this Policy shall either provide a replacement, or reimburse via online store credits equivalent to the invoice value or refund the price paid for the Product to the Buyer through the original mode of payment depending on the nature of the return. After the refund is initiated depending upon the return/exchange/cancellation, it may take 10-14 working days to reflect in your account depending upon the payment mode or instrument you have used while placing the order. Kindly note that in case of return/exchange based on the clause 11 of this policy, refund will be initiated once the Product is received to the Seller in proper condition.</p>
							</div>
							<div>
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Contact Us</h2>
								<p>If You have any complaints or wish to communicate with us for any reason, You can contact us at <a href="tel:+918080754321" target="_blank">+918080754321</a> or by email at: <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a></p>
							</div>	
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default BuyerReturnPolicy;