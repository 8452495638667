const initialState = { domain: "" }

const domain = (state = initialState, action) => {
	switch (action.type) {
    case 'SET_DOMAIN':
      return { domain: action.payload };  
  }
  return state
}

export default domain;