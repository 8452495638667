const initialState = {count: 0, products: {}, name: '', id: '', symbol: '' }

const catalogs = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_CATALOG_COUNT':
      return { count: action.payload, products: state.products, name: state.name, id: state.id, symbol: state.symbol }; 
    case 'SET_CATALOG_PRODUCTS':
      return { count: state.count, products: action.payload, name: state.name, id: state.id, symbol: state.symbol };
    case 'SET_CATALOG_NAME':
      return { count: state.count, products: state.name, name: action.payload, id: state.id, symbol: state.symbol };
    case 'SET_CATALOG_ID':
      return { count: state.count, products: action.payload, name: state.name, id: action.payload, symbol: state.symbol };
    case 'SET_CATALOG_SYMBOL':
      return { count: state.count, products: action.payload, name: state.name, id: state.id, symbol: action.payload }; 
  }
  return state
}

export default catalogs;