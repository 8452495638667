import React from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import CompareProduct from './CompareProduct';

class CompareModal extends React.Component {
  constructor(props){
    super(props)
  }
  render() {
    const { t } = this.props;
    return (
      <div className="container-fluid row no-gutters compare_bar">
        <Col xs={12}>
          <div className="accordion" id="compare_products"> 
            <div className="panel">
              <div className="panel-heading">
                <Container>
                  <a href="#" className="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
                     aria-expanded="true" aria-controls="faq3">
                    <div className="panel-titile py-1" id="faqhead3">
                      <h6 className="bold text-uppercase white mb-0">
                        {(typeof this.props.products.length != "undefined")?'Compare products ('+this.props.products.length+'/3)':'(0/3)'}
                      </h6>
                    </div>
                  </a>
                </Container>
              </div>
              <div id="faq3" className="collapse" aria-labelledby="faqhead3" data-parent="#compare_products">
                <div className="panel-body">
                  <Container>
                    <Row>
                      <ul className="col-sm-9 compare_ul list-unstyled">
                        {this.props.products.map((product,index) =>(
                          <CompareProduct key={index} removeCompareItem={(compare_id)=>this.props.removeCompareItem(compare_id)} product={product}/>
                        ))}
                        {(this.props.products.length === 1) &&  
                          <React.Fragment>
                            <CompareProduct removeCompareItem={()=>this.props.removeCompareItem()} product={[]}/>
                            <CompareProduct removeCompareItem={()=>this.props.removeCompareItem()} product={[]}/>
                          </React.Fragment>
                        }
                        {(this.props.products.length === 2) &&  
                          <React.Fragment>
                            <CompareProduct removeCompareItem={()=>this.props.removeCompareItem()} product={[]}/>
                          </React.Fragment>
                        }
                      </ul>
                      <div className="col-sm-3 compare_bar_btns">
                        <Button variant="link" onClick={()=>this.props.removeAllCompareItem()} className="mb-3">{t('clearAll')}</Button>
                        <Link className="btn btn-primary btn-md" to="/product-compare">{t('compareProducts')}</Link>
                      </div>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </div>     
    );
  }
}
export default withTranslation()(CompareModal);



