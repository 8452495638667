const initialState = { settings: [] }

const settings = (state = initialState, action) => {
	switch (action.type) {
    case 'SET_SETTINGS':
      return { settings: action.payload };  
  }
  return state
}

export default settings;