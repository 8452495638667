import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MetaTags from '../../../components/MetaTags';
import bikeManualHelmetImg from '../../../assets/images/thumbnail/manual-bike-helmet.jpg';

import pdf1 from '../../../assets/pdf/Helmet-Manual/Arx-Visor_Leaflet_A6_2020_BIKE_SCOTT-Sports_Multi.pdf';
import pdf2 from '../../../assets/pdf/Helmet-Manual/Eyewear-Split_Leaflet_150x585_2014_BIKE_SCOTT-Sports_Multi.pdf';
import pdf3 from '../../../assets/pdf/Helmet-Manual/Helmet_BSI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi.pdf';
import pdf4 from '../../../assets/pdf/Helmet-Manual/Helmet_Fuga_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi.pdf';
import pdf5 from '../../../assets/pdf/Helmet-Manual/Helmet_Karma_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi.pdf';
import pdf6 from '../../../assets/pdf/Helmet-Manual/Helmet_Manual_420x296_2011_BIKE_SCOTT-Sports_Multi.pdf';
import pdf7 from '../../../assets/pdf/Helmet-Manual/Helmet_Manual_420x296_2014_BIKE_SCOTT-Sports_Multi.pdf';
import pdf8 from '../../../assets/pdf/Helmet-Manual/Helmet_Manual_A6_2017_BIKE_SCOTT-Sports_Multi.pdf';
import pdf9 from '../../../assets/pdf/Helmet-Manual/Helmet_Manual_A6_2018_BIKE_SCOTT-Sports_Multi.pdf';
import pdf10 from '../../../assets/pdf/Helmet-Manual/Helmet_Manual_A6_2019_BIKE_SCOTT-Sports_Multi.pdf';
import pdf11 from '../../../assets/pdf/Helmet-Manual/Helmet_NeroPlus_Manual_A6_2020_BIKE_SCOTT-Sports_Multi.pdf';
import pdf12 from '../../../assets/pdf/Helmet-Manual/Helmet_SAI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi.pdf';
import pdf13 from '../../../assets/pdf/Helmet-Manual/Helmet_Spunto_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi.pdf';
import pdf14 from '../../../assets/pdf/Helmet-Manual/Helmet_Voltage_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi.pdf';
import pdf15 from '../../../assets/pdf/Helmet-Manual/Helmet-AeroPlugsCadence_Leaflet_A6_2017_BIKE_SCOTT-Sports_Multi.pdf';
import pdf16 from '../../../assets/pdf/Helmet-Manual/Helmet-Generic_Manual_A6_2020_BIKE_SCOTT-Sports_Multi.pdf';
import pdf17 from '../../../assets/pdf/Helmet-Manual/Helmet-NeroPlus_Manual_A6_2018_BIKE_SCOTT-Sports_Multi.pdf';
import pdf18 from '../../../assets/pdf/Helmet-Manual/Helmet-NeroPlus_Manual_A6_2019_BIKE_SCOTT-Sports_Multi.pdf';
import pdf19 from '../../../assets/pdf/Helmet-Manual/Helmet-Split_Manual_85x160_2017_BIKE_SCOTT-Sports_Multi.pdf';


class ManualsHelmetView extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main">
					<div className="main_section">
						<Container className="">
              <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-bike"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Helmet Manuals</h1>
							<Row className="pd7">
                <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                  <ul className="nav nav-tabs vertical_tabs">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#all_helmet">
                        <i className="far fa-chevron-right"></i> All Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#ten_helmet">
                        <i className="far fa-chevron-right"></i> 2010 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#eleven_helmet">
                        <i className="far fa-chevron-right"></i> 2011 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#thirteen_helmet">
                        <i className="far fa-chevron-right"></i> 2013 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#fourteen_helmet">
                        <i className="far fa-chevron-right"></i> 2014 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#eighteen_helmet">
                        <i className="far fa-chevron-right"></i> 2018 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#ninteen_helmet">
                        <i className="far fa-chevron-right"></i> 2019 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#twenty_helmet">
                        <i className="far fa-chevron-right"></i> 2020 Helmet Manuals | SCOTT Bike
                      </a>
                    </li>              
                  </ul>
                </Col>
                <Col lg={9} sm={12}>
                  <div className="tab-content">
                    <div className="tab-pane container active" id="all_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Arx-Visor_Leaflet_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Eyewear-Split_Leaflet_150x585_2014_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_BSI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Fuga_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Karma_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_420x296_2011_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_420x296_2014_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf11} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_NeroPlus_Manual_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf12} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_SAI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf13} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Spunto_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf14} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Voltage_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf15} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-AeroPlugsCadence_Leaflet_A6_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf16} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-Generic_Manual_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf17} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-NeroPlus_Manual_A6_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf18} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-NeroPlus_Manual_A6_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf19} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-Split_Manual_85x160_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="ten_helmet">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Fuga_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Karma_Manual_420x296_2010_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                       
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="eleven_helmet">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_420x296_2011_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                       
                      </Row>
                    </div>                   
                    <div className="tab-pane container fade" id="thirteen_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_BSI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf12} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_SAI_Leaflet_291x390_2013_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="fourteen_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Eyewear-Split_Leaflet_150x585_2014_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_420x296_2014_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>                    
                    <div className="tab-pane container fade" id="seventeen_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf15} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-AeroPlugsCadence_Leaflet_A6_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf19} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-Split_Manual_85x160_2017_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="eighteen_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf17} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-NeroPlus_Manual_A6_2018_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                        
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="ninteen_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_Manual_A6_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf18} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-NeroPlus_Manual_A6_2019_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                        
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="twenty_helmet">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Arx-Visor_Leaflet_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf11} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet_NeroPlus_Manual_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf16} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualHelmetImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Helmet-Generic_Manual_A6_2020_BIKE_SCOTT-Sports_Multi</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                     
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ManualsHelmetView;