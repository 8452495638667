import React, { Component } from 'react'
import { storeContactus,cancelOrder } from '../../helpers/ServicesHelper'
import { mobile } from '../../helpers/OperationsHelper'
import { toast } from 'react-toastify';
export class CoreReturnReplacement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading:true,
      name:'',
      lname:'',
      LoadingButton:false,
      email:'',
      reason:'',
      image:null,
      howCanHelp:'',
      errors:[],
      success:false,
      refundType:'',
    };
  }

  async submitRequest(order_id, order_detail_id) {
    this.setState({LoadingButton:true})
    var formdata = new FormData(document.getElementById('returnReplacement'));
    formdata.append('refund_requests[order_id]',order_id);
    formdata.append('refund_requests[order_detail_id]',order_detail_id);
    var response = await cancelOrder(formdata);
    if(response.data.status == 'success'){
      document.getElementById("returnReplacement").reset();
      await this.props.getOrderDetail();
      this.props.closePopup()
      this.setState({success:true,LoadingButton:false})
    }else{
      this.setState({errors:response.data.errors,LoadingButton:false})
    }

    if (response.data.status == 'success') {
      await this.props.getOrderDetail();
    } else {
      toast.error(response.data.errors['refund_requests[order_detail_id]'], {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
}

export default CoreReturnReplacement;

