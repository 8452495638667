import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';

import LoadingButton from 'react-bootstrap-button-loader';
import CoreContactUs from '../../../../core/components/customerConnect/CoreContactUs'

class ContactUs extends CoreContactUs {	

	render(){
		return (			
		  <div class="tab-pane fade" id="email_faq" role="tabpanel" aria-labelledby="email_faq_tab">
		  	<Card>
		  		<Card.Body>
		  			<h6 className="black bold text-center text-uppercase mb-4">Send us your query</h6>
		  			<Row>
		  				<Col md={{ span: 8, offset: 2 }}>
				  			<Form className="fields-group-md" id="contactUs">
									<Form.Group>
								    <Form.Label>First Name :</Form.Label>
								    <Form.Control name="contact_us[first_name]" type="text" className="mb-1" />
								    <span className="red">{this.state.errors['contact_us[first_name]']}</span>
								  </Form.Group>	
									<Form.Group>
								    <Form.Label>Last Name :</Form.Label>
								    <Form.Control name="contact_us[last_name]" type="text" className="mb-1" />
								    <span className="red">{this.state.errors['contact_us[last_name]']}</span>
								  </Form.Group>	
								  <Form.Group>
								    <Form.Label>Email ID :</Form.Label>
								    <Form.Control name="contact_us[email]" type="email" className="mb-1" />
								    <span className="red">{this.state.errors['contact_us[email]']}</span>
								  </Form.Group>		
								  <Form.Group>
								    <Form.Label>Reason for Writing? :</Form.Label>
								    <Form.Control name="contact_us[reason]" type="text" className="mb-1" />
								    <span className="red">{this.state.errors['contact_us[reason]']}</span>
								  </Form.Group>		
								  <Form.Group>
								    <Form.Label>How can we help?</Form.Label>
								    <Form.Control name="contact_us[detail]" as="textarea" rows={3} />
								    <span className="red">{this.state.errors['contact_us[detail]']}</span>
								  </Form.Group>	
								  <Form.Group>
								    <Form.Label>Select image</Form.Label>
								    <Form.File  name="contact_us[image]" rows={3} />
								    <span className="red">{this.state.errors['contact_us[image]']}</span>
								  </Form.Group>				  
								</Form>
								{(this.state.success)&&<span className="green">We have recieved your request and one of us will revert you shortly.</span>}
								<div className="text-center">
									<LoadingButton loading={this.state.contactUsLoadingButton} onClick={()=>this.submitContactUs()} style={this.props.styles.primary_btn}>Send</LoadingButton>
								</div>
								<p className="mt-4">If you need any assistance or have any questions, feel free to contact us at <a href="mailto:cs@sportnetwork.in">cs@sportnetwork.in</a></p>
							</Col>
						</Row>
		  		</Card.Body>
		  	</Card>
		  </div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(ContactUs));