import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import bikeManualImg from '../../../assets/images/thumbnail/manual-scott-bike.jpg';

import pdf1 from '../../../assets/pdf/2016-Bike-Manual/Addict_CX_Manual_A5_2016_BIKE_SCOTT-Sports_EN.pdf';
import pdf2 from '../../../assets/pdf/2016-Bike-Manual/Gambler_Manual_A5_2015_BIKE_SCOTT-Sports_EN.pdf';
import pdf3 from '../../../assets/pdf/2016-Bike-Manual/General_Manual_A5_2016_BIKE_SCOTT-Sports_EN.pdf';
import pdf4 from '../../../assets/pdf/2016-Bike-Manual/General_US_Manual_A5_2016_BIKE_SCOTT-Sports_EN.pdf';
import pdf5 from '../../../assets/pdf/2016-Bike-Manual/Genius_LT_Manual_A5_2014_BIKE_SCOTT-Sports_EN.pdf';
import pdf6 from '../../../assets/pdf/2016-Bike-Manual/Genius_Manual_A5_2014_BIKE_SCOTT-Sports_EN.pdf';
import pdf7 from '../../../assets/pdf/2016-Bike-Manual/Plasma_4_Manual_A5_2015_BIKE_SCOTT-Sports_EN.pdf';
import pdf8 from '../../../assets/pdf/2016-Bike-Manual/Plasma_5_Manual_A5_2015_BIKE_SCOTT-Sports_EN.pdf';
import pdf9 from '../../../assets/pdf/2016-Bike-Manual/Road_Manual_A5_2016_BIKE_SCOTT-Sports_EN.pdf';
import pdf10 from '../../../assets/pdf/2016-Bike-Manual/Scale_Manual_A5_2015_BIKE_SCOTT-Sports_EN.pdf';


class ManualsBikeView extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
        <MetaTags {...this.props}/>
				<div className="main">
					<div className="main_section">
						<Container className="">
              <h1 className="page_heading black mb-4 text-uppercase bold"><Link to="/manuals-bike"><i className="fal fa-long-arrow-left pr-2 black" style={{fontWeight:'700'}}></i></Link> Bike Manuals</h1>
							<Row className="pd7">
                <Col lg={3} sm={12} className="mb-4 mb-lg-0">
                  <ul className="nav nav-tabs vertical_tabs">
                    <li className="nav-item">
                      <a className="nav-link active" data-toggle="tab" href="#all_bike">
                        <i className="far fa-chevron-right"></i> All Bike Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#fourteen_bike">
                        <i className="far fa-chevron-right"></i> 2014 Bike Manuals | SCOTT Bike
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#fifteen_bike">
                        <i className="far fa-chevron-right"></i> 2015 Bike Manuals | SCOTT Bike
                      </a>
                    </li>  
                    <li className="nav-item">
                      <a className="nav-link" data-toggle="tab" href="#sixteen_bike">
                        <i className="far fa-chevron-right"></i> 2016 Bike Manuals | SCOTT Bike
                      </a>
                    </li>                     
                           
                  </ul>
                </Col>
                <Col lg={9} sm={12}>
                  <div className="tab-content">
                    <div className="tab-pane container active" id="all_bike">
                      <Row className="pd7 pdf_list">
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Addict_CX_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gambler_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">General_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">General_US_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Genius_LT_Manual_A5_2014_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Genius_Manual_A5_2014_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Plasma_4_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Plasma_5_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Road_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Scale_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="fourteen_bike">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf5} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Genius_LT_Manual_A5_2014_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf6} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Genius_Manual_A5_2014_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                        
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="fifteen_bike">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf2} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Gambler_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>  
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf7} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Plasma_4_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf8} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Plasma_5_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>   
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf10} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Scale_Manual_A5_2015_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                   
                      </Row>
                    </div>
                    <div className="tab-pane container fade" id="sixteen_bike">
                      <Row className="pd7 pdf_list">                       
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf1} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Addict_CX_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf3} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">General_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf4} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">General_US_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>
                        <Col md={3} sm={6} xs={6} className="mb-3">
                          <a href={pdf9} target="_blank">
                            <Card>
                              <Card.Body className="p-0">
                                <img src={bikeManualImg} className="img-fluid" />
                              </Card.Body>
                              <Card.Footer>
                                <p className="mb-0">Road_Manual_A5_2016_BIKE_SCOTT-Sports_EN</p>
                              </Card.Footer>
                            </Card>
                          </a>
                        </Col>                
                      </Row>
                    </div>                    
                  </div>
                </Col>
              </Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default ManualsBikeView;