import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom';
import { connect, Provider} from 'react-redux';

import store from './store';
import App from './web/config/App';
import reportWebVitals from './web/config/reportWebVitals';
import './web/utils/i18n';

ReactDOM.hydrate(
  <React.StrictMode>
  	<Provider store={store} >
      <App />
    </Provider>
  </React.StrictMode>, 
  document.getElementById('root')
);
