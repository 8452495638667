import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
class Breadcrumbs extends React.Component {


  render() {
    console.log("Breadcrums: ", this.props.breadcrumbs);
    return (
      <div className="container breadcrumbs_container">
        <ol className="breadcrumb">
          <li>
            <Link to="/" className="fa fa-home" aria-hidden="true"></Link>
          </li>
          {this.props.breadcrumbs.map((breadcrumb, index) => (
            breadcrumb.name != null && (
              <React.Fragment key={index}>
                <li>
                  <Link to={this.props.breadcrumbs.length - 1 > index ? breadcrumb.link : '#'}>{breadcrumb.name}</Link>
                </li>
              </React.Fragment>
            )
          ))}
        </ol>
      </div>
    );
  }
}

export default Breadcrumbs;



