import React from 'react'
import { verifyLinkPassword,resetPassword } from '../../../../core/helpers/ServicesHelper'
class ResetPasswordController extends React.Component {
  constructor(props){
		super(props);
    this.state={
      'confirmPassword':'',
      'password':'',
      'email_id':'',
      'isValidLink':true,
      'message':'',
      'messageTitle':this.props.t('linkExpired'),
      'type':'danger',
      'errors':[],
      'showMessage':false
		}
	}

	async componentDidMount(){
		var urlParam = this.props.location.search;
		var verifyLink = await verifyLinkPassword(urlParam);
		if(verifyLink.data.status == 'error'){
			this.setState({'message':verifyLink.data.message,'showMessage':true})
		}else{
			this.setState({'message':"",'showMessage':false,email_id:verifyLink.data.email_id})
		}
	}

	async updatePassword(){
		var formdata = []
		var token = new URLSearchParams(this.props.location.search).get("token");
		this.setState({'errors':[]})
		formdata['reset_password[password]'] = this.state.password
		formdata['confirm_password'] = this.state.confirmPassword
		formdata['reset_password[reset_token]'] = token
		var response = await resetPassword(formdata);
		if(response.data.status == 'success'){
			this.setState({'messageTitle':this.props.t('passwordChangedText'),'message':this.props.t('passwordReset'),type:'success','showMessage':true})
			setTimeout(function(){ window.location.assign('/login') }, 2000);
		}else if(response.data.status == 'error'){
			this.setState({'errors':response.data.errors})
		}else{
			this.setState({'messageTitle':this.props.t('unableToResetPassword'),'message':this.props.t('passwordIssue'),type:'danger','showMessage':true})
		}
	}
}

export default ResetPasswordController;