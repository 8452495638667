import React from 'react';
import { getDealerLeader } from '../../helpers/ServicesHelper';

class CoreHome extends React.Component {
  constructor(props){
    super(props);
    this.state={
      loader: false,
      ledgerLoading: true,
      dueDetails:[],
      creditBal:'',
      totalCreditAvailable:0,
      totalCredit:0,
      sellerAccount:[],
      user: {},
    }    
  }

  componentDidMount(){
    this.getDealer();
    this.setState({ loader: false });
  }

   async getDealer(){
    if (typeof this.props.user.dealer_id != 'undefined' && this.props.user.dealer_id != 'null') {
      var dealer = await getDealerLeader();
      if(dealer.data.status=='success'){
        await this.setState({creditBal:dealer.data.data.credit_amount_used,
                             totalCreditAvailable:dealer.data.data.total_credit_available,
                             totalCredit:dealer.data.data.total_credit_amount,
                             sellerAccount:dealer.data.data.seller_tally_account,
                             dueDetails:dealer.data.data.due_details,ledgerLoading:false});
      }
      else{
        console.log("Records Not Found");
      }  
    } 
  }
}

export default CoreHome;
