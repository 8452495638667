import { checkValidHash, mobile, getMobileToast,dealersPortal } from './OperationsHelper';
import { placeOrder } from './ServicesHelper';
import { toast } from 'react-toastify';
import cookie from 'react-cookies';

export const submitOrder = async (carts, orderType=0, userId, userType) => {
  var formdata = [];
  formdata['orders[remark]'] = '';
  formdata['orders[order_type]'] = orderType;
  
  if(userId != null && userId != "" && carts != ''){
    formdata['orders[user_id]'] = userId;
    formdata['orders[status]'] = 'pending';
    formdata['orders[payment_status]'] = (dealersPortal == true )?'':'waiting for payment';
    
    carts.map((value, index)=>{
      formdata['orders[coupon_code]'] = value.coupon_code;
      formdata['order_details['+index+'][item_id]'] = value.item_id;
      formdata['order_details['+index+'][transaction_id]'] = value.id;
      formdata['order_details['+index+'][quantity]'] = value.quantity;
      formdata['order_details['+index+'][status]'] = 'pending';
      formdata['order_details['+index+'][payment_status]'] = (dealersPortal == true)?'':'waiting for payment';
    });
    
    var response = await placeOrder(formdata);
    return response;
  } else {
    window.location.href = '/login';
  }
}





