import axios from 'axios';
import qs from 'qs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import cookie from 'react-cookies'
import { getAsyncItems, mobile } from './OperationsHelper.js';

export const axiosGet = async (url) => {
  // console.log(url)
  var response = [];
  let authToken = "";
  let hash = "";
  if (mobile != true) {
    authToken = cookie.load('authToken');
    hash = cookie.load('hash');
  } else {
    var userData = await AsyncStorage.getItem('userData');
    var skipLogin = await AsyncStorage.getItem('skipLogin');
    hash = await AsyncStorage.getItem('hash');

    if (authToken == "" && userData != null && skipLogin != 1) {
      authToken = JSON.parse(userData).authToken;
    }

    if (hash != null) {
      hash = JSON.parse(hash);
    }
  }

  let config = {
    headers: {
      Authorization: "Basic Ymhhc2thcjoxMjM0NTY=",
      authToken: (typeof authToken != 'undefined') ? authToken : '',
      hash: hash,
    }
  }
  // console.log(url, config);

  response = await axios.get(url, config).then(response => {
    // console.log(response);
    return response;
  })
    .catch(error => {
      console.log(error)
      return { data: { 'status': 'fail' } };
    });
  // console.log(response);
  return response;
}

export const axiosPost = async (url, data, stringify = true) => {
  // console.log(url)
  var response = '';
  let authToken = "";
  let hash = "";
  if (mobile != true) {
    authToken = cookie.load('authToken');
    hash = cookie.load('hash');
  } else {
    var userData = await AsyncStorage.getItem('userData');
    var skipLogin = await AsyncStorage.getItem('skipLogin');
    hash = await AsyncStorage.getItem('hash');
    if (authToken == "" && userData != null && skipLogin != 1) {
      authToken = JSON.parse(userData).authToken;
    }
    if (hash != null) {
      hash = JSON.parse(hash);
    }
  }

  let config = {
    headers: {
      Authorization: "Basic Ymhhc2thcjoxMjM0NTY=",
      authToken: (typeof authToken != 'undefined') ? authToken : '',
      hash: hash,

    }
  }

  if (stringify == true)
    data = qs.stringify(data);

  response = await axios.post(url, data, config).then(response => {
    // console.log(response)
    return response;
  })
    .catch(error => {
      console.log(error);
      return { data: { 'status': 'fail' } };
    });
  return response;
}