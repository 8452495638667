import React from 'react';
import {connect} from 'react-redux'
import { withTranslation } from 'react-i18next';

class AccountAddress extends React.Component {

	render(){
		
		const { t, tallyCompany ,tallyVoucher } = this.props;
		return (
      <div>
        {tallyVoucher.tally_company_id != '' && typeof tallyVoucher.tally_company_id != 'undefined' && (
          <React.Fragment>
            <h4 className="bold black">{tallyCompany.company_name}</h4>
            <p className="mb-4">{tallyCompany.company_address}</p>
            <h5 className="bold black">Warehouse :</h5>
            <p className="mb-0">{tallyCompany.warehouse_address}</p>
            <p className="mb-0">GSTIN/UIN: {tallyCompany.company_gst_number}</p>
            <p className="mb-0">State Name : {tallyCompany.company_state_name}, Code : {tallyCompany.company_state_code}</p>
            <p className="mb-0">CIN: {tallyCompany.company_cin}</p>
            <p>E-Mail : <a href={tallyCompany.company_email}>{tallyCompany.company_email}</a></p>
          </React.Fragment>
        )}
      </div>			
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(AccountAddress));