import React from 'react';
import {Container, Row, Col, Button, Card, Form, Tabs, Tab} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getImageUrl, numberWithFloat, dealersPortal } from  '../../../../core/helpers/OperationsHelper';
import loader from '../../../assets/loader/circle-loader.gif';
import OrderNotFound from '../../../components/OrderNotFound';
import Loader from '../../../components/Loader';
import OrderProductDetail from './OrderProductDetail';

class Orders extends React.Component {

	render(){
		var date = new Date();
		const { t } = this.props;
		return (
			<React.Fragment>
				<Row className="mt-3">
		    	<Col lg={12}>
		    		<div className="row">
		    			<div className="col-lg-2 col-sm-4 col-6">
					    	<Form.Group>
							    <Form.Control value={this.props.status} onChange={(e) => this.props.setFilterUrl(e.target.value)} as="select">
							      <option value={'month-3'}>{t('past3Month')}</option>
							      <option value={'days-30'}>{t('last30days')}</option>
							      <option value={date.getFullYear()}>{date.getFullYear()}</option>
							      <option value={date.getFullYear() - 1}>{date.getFullYear() - 1}</option>
							      <option value={date.getFullYear() - 2}>{date.getFullYear() - 2}</option>
							    </Form.Control>
								</Form.Group>
		    			</div>
		    			<div className="col text-right">
			    			<p className="mb-0"><span className="bold">{this.props.orders.length}</span> {t('orders')}</p>
			    		</div>
						</div>
		    	</Col>
		    </Row>
		    {

		    	this.props.orders.length > 0 ? (
		    	this.props.orders.map((order, index) => (
		    		<Row kye={index}>
				    	<Col lg={12}>
				    		<Card className="order_card cart_table mb-3">
				    			<Card.Header>
				    				<Row className="pd7"> 
				    					<Col md={2} xs={6} className="mb-3 mb-sm-0">
				    						<p className="text-uppercase gray mb-0" style={{fontSize:'0.90rem'}}>{t('orderPlaced')}</p>
				    						<p className="gray mb-0" style={{fontSize:'0.90rem'}}>{order.date}</p>
				    					</Col>
				    					<Col md={2} xs={6} className="mb-3 mb-sm-0 text-right text-sm-left">
				    						<p className="text-uppercase gray mb-0" style={{fontSize:'0.90rem'}}>{t('total')} {order.product_type_field_name}</p>
				    						{order.product_type_field_currency == "0" && (
				    							<p className="gray mb-0" style={{fontSize:'0.90rem'}}>{order.product_type_field_value+' '+order.product_type_field_unit}</p>
				    						)}
				    						{order.product_type_field_currency == "1" && (
				    							<p className="gray mb-0" style={{fontSize:'0.90rem'}}><i className="fal fa-rupee-sign"></i>  {numberWithFloat(order.total)}</p>
				    						)}
				    					</Col>
				    					{order.shipping_address != null && 
				    						<Col md={2} xs={6}>
					    						<p className="text-uppercase gray mb-0" style={{fontSize:'0.90rem'}}>ship to</p>
					    						<div className="dropdown">
													  <button 
													  	className="btn btn-secondary dropdown-toggle" 
													  	type="button" id="ship_to" 
													  	data-toggle="dropdown" aria-haspopup="true" 
													  	aria-expanded="false"
													  	style={{ border: 0, padding: 0 }}
													  >
													  	{dealersPortal == true ? order.shipping_address.trading_name : order.shipping_address.name}
													  </button>
													  <div className="dropdown-menu" aria-labelledby="ship_to">
													  	{dealersPortal == true && (<p className="pl-2 mb-0 bold">{order.shipping_address.trading_name}</p>)}
													  	{dealersPortal == false && (<p className="pl-2 mb-0 bold">{order.shipping_address.name}</p>)}
													    <p className="pl-2 mb-0">{order.shipping_address.street_address}</p>
						    							<p className="pl-2 mb-0">{order.shipping_address.city+', '+order.shipping_address.state+' - '+order.shipping_address.postal_code}</p>
						    							<p className="pl-2 mb-0">{order.shipping_address.country}</p>
						    							<p className="pl-2 mb-0">Phone: {order.shipping_address.mobile_no}</p>
													  </div>
													</div>
					    					</Col>
				    					}

				    					
				    					<Col md={6} xs={6} className="text-right">
				    						<p className="mb-0" style={{fontSize:'0.90rem'}}>{t('order')} # {order.id}</p>
				    						<div className="d-flex justify-content-end align-items-center">
				    							<div className="flex-fill detail_link text-capitalize">
				    								<Link to={"/order-detail/"+order.id}>{t('orderDetails')}</Link>
				    							</div>
				    							
				    							{/*<div className="">
				    								<div className="dropdown">
														  <button 
													  		className="btn btn-secondary dropdown-toggle" type="button" 
													  		id="invoice" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
													  		style={{ border: 0, padding: 0 }}
														  >
														    Invoice
														  </button>
														  <div className="dropdown-menu" aria-labelledby="invoice">
														    <a className="dropdown-item" href="#">Printable order summary</a>
														  </div>
														</div>
				    							</div>*/}
				    						</div>
				    					</Col>
				    				</Row>										    				
				    			</Card.Header>
				    			<OrderProductDetail 
				    				showStatus={true}
				    				orderDetails={order.order_details} 
				    				images={this.props.images} 
				    				settings={this.props.settings}
				    				orderStatus={order.status}
				    				showNotes={false}
				    				showCancelOrderButton={false}
				    			/>
				    		</Card>
				    	</Col>
				    </Row>
		    	))) : <OrderNotFound parentProps={this.props.parentProps}/>
				}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(Orders));