import React from 'react';
import {Container, Row, Col, Button, Form, Modal} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import Tostify from '../../../components/Toaster';
import CoreUser from '../../../../core/components/users/CoreUser';
class ChangePassword extends CoreUser {	
	render(){
		const { t } = this.props;
		return (
			<React.Fragment> 
				<Tostify />  
				<div className="main mt-5">	
					<div className="main_section">
						<Container>					
							<Row>
								<Col lg={6} className="mx-auto">
									<div className="">
										<h1 className="page_heading bold text-uppercase black text-center mb-4">{t('changePassword')}</h1>
									</div>
                  <Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4">{t('oldPassword')}:</Form.Label>		
											<Col lg="8">
									    					
												<Form.Control value={this.state.oldPassword}
																			onChange={(e)=>this.setState({oldPassword:e.target.value,errorChangePassword:''})} 
																			type="password" className="mb-1" />
									    	<span className="red">
													{(this.state.errorChangePassword['change_password[old_password]']!= "" && 
														typeof this.state.errorChangePassword['change_password[old_password]']!= 'undefined')?
														this.state.errorChangePassword['change_password[old_password]']:""}
												</span>			    	
									    </Col>						   
									  </Form.Group>									  
									</Form>
                  <Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4">{t('newPassword')}:</Form.Label>
                      <Col lg="8">
											<Form.Control value={this.state.newPassword}
																			onChange={(e)=>this.setState({newPassword:e.target.value,errorChangePassword:''})} 
																			type="password" className="mb-1" />
									    	<span className="red">
													{(this.state.errorChangePassword['change_password[new_password]']!= "" && 
														typeof this.state.errorChangePassword['change_password[new_password]']!= 'undefined')?
														this.state.errorChangePassword['change_password[new_password]']:""}
												</span>			    										    	
									    </Col>				   
									  </Form.Group>									  
									</Form>
                  <Form className="fields-group-md">
										<Form.Group as={Row}>
									    <Form.Label column lg="4">{t('confirmPassword')}:</Form.Label>		
                      <Col lg="8">
												<Form.Control value={this.state.changeConfirmPassword}
																				onChange={(e)=>this.setState({changeConfirmPassword:e.target.value,errorChangePassword:''})} 
																				type="password" className="mb-1" />		
												<span className="red">
													{(this.state.errorChangePassword['confirm_password']!= "" && 
														typeof this.state.errorChangePassword['confirm_password']!= 'undefined')?
														this.state.errorChangePassword['confirm_password']:""}
												</span>							    	
									    </Col>						   
									  </Form.Group>									  
									</Form>
									<div className="text-center mt-5">
										
										<Button className="btn btn-md" onClick={()=>this.changePassword()}>{t('submit')}</Button>
										<Modal show={this.state.show} onHide={()=>this.handleClose()}>
											<Modal.Footer className="justify-content-center d-block">
												<div>
													<Button className="btn-sm mb-2" variant="primary" onClick={()=>this.allDeviceLogout()} style={this.props.styles.primary_btn}>
														{t('logoutAllDevice')}
													</Button>
												</div>
												<div>
													<Button className="btn-sm mb-2" variant="primary" onClick={()=>this.removeStore()} style={this.props.styles.primary_btn}>
													{t('logoutCurrentDevice')}
													</Button>
												</div>
												<div>
													<Button className="btn-sm" variant="primary" onClick={()=>window.location.assign('/')}>
														{t('loggedIn')}
													</Button>
												</div>
											</Modal.Footer>
										</Modal>
									</div>
								</Col>								
							</Row>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {    
    styles: state.styles.styles,
  }
}



export default withTranslation()(connect(mapStateToProps)(ChangePassword));
