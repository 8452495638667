import React from 'react';
import {Container} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import LogoImg from '../assets/images/icons/scott_network_logo.jpeg'; 
import { Link } from 'react-router-dom'

class NotFound extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container">
        <div className="col-sm-8 mx-auto mt-5">
          <div className="text-center mb-5">
            <img src={LogoImg} className="img-fluid" width="100px" />
          </div>
          <div className="d-flex align-items-start">
            <div className="pr-3">
              <h2 className="blue"><i className="fal fa-question-circle"></i></h2>
            </div>
            <div>
              <h5 className="bold blue">{t('lookingForSomething')}</h5>
              <p>{t('sorryText')}.</p>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
        </div>
        <h5 className="bold text-center mt-5"><i className="fas fa-caret-right"></i> {t('goto')} <Link to="/">{t('home')}</Link> {t('page')}</h5>
      </div>
    );
  }
}

export default withTranslation()(NotFound);



