import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {Container, Col, Row, Button, Table} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import ProductOne from '../../../assets/images/products/spark-rc-cycle.png';
import { getApiUrl, getImagePath } from '../../../../core/helpers/OperationsHelper';
import CoreProductCompare from '../../../../core/components/products/CoreProductCompare';
import MetaTags from '../../../components/MetaTags';

class ProductCompare extends CoreProductCompare {
  render() {
    const { t } = this.props;
    return (
      <div className="main">
        <MetaTags {...this.props}/>
        <Container>
          <h5 className="bold text-uppercase mt-5 text-left mb-3 black">{t('compareProducts')}</h5>
          <Row className="mt-5">
            <Col lg={12}>
              <Table size="sm" className="compare_table" responsive="sm">
                <tbody>
                  <tr className="compare_img product_compare">
                    <td className="pb-3">
                      <Link to="/" className="text-center return_link"><i className="fal fa-chevron-circle-left mb-2"></i><br/> {t('returnToShopping')}</Link>
                    </td>
                    {this.state.products.length > 0 && this.state.products.map((product, index) => 
                      <td key={index} width="20%" className="comparable pb-3">
                        <div className="product_card text-center">
                          <div className="product_inside">
                            <Link to={'/pp/'+product.product.slug} className="product_link">
                              <img src={this.props.settings.image_url+'products/images/large/'+product.product_images.name} className="product_img img-fluid" />
                            </Link>
                            <Link to={'/pp/'+product.product.slug} className="product_label gray">
                              <h7 className="text-uppercase mt-2">{product.product.name}</h7>
                            </Link> 
                            <Link to={"/pp/"+product.product.slug} className="black text-underline d-block">{t('viewDetails')}</Link> 
                            <Button type="button" variant="link" className="mx-auto cancel btn-sm"><i className="far fa-times"></i></Button>
                          </div>
                        </div>  
                      </td>
                    )}
                    <td width="20%" className="pt-3">
                      <Link to="/" className="text-center return_link"><i className="fal fa-plus-circle mb-2"></i><br/>{t('addProduct')}</Link>
                    </td>
                  </tr>
                  {typeof this.state.specifications != 'undefined' && this.state.specifications.map((specification, index) => 
                    <tr key={index}>
                      <th width="20%">{specification}</th>
                      {
                        this.state.products.length > 0 && this.state.products.map((product,index) => (
                          <td width="20%">{(typeof product.product_types[specification] != 'undefined') ? product.product_types[specification] : '-'}</td>
                        ))
                      }                       
                    </tr> 
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>     
    );
  }
}
const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings
  }
}

export default withTranslation()(connect(mapStateToProps,null)(ProductCompare));



