import React from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom'

import prebookimg from "../../../assets/images/prebookImg/prebook.png";
import generalImg from '../../../assets/images/icons/information.png';
import bikeImg from '../../../assets/images/icons/bike.png';
import ebikeImg from '../../../assets/images/icons/ebike.png';
import runningImg from '../../../assets/images/icons/running.png';
import motosportsImg from '../../../assets/images/icons/motosports.png';
import accessoriesImg from '../../../assets/images/icons/helmetIcon.png';

import GeneralFaq from './GeneralFaq';
import MotosportsFaq from './MotosportsFaq';
import RunningFaq from './RunningFaq';
import BikeFaq from './BikeFaq';
import AccessoriesFaq from './AccessoriesFaq';
import EbikeFaq from './EbikeFaq';
import PreBooking from "./PreBooking";
import "../../../assets/css/prebooking.css";

import prebookImg1 from "../../../assets/images/prebookImg/prebooking1.jpg";
import prebookImg2 from "../../../assets/images/prebookImg/prebooking2.jpg";
import prebookImg3 from "../../../assets/images/prebookImg/prebooking3.jpg";
import prebookImg4 from "../../../assets/images/prebookImg/prebooking4.jpg";
import prebookImg5 from "../../../assets/images/prebookImg/prebooking5.jpg";
import prebookImg6 from "../../../assets/images/prebookImg/prebooking6.jpg";
import prebookImg7 from "../../../assets/images/prebookImg/prebooking7.jpg";
import prebookImg8 from "../../../assets/images/prebookImg/prebooking8.jpg";

class Faq extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false,
			redirect: false
		}
		this.handleShow = this.handleShow.bind(this);
	}

	handleShow() {
		console.log('modal');
		// this.props.history.push('/pl/bikes/bikes/pre-booking');
		// return <Redirect to="/pl/bikes/bikes/pre-booking" />
		this.setState({ redirect: !this.state.redirect });

	}
	render() {
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to="/pl/bikes/bikes/pre-booking" />;
		}
		return (
			<div class="tab-pane fade show active" id="contact_faq" role="tabpanel" aria-labelledby="contact_faq_tab">
				<ul class="nav row" role="tablist">
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#general_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={generalImg} height="50px" />
										<h5 className="mt-3 black mb-0">General</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#bike_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={bikeImg} height="50px" />
										<h5 className="mt-3 black mb-0">Bike</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#ebike_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={ebikeImg} height="50px" />
										<h5 className="mt-3 black mb-0">E-Bike</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#running_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={runningImg} height="50px" />
										<h5 className="mt-3 black mb-0">Running</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#motosports_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={motosportsImg} height="50px" />
										<h5 className="mt-3 black mb-0">Motosports</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li class="nav-item col-sm-4">
						<a class="nav-link" href="#accessories_faq" data-toggle="tab">
							<Card>
								<Card.Body className="text-center shadow-sm">
									<div>
										<img src={accessoriesImg} height="50px" />
										<h5 className="mt-3 black mb-0">Parts and Accessories</h5>
									</div>
								</Card.Body>
							</Card>
						</a>
					</li>
					<li className="nav-item col-sm-6 col-md-4">
						<a className="nav-link" href="#prebook_faq" data-toggle="tab">
							<div className="card">
								<div className="text-center shadow-sm card-body ">
									<div>
										<img src={prebookimg} height="70px" width="70px" />
										<h5 className=" black mb-0">Prebooking</h5>
									</div>
								</div>
							</div>
						</a>
					</li>
				</ul>
				<div class="tab-content" id="faq-tabContent">
					<GeneralFaq />
					<BikeFaq />
					<RunningFaq />
					<MotosportsFaq />
					<AccessoriesFaq />
					<EbikeFaq />
					{/* <PreBooking /> */}
					<div className="tab-pane fade" id="prebook_faq" role="tabpanel" aria-labelledby="prebook_faq_tab">
						<h5 className="black bold mt-5 ms-4">
							<a href="" className='btn btn-warning bold' data-toggle="modal" data-target="#myModal">Steps To Prebook</a>
						</h5>
					</div>

					<div className="container prebook-modal-cont">

						<div className="modal" id="myModal">
							<div className="modal-dialog">
								<div className="modal-content">


									<div className="modal-header">
										<h4 className="modal-title">FAQ</h4>
										<button type="button" className="close modal-close" data-dismiss="modal">&times;</button>
									</div>


									<div className="modal-body prebook-modal-body">
										<div className="row">
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg1} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg2} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg3} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg4} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg5} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className="mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg6} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg7} alt="" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-12">
												<div className=" mb-3">
													<div className="">
														<div className="prebook_img_steps">
															<img src={prebookImg8} alt="" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>


									<div className="modal-footer">
										<Link to="/pl/bikes/bikes/pre-booking" className="pre-book-btn close btn btn-warning" onClick={this.handleShow} data-dismiss="modal">Pre-Booking</Link>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}
}

export default Faq;