import { getBrands } from '../core/helpers/ServicesHelper';

function brandAction(brands) {
  return {
        	type: "SET_CUSTOMER_BRANDS",
        	payload:brands,
    		}
};

async function getBrandAPICall() {
  return await getBrands();
};


export const setBrandData = (data) => async (dispatch) =>{
  var getBrand = await getBrandAPICall();
  if(getBrand.data.status == 'success'){
	  dispatch(brandAction(getBrand.data.data))
  }
}

const initialState = {brands: []}
const brands = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_CUSTOMER_BRANDS':
      return { brands: action.payload}; 
  }
  return state
}

export default brands;