import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';

import ProductSpecifications from './ProductSpecifications';
import Geomatry from './Geomatry';
import Technologies from './Technologies';
import WhyToBuy from './WhyToBuy';
import '../../../assets/css/specification.css';

class ProductDetailsTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { key: 'specifications' }
  }

  componentDidMount() {
    if (this.props.specifications != '')
      this.setState({ 'key': 'specifications' });
    else if (this.props.specifications === '' && this.props.geometryAttachment != '')
      this.setState({ 'key': 'geomatry' });
    else if (this.props.specifications === '' && this.props.geometryAttachment === '' && this.props.technologies != "")
      this.setState({ 'key': 'technologies' });
    else if (this.props.specifications === '' && this.props.geometryAttachment === '' && this.props.technologies === "" && this.props.whyToBuy != "")
      this.setState({ 'key': 'why_to_buy' });
  }

  render() {
    return (
      <div className="specification_sec mt-5">
        <div className="product_specs_content py-1">
          <Container>
            <Row>
              <Col lg={12}>
                <p className="mb-0 text-center">{this.props.description}</p>
              </Col>
            </Row>
          </Container>  
        </div>
        <Tabs
          className="theme_tabs d-block text-center border-0"
          activeKey={this.state.key}
          onSelect={(k) => this.setState({ 'key': k })}
        >
          {(this.props.specifications != "" && this.props.specifications.length > 0) && (
            <Tab eventKey="specifications" title="specifications" className="spec-pad ">
              <div className="tabContent_data product_specs_content">
                <Container>
                  <ProductSpecifications specifications={this.props.specifications} description={this.props.description} />
                </Container>
              </div>
            </Tab>
          )}
          {(this.props.geometryAttachment != "" && this.props.geometryAttachment != null) && (
            <Tab eventKey="geomatry" title="geometry">
              <div className="tabContent_data product_list">
                <Geomatry itemId={this.props.itemId} geometryAttachment={this.props.geometryAttachment} />
              </div>
            </Tab>
          )}
          {(this.props.technologies != "") && (
            <Tab eventKey="technologies" title="technologies">
              <div className="tabContent_data product_list ">
                <Technologies technologies={this.props.technologies} />
              </div>
            </Tab>
          )}

          {(this.props.whyToBuy != '' && this.props.whyToBuy.length > 0) && (
            <Tab eventKey="why_to_buy" title="Why to buy">
              <div className="tabContent_data product_list ">
                <WhyToBuy whyToBuy={this.props.whyToBuy} />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
};

export default ProductDetailsTabs;