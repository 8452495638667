import React from 'react';
import {Container, Row, Col, Table, Card} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import MetaTags from '../../../components/MetaTags';
import Faq from '../components/Faq';

class PrivacyPolicy extends React.Component {	
	componentDidMount() {
	  window.scrollTo(0, 0)
	}
	render(){
		return (
			<React.Fragment> 
				<MetaTags {...this.props}/>
				<div className="main mt-5">
					<div className="main_section">
						<Container className="">
							<h1 className="page_heading bold text-uppercase black mb-3 text-left mb-3">Privacy Policy</h1>
							<div>
								<p>THIS PRIVACY POLICY (“PRIVACY POLICY”) SETS FORTH HOW SCOTT NETWORK SOLUTIONS (SNS) USES AND PROTECTS THE PERSONAL INFORMATION THAT THE USER (AS DEFINED BELOW) PROVIDES TO US WHEN THE USER USES THIS PORTAL. THIS PRIVACY POLICY APPLIES ONLY TO PERSONAL INFORMATION COLLECTED ON THE PORTAL. THIS PRIVACY POLICY DOES NOT APPLY TO INFORMATION COLLECTED BY US IN OTHER WAYS, INCLUDING OFFLINE INFORMATION. PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY CONTINUING TO USE THE SERVICES OR ACCESS THE PORTAL, THE USER AGREES TO THIS PRIVACY POLICY. IF THE USER DOES NOT AGREE TO THIS PRIVACY POLICY, THE USER SHALL NOT AVAIL THE SERVICES OR ACCESS THE PORTAL.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Introduction</h2>
								<p>We, SCOTT NETWORK SOLUTIONS (SNS) (“SNS”; “we”; “us”; “our”) have developed an online marketplace for sports equipment (“Portal”) accessible through smartphones and computer devices. The Portal enables the users (“User”, “you”, “your”) to buy or sell certain sports products enlisted on the Portal (“Services”). For the provisions of the services, SNS shall collect the Personal Information of the Users. We are committed to protect the Personal Information (defined below) and have made this Privacy Policy to describe the procedures we adhere to for collecting, using, and disclosing the Personal Information. We recommend the Users to read this Privacy Policy carefully so that the Users understand our approach towards the use of their Personal Information.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Governing Statute</h2>
								<p>This Privacy Policy is governed by and is compliant with the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, which is designed to protect the Personal Information; and other applicable rules and regulations related to privacy.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Personal Information Collected</h2>
								<p>This Privacy Policy applies to Personal Information collected and controlled by SNS, about the Users, when they use and register on the Portal, for the Services. For availing the Services, the Users are required to register with the Portal by using the sign-up option available (“Account”). During the registration process and for using the Services, the Users shall be required to share/upload certain Personal Information. For the purposes of this Privacy Policy, “Personal Information” means information that can be used to personally identify the User, including but not limited to the User’s name, address, telephone number, e-mail address, age, date of birth, billing and transaction information, product information, debit/credit card or other financial information, bank account details, contact preferences and similar other information.</p>
								<p>The User undertakes that the User shall be solely responsible for the accuracy and truthfulness of the Personal Information shared with us. In the event the User is sharing any Personal Information on behalf of a third person, the User represents and warrants that he has the necessary authority to share such Personal Information with SNS and SNS shall not be responsible for verifying the same. The User understands and acknowledges that such Personal Information shall be subject to the terms and conditions of this Privacy Policy.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Non-Personal Information</h2>
								<p>We collect the User’s Internet Protocol (“IP”) addresses when the User visits the Portal to track and aggregate non-personal information. Non-personal information may include the browser name, the type of electronic device, manufacturer, make, model, unique ID, location of the electronic device and technical information about the User’s means of connection to our Portal, such as the operating system and the Internet service providers utilized and other similar information. For example, we may use IP addresses to monitor the regions from which the User navigates our Portal. The User understands that this information shall be non-personal information and could be used by SNS, for its own business purposes.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Use of Personal Information</h2>
								<p>We shall use the Personal Information in the following cases:</p>
								<ol type="i">
									<li className="pb-2">to provide the User with the Services and to assist the User in the event the User needs any additional support;</li><li className="pb-2">for creation or development of business intelligence or data analytics in relation to the Services provided by SNS;</li><li className="pb-2">to improve the Services;</li><li className="pb-2">to maintain and manage the Account;</li><li className="pb-2">to assist the User with technical difficulties that may arise in relation to the User’s use and access of the Portal;</li><li className="pb-2">to manage our relationship with the User;</li><li className="pb-2">for internal record keeping; and</li><li className="pb-2">to comply with our legal or statutory obligations.</li>
								</ol>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Anonymized Data</h2>
								<p>We may also use your Personal Information collected to create aggregate anonymized data. We shall ensure that such anonymized data will not be directly identifiable to you or to the Personal Information shared with us. We may use this anonymized data for any purpose including but not limited to research, analytics, and to improve our Services. By using the Privacy Policy, you provide us the right to use your Personal Information to create anonymized data and use it for our business purposes.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Cookies</h2>
								<p>Our Portal may use "cookies" to enhance the User experience. A cookie is a small file which asks permission to be placed on the User’s hard drive. Once the User agrees, the file is added and the cookie helps analyze web traffic or lets the User know when the User visit a particular site. Cookies allow web Portals to respond to the User as an individual. The web Portal can tailor its operations to the User’s needs, likes and dislikes by gathering and remembering information about the User’s preferences. We use traffic log cookies to identify which pages are being used. This helps us analyze data about web page traffic and improve our Portal in order to tailor it to the User’s needs. We only use this information for statistical analysis purposes and then the data is removed from the systems. Overall, cookies help us provide the User with a better Portal, by enabling us to monitor which pages that the User find useful and which the User do not. A cookie in no way gives us access to User’s electronic device or any information about the User, other than the data that the User choose to share with us. Use of cookies may also allow us to automate entry into password-protected portions of our Portal so that the User will not need to re-enter the User’s password each time the User visit such Portal. Cookies alone do not personally identify User; they are designed to recognize the User’s web browser. However, if the User have provided us with personal information, such as through completion of a web form, cookies may be linked to the User’s personal information, such as the User’s e-mail address or password. By using the Portal, the User signify the User’s consent to our use of the cookies.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Disclosures</h2>
								<p>We do not sell, rent, share, distribute, lease or otherwise provide the User’s Personal Information to third parties, without the User’s prior consent. Keeping this in mind, we may disclose the User’s Personal Information in the following cases:</p>
								<ul>
									<li className="pb-3">Administrators: We may provide access to the User’s Personal information to any of our authorized administrators for an internal business purpose, who shall be under confidentiality obligations towards the same.</li>
									<li className="pb-3">Affiliates: We may provide the Personal Information we collect to our affiliates. For example, we may disclose Personal Information to our affiliates in order to respond to the User’s requests for information or the Services.</li>
									<li className="pb-3">Service Providers: We may share the User’s Personal Information to the service providers, who are working with us in connection with the operation of the Services or the Portal, so long as such service providers are subject to confidentiality restrictions consistent with this Privacy Policy.</li>
									<li className="pb-3">Merger or Acquisition: We may transfer the User’s Personal Information if we are acquired by/we acquire or merge with another Clinic or transfer a part of our business, including the Portal, to a third party. Any third party or resultant entity that receives the User’s Personal Information pursuant to a merger, demerger or business transfer shall have the right to continue to use the User’s Personal Information. In the event of such a sale or transfer, we may notify the Users.</li>
									<li>Legal and Regulatory Authorities: We may be required to disclose the User’s Personal Information due to legal or regulatory requirements. In such instances, we reserve the right to disclose the User’s Personal Information as required in order to comply with our legal obligations, including but not limited to complying with court orders, warrants, or discovery requests. We may also disclose the User’s Personal Information to (a) law enforcement officers or others; (b) Credit Information Companies; (c) to comply with a judicial proceeding, court order, or legal process served on us or the Portal ; (d) to enforce or apply this Privacy Policy or our other policies or agreements; (e) for an insolvency proceeding involving all or part of the business or asset to which the information pertains; (f) respond to claims that any Personal Information violates the rights of third-parties; (g) or protect the rights, property, or personal safety of SNS, or the general public. The User agree and acknowledge that we may not inform the User prior to or after disclosures made according to this section.</li>
								</ul>
								<p>Not with standing anything mentioned here in above, SNS shall not be responsible for the actions or omissions of the parties (including but not limited to the entities listed above) with whom the Personal Information is shared, nor shall SNS be responsible and/or liable for any additional information the User may chooses to provide directly to any third party.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Data Retension</h2>
								<p>We will retain the User’s Personal Information as long as it is required to be retained for the purpose of provision of the Services. We may also retain and use the User’s Personal Information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. Subject to this section, we will try to delete the User’s Personal Information upon reasonable written request for the same. Please note, however, that there might be latency in deleting Personal Information from our servers and backed-up versions might exist even after deletion.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Security</h2>
								<p>The User’s Personal Information is stored on our database which are hosted on cloud. Although we provide appropriate firewalls and protections, we cannot warrant the security of any Personal Information transmitted as our systems are not hack proof. Data pilferage due to unauthorized hacking, virus attacks, technical issues is possible and we take no liabilities or responsibilities for it. The Users are required to be careful to avoid “phishing” scams, where someone may send the User an e-mail that looks like it is from SNS asking for the User’s personal information.</p>
								<p>The User is responsible for all the actions that take place under their Account. If the User chooses to share their Account details and password or any Personal Information with third parties, the User is solely responsible for the same. If the User loses control of their Account, the User may lose substantial control over its Personal Information and may be subject to legally binding actions. It is the User’s responsibility to keep the User’s password confidential.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Accessing and Modifying Personal Information</h2>
								<p>In case the User needs to access, review, and/or make changes to the Personal Information, the User may do so on their Account. The User shall keep the User’s Personal Information updated to help us improve our Services. If the User updates, modifies or corrects their Personal Information, we may continue to keep copies of the Personal Information prior to such update, modification or correction for uses provided for in this Privacy Policy. We shall not verify any such modifications or corrections made by the User. The User shall be solely liable for such modifications or corrections.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Indemnification</h2>
								<p>The User agrees to indemnify us, our subsidiaries, affiliates, officers, agents, suppliers and employees and hold us harmless from and against any claims and demand, including reasonable attorneys' fees, made by any third party due to arising out of or relating to: (i) accuracy and correctness of Personal Information and contents that User submits or shares through the Portal; (ii) the User’s violation of this Privacy Policy.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Limitation of Liability</h2>
								<p>The User expressly understands and agrees that SNS shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, information, details or other intangible losses (even if SNS has been advised of the possibility of such damages), arising out of this Privacy Policy.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Reservation of Rights</h2>
								<p>All rights not expressly granted in this Privacy Policy are reserved by SNS and its licensors, as the case may be. Nothing contained in this Privacy Policy shall be construed as conferring by implication, estoppels or otherwise any license or right under any copyright, patent, trademark or other intellectual property right of Clinic or any other person or to the User.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Governing Law and Disputes</h2>
								<p>This Privacy Policy shall be construed and governed by the laws of India without regard to principles of conflict of laws. Any dispute arising, between the Users and SNS shall be submitted to the exclusive jurisdiction of the courts in Mumbai, India.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Changes to the Policy</h2>
								<p>We may update this Privacy Policy without notice to the User. The Users are encouraged to check this Privacy Policy on a regular basis to be aware of the changes made to it.</p>
								<p>This Privacy Policy was last modified on February 2018.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Your acceptance to these terms</h2>
								<p>By using this site, you signify your acceptance to this policy and terms of service. If you do not agree to this policy, please do not use our site. Your continued use of the site following the posting of changes to this policy will be deemed your acceptance of those changes.</p>
							</div>
							<div className="">
								<h2 className="small_heading black bold mt-4 text-underline mb-2">Contact Us</h2>
								<p>If the Users have any questions or concerns or grievances regarding this Privacy Policy, the User can email us at our grievance email: <a href="mailto:cs@sportnetwork.in" target="_blank">cs@sportnetwork.in</a></p>
							</div>
						</Container>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default PrivacyPolicy;