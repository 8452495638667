import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import TechnologiesModal from './TechnologiesModal';


class Technologies extends React.Component { 
  render() {
    const { t } = this.props;
    return (
      <Container>
        <Row className="pd7">         
          <Col lg={12}>
            <ResponsiveMasonry columnsCountBreakPoints={{321:1, 767:2, 768: 3, 991: 3, 992: 4}}>
              <Masonry gutter={"15px"} className="teaser">
                {this.props.technologies.length > 0 && this.props.technologies.map((technology,key)=>(
                  <React.Fragment key={technology.id}>
                    <div className="teaser_container">
                      <div className="teaser_img">
                        <a href="#" data-toggle="modal" data-target={"#tech-modal-"+technology.id}>
                          <img src={this.props.settings.image_url+'technologies/original/'+technology.image} width="100%" />
                        </a>
                      </div>
                      <div className="teaser_content">              
                        <h6 className="bold mt-0 mb-2 text-capitalize"><a href="#" data-toggle="modal" data-target={"#tech-modal-"+technology.id} className="black">{technology.title}</a></h6>             
                        <p className="mb-0">{technology.description}</p>
                        {technology.details.length > 0 && (
                          <Button variant="link" className="btn-sm p-0" data-toggle="modal" data-target={"#tech-modal-"+technology.id}>
                            {t('learnMore')}
                          </Button> 
                        )}
                      </div>
                    </div>
                    <TechnologiesModal technologies={technology.details} id={technology.id}/>
                  </React.Fragment>
                ))
              }
              </Masonry>
            </ResponsiveMasonry>
          </Col>
        </Row>
      </Container>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(Technologies));
