import React, { Component } from 'react'
import CoreCatalogs from '../../../../core/components/catalogs/CoreCatalogs'
import Dropdown from './../../../components/formFields/DropDown';
import {Button, Container, Row, Form,Col} from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
class CreateCatalog extends CoreCatalogs {
  render() {
		const { t } = this.props;
    return (
			<React.Fragment>
				<div className="main admin-theme">
		      <Container>
		        <div className="mb-3 mt-3">
		          <h3 className="semi_bold"><Link to="/catalogs"><i className="far fa-long-arrow-left dark_gray pr-2"></i></Link> {t('addCatalog')}</h3> 
		        </div>
		        
		        <Form  onSubmit={(e)=>this.handleSubmit(e)} className="fields-group-md">
		          <Row className="pd7">
		            <Col lg={6} xs={12}>
		              <Form.Group>
		                <Form.Label>{t('catalogName')}</Form.Label>
		                <Form.Control onChange={event => this.setState({'catalogName':event.target.value})} name="catalogs[name]" type="text" value={this.state.catalogName} placeholder="Enter Catalog Name" required/>
		                <span className="errors red">{this.state.errorMessage['catalogs[name]']}</span>
		              </Form.Group>
		            </Col>
		            <Col lg={6} xs={12}>
		              <Form.Group>
		                <Form.Label>{t('accessLevel')}</Form.Label>
		                <Dropdown 
		                		name={"catalog_settings[name]"} 
		                		values={[{id: "Public", name: "Public"},{id: "Private", name: "Private"},{id: "Protected", name: "Protected"}]} 
		                		defaultValue={this.state.accessLevel} 
		                		onChange={event => this.setState({'accessLevel':event.target.value})}
		                		errorMessage={this.state.errorMessage} 
		                		placeholder={"Select Access Level"}/>
		              </Form.Group>
		            </Col>
		          </Row>
		          <div className="text-center mt-5">
		            <Button type="submit" className="btn btn-md bdr_dark_blue bdr_dark_blue">
		              {t('save')} {t('catalog')}
		            </Button>
		          </div>
		          {(this.state.isEdit ==1)?<div className="text-center mt30">
		            {(this.state.catalogData.status == 0)?<p className="text-muted">{t('disableCatalogText')}.
		            </p>:<p className="text-muted">{t('enableCatalogText')}
		            </p>}
		            <Link to="#" onClick={(e)=>this.enableDisableCatalog(e)} >
		              <p className={(this.state.catalogData.status == 0)?"text-center bold dark_red underline":"underline text-center bold dark_green"}>
		                {(this.state.catalogData.status == 0)?"Disable":'Enable'}
		              </p>
		            </Link>.
		            {(this.state.catalogData.status == 0)?<p className="text-muted">{t('catalogNotAffectText')}.</p>:""}
		          </div>:""}
		        </Form>
		      </Container>
		    </div>
			</React.Fragment>
		);
  }
}

export default withTranslation()(CreateCatalog);