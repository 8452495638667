import React from 'react';
class MultipleVariationSelection extends React.Component {
	render() {
		const { variation, selectedVariationKey, variationSkuCombinations, selectVariationSkuCode, selectvariation, selectedVariations, settings, changeImage } = this.props;

		return (
			variation.options.map((variation_option, variation_option_key) => {
					return (
						(variation.name != 'Color') ? // for size selection
						(selectedVariationKey == variation.name || (selectedVariationKey != variation.name && variationSkuCombinations.includes(selectVariationSkuCode + '-' + variation_option.sku_no))) ?
						<button
							key={variation_option_key}
							onClick={() => selectvariation(variation.name, variation_option.sku_no, changeImage, variation_option.value)}
							className={(selectedVariations[variation.name] === variation_option.sku_no) ? 'size_btn btn-primary mb-1 mt-1' : 'size_btn mb-1 mt-1'}
						>
							<text>{variation_option.value}</text>
						</button>
						:
						<button
							key={variation_option_key}
							className={'size_btn mb-1 mt-1 disable-variation'}
							disabled={'disabled'}
						>
							<text>{variation_option.value}</text>
						</button>
						:
						(selectedVariationKey == variation.name || (selectedVariationKey != variation.name && variationSkuCombinations.includes(selectVariationSkuCode + '-' + variation_option.sku_no))) ?
						<button
							key={variation_option_key}
							onClick={() => selectvariation(variation.name, variation_option.sku_no, changeImage, variation_option.value)}
							className={(selectedVariations[variation.name] === variation_option.sku_no) ? 'size_btn mb-1 mt-1 bg-white' : 'size_btn mb-1 mt-1 bg-white'}
							style={{ border: (selectedVariations[variation.name] === variation_option.sku_no) ? '2px solid #ffc700' : '', }}
						>
							<img width="50" height="50" src={settings.image_url + "/products/images/thumb/" + variation_option.name}></img>
						</button>
						:
						<button
							key={variation_option_key}
							className={'size_btn mb-1 mt-1 bg-white disable-color'}
							style={{ border: (selectedVariations[variation.name] === variation_option.sku_no) ? '2px solid #ffc700' : '', }}
							disabled={'disabled'}
						>
							<img width="50" height="50" src={settings.image_url + "/products/images/thumb/" + variation_option.name}></img>
							<i className="fal fa-times"></i>
						</button>
					)
				}
			)
		);
	}
}

export default MultipleVariationSelection;
