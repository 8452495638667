import React from 'react';
import Orders from './Orders';
import Loader from '../../../components/Loader';

class AllOrders extends React.Component {
	render(){
		return (
			<React.Fragment>
				{this.props.variations.map((variation,index)=>(
					<tr key={index} className="item_options">
						<td width="18%" className="px-0 py-0 pb-1"><p className="text-capitalize mb-0">{variation.variation_name}</p></td>
						<td width="82%" className="px-0 py-0 pb-1"><p className="text-capitalize mb-0">: {variation.variation_value}</p></td>
					</tr>
				))}
			</React.Fragment>
		);
	}
}


export default AllOrders;