import React from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import CoreCatalogListing from '../../../../core/components/catalogs/CoreCatalogListing';
import { getImageUrl, getClientApiUrl, getApiUrl } from '../../../../core/helpers/OperationsHelper';
import Loader from '../../../components/Loader';
import { withTranslation } from 'react-i18next';
class CatalogListing extends CoreCatalogListing {

  async setCatalogForAddProduct(catalogId, catalogName, catalogSymbol, action) {
    this.props.setCatalogId(catalogId);
    this.props.setCatalogName(catalogName);
    this.props.setCatalogSymbol(catalogSymbol);
    await this.getCatalogProducts(catalogId);
    if (action === 'add') {
      this.props.history.push('/');
    } else if (action === 'view') {
      localStorage.setItem('Catalog_Name', catalogName);
      console.log(catalogId);
      this.props.history.push(`/cpl/${catalogId}`);
      // this.props.history.push('/cpl'+catalogSymbol)
    }
  }

  async onClickShare(catalog_symbol) {
    var url = `https://api.whatsapp.com/send?text=*Shilpi Jewels has shared with you a product catalog. Click on the link to view the catalog.* ` + await getClientApiUrl() + `cpl/` + catalog_symbol
    window.open(url, '_blank');
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <div className="main home mt-5">
          {this.state.loader == false && (
            <Container>
              <Row className="mt-3">
                <Col lg={8} className="mx-auto category_sec">
                  {this.state.catalogs.map((item, i) => (
                    <Row className="pd3" key={i}>
                      <Col className={'product_box'}>
                        <div className="category_box card">
                          <div className="card-body black p-3">
                            <div className="d-flex">
                              <h5 className="bold flex-fill">{item.name}</h5>
                              <h5>{item.product_counts} {t('products')}</h5>
                            </div>
                            <div>
                              <Button
                                onClick={() => this.setCatalogForAddProduct(item.id, item.name, item.catalog_symbol, 'add')}
                                className="btn btn-md text-capitalize mr-2"
                                variant="outline-dark"
                              >
                                <i className="fas fa-file-plus pr-2"></i> {t('addProduct')}
                              </Button>
                              <Button
                                onClick={() => this.props.history.push('edit-catalog/' + item.id)}
                                className="btn btn-md text-capitalize mr-2"
                                variant="outline-dark"
                              >
                                <i className="fas fa-pen pr-2"></i> {t('edit')}
                              </Button>
                              <Button
                                onClick={() => this.onClickShare(item.catalog_symbol)}
                                target="_blanck"
                                className="btn btn-md text-capitalize mr-2" variant="dark">
                                <i className="fas fa-share-alt pr-2"></i>
                                Share
                              </Button>
                              <Button
                                onClick={() => this.setCatalogForAddProduct(item.id, item.name, item.catalog_symbol, 'view')}
                                className="btn btn-md text-capitalize mr-2"
                                variant="outline-dark"
                              >
                                <i className="fas fa-eye pr-2"></i> {t('view')}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                  ))}

                </Col>
                <Link className="float_icon" title="Add Catalog" to="/create-catalog">
                  <i className="fal fa-plus"></i>
                </Link>
              </Row>
            </Container>
          )}
          <Loader loaderState={this.state.loader} />
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCatalogCount: (count) => { dispatch({ type: 'SET_CATALOG_COUNT', payload: count }) },
    setCatalogProducts: (products) => { dispatch({ type: 'SET_CATALOG_PRODUCTS', payload: products }) },
    setCatalogName: (name) => { dispatch({ type: 'SET_CATALOG_NAME', payload: name }) },
    setCatalogId: (id) => { dispatch({ type: 'SET_CATALOG_ID', payload: id }) },
    setCatalogSymbol: (symbol) => { dispatch({ type: 'SET_CATALOG_SYMBOL', payload: symbol }) },
  }
}

export default withTranslation()(connect(null, mapDispatchToProps)(CatalogListing));

