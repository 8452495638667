const initialState = {products: [],page:false }

const products = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_PRODUCTS':
      return { products: action.payload}; 	
   	case 'SET_FILTERPAGE':
      return { page: action.payload}; 
  }
  return state
}

export default products;