import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

class SizeChartModal extends React.Component { 
  render() {
    return (
      <div id="size-modal" className="modal fade" tabIndex="-1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header border-0">             
              <button type="button" className="close btn-lg" data-dismiss="modal">
                  <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={this.props.settings.image_url+'size_charts/original/'+this.props.sizeChart} width="100%" />
            </div>           
          </div>
        </div>
      </div>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(SizeChartModal));
