import React from 'react';
import {Container, Row, Col, Button, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import LoadingButton from 'react-bootstrap-button-loader';

import CoreReturnReplacement from '../../../../core/components/transactions/CoreReturnReplacement'
import Tostify from '../../../components/Toaster';

class ReturnPopup extends CoreReturnReplacement {	
	render(){
		return (			
		  <div id="email_faq" aria-labelledby="email_faq_tab">
		  	<Tostify />
		  	<Card>
		  		<Card.Body>
		  			<h6 className="black bold text-center text-uppercase mb-4">Return/Replacement Request</h6>
		  			<Row>
		  				<Col >
				  			<Form className="fields-group-md" id="returnReplacement">
				  				<Form.Group>
								    <Form.Label>Return/Replacement </Form.Label>
								    <Form.Control as="select" name="refund_requests[request_for]">
								    	<option value={'replacement'}>{'Replacement'}</option>
								    	<option value={'return'}>{'Return'}</option>
								    </Form.Control>
								  	<span className="red"></span>
							 	 	</Form.Group>
								  <Form.Group>
								    <Form.Label>Reason for Return/Replacement? </Form.Label>
								    <Form.Control name="refund_requests[refund_reason]" as="textarea" className="mb-1" />
								    <span className="red">{this.state.errors['refund_requests[refund_reason]']}</span>
								  </Form.Group>		
								  <Form.Group>
								    <Form.Label>Select image 1</Form.Label>
								    <Form.File  name="refund_request_images[0][image]" rows={3} />
								    <span className="red">{this.state.errors['refund_request_images[0][image]']}</span>
								  </Form.Group>	
								  <Form.Group>
								    <Form.Label>Select image 2</Form.Label>
								    <Form.File  name="refund_request_images[1][image]" rows={3} />
								    <span className="red">{this.state.errors['refund_request_images[1][image]']}</span>
								  </Form.Group>	
								  <Form.Group>
								    <Form.Label>Select image 2</Form.Label>
								    <Form.File  name="refund_request_images[2][image]" rows={3} />
								    <span className="red">{this.state.errors['refund_request_images[2][image]']}</span>
								  </Form.Group>				  
								</Form>
								{(this.state.success)&&<span className="green">We have recieved your request and one of us will revert you shortly.</span>}
								<div className="text-center">
									<LoadingButton loading={this.state.LoadingButton} onClick={()=>this.submitRequest(this.props.orderId,this.props.orderDetailId)} style={this.props.styles.primary_btn}>Submit Request</LoadingButton>
								</div>
							</Col>
						</Row>
		  		</Card.Body>
		  	</Card>
		  </div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    styles: state.styles.styles,
  }
}

export default withTranslation()(connect(mapStateToProps)(ReturnPopup));