const initialState = {categories: [], brands: [], catalogs: [] }

const dealers = (state = initialState, action) => {
	switch (action.type) {
  	case 'SET_DEALER_CATEGORIES':
      return { categories: action.payload, brands: state.brands, catalogs: state.catalogs }; 
    case 'SET_DEALER_BRANDS':
      return { categories: state.categories, brands: action.payload, catalogs: state.catalogs };
    case 'SET_DEALER_CATALOGS':
      return { categories: state.categories, brands: state.brands, catalogs: action.payload };
  }
  return state
}

export default dealers;