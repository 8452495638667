import React from 'react';
import { Container, Row, Col, Form, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { withTranslation } from 'react-i18next';

import CoreHeader from '../../../../core/components/header/CoreHeader';
import LogoImg from '../../../assets/images/logos/scott-logo.png';
import ContentLoaderLogo from "../../ContentLoaderLogo";

class DealerHeader extends CoreHeader {
  render() {
    const { categories, subCategories, subSubCategories, dealerBrands, t } = this.props;
    return (
      <React.Fragment>
        <div className="logo_div">
          {(typeof this.props.settings.dealer_header_logo != 'undefined') ?
            <Link to="/" className="navbar-brand" style={{ padding: '20px 10px' }}>
              <img src={this.props.settings.image_url + '/application_images/original/' + this.props.settings.dealer_header_logo} width="130px" />
            </Link> : <ContentLoaderLogo />
          }
        </div>
        <nav id="menu" className={`main_menus ${this.props.isOpen == true ? "open" : ""} d-flex align-items-center`}>
          <ul>
            {categories.length > 0 && categories.map((category, menuIndex) => (
              <li key={menuIndex} className={`nav-item bold pl-3 pr-3 ${typeof this.state.openMenuValues[menuIndex] != 'undefined' && this.state.openMenuValues[menuIndex].isOpen == true ? "active" : ""}`}>
                <a className="nav-link" style={{ display: "block", padding: "23px 15px !important" }} onClick={() => this.menuToggle(menuIndex)}>{category.name}</a>
                <div className="subnav">
                  <Container>
                    <Row>
                      <Col lg={8} className="subnav-list" >
                        <Row className="no-gutters">
                          {Object.keys(subCategories).length > 0 && typeof subCategories[category.id] != 'undefined' && (subCategories[category.id]).map((subCategory, subIndex) => (
                            <Col key={subIndex} lg={4} className={`${typeof this.state.openMenuValues[menuIndex] != 'undefined' && this.state.openMenuValues[menuIndex].isOpen == true ? "sub_active" : ""}`}>
                              <ul className="subnav-list-item">
                                <React.Fragment>
                                  <li className="submenu_desk right_col">
                                    <Link to={"/pl/" + category.slug + '/' + subCategory.slug}>{subCategory.name}</Link>
                                  </li>
                                  <li className={`submenu_mob right_col ${typeof this.state.openSubMenuValues[subIndex] != 'undefined' && this.state.openSubMenuValues[subIndex].isOpen == true ? "sub_active" : ""}`}>
                                    <a onClick={() => this.subMenuToggle(subIndex)}>{subCategory.name}</a>
                                  </li>
                                  <div className="pushmenu">
                                    {Object.keys(subSubCategories).length > 0 && typeof subSubCategories[subCategory.id] != 'undefined' && (subSubCategories[subCategory.id]).map((subSubCategory, index) => (
                                      <li key={index}>
                                        <Link to={"/pl/" + category.slug + '/' + subCategory.slug + '/' + subSubCategory.slug}>{subSubCategory.name}</Link>
                                      </li>
                                    ))}
                                  </div>
                                </React.Fragment>
                              </ul>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col lg={4} className="subnav-promos">
                        <Row className="no-gutters">
                          <Col lg={12} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_1} className="img-fluid" />
                            </a>
                          </Col>
                          <Col lg={6} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_2} className="img-fluid" />
                            </a>
                          </Col>
                          <Col lg={6} sm={12}>
                            <a href="" className="submenu_banner">
                              <img src={category.menu_image_url_3} className="img-fluid" />
                            </a>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </li>
            ))}
            <li className={`nav-item bold ${typeof this.state.openMenuValues['brands'] != 'undefined' && this.state.openMenuValues['brands'].isOpen == true ? "active" : ""}`}>
              <a className="nav-link" onClick={() => this.menuToggle('brands')}>Brands</a>
              <div className="subnav">
                <Container>
                  <Row>
                    <Col lg={8} className="subnav-list">
                      <Row className="no-gutters">
                        <Col lg={3} className={`${this.state.openSubdiv == 1 && this.state.isSubactive == true ? "sub_active" : ""}`}>
                          <ul className="subnav-list-item">
                            <React.Fragment>
                              <li className="submenu_desk right_col">
                                <Link to={"#"}>{'Brands'}</Link>
                              </li>
                              <li className={`submenu_mob right_col ${typeof this.state.openSubMenuValues['brands'] != 'undefined' && this.state.openSubMenuValues['brands'].isOpen == true ? "sub_active" : ""}`}>
                                <a onClick={() => this.subMenuToggle('brands')}>Brands</a>
                              </li>
                              <div className="pushmenu">
                                {Object.keys(dealerBrands).length > 0 && dealerBrands.map((brand, index) => (
                                  <li key={index}>
                                    <Link to={"/bpl/" + brand.brand_slug}>{brand.brand_name}</Link>
                                  </li>
                                ))}
                              </div>
                            </React.Fragment>
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </div>
            </li>
          </ul>
        </nav>
      </React.Fragment>
    );
  };
};

export default withTranslation()(DealerHeader);