import React from 'react';
import {Container, Row, Col, Card, Form, Table} from 'react-bootstrap';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { dealersPortal } from  '../../../../core/helpers/OperationsHelper';
import CoreSellerSelection from '../../../../core/components/transactions/CoreSellerSelection';
import OrderSummery from '../components/OrderSummery';
import Tostify from '../../../components/Toaster';
import Variations from '../components/Variations';
import { updateSellerId } from  '../../../../core/helpers/ServicesHelper';
import EmptyCart from '../../../components/EmptyCart';
import loader from '../../../assets/loader/circle-loader.gif';
import Loader from '../../../components/Loader';

class SellerSelection extends CoreSellerSelection {
	
	updateSellerId = async (value, id) => {
		var formdata=[];
		formdata['carts[id]'] = id;
		formdata['carts[seller_id]'] = value;
		await updateSellerId(formdata, id);
	}

	render(){
		const { t } =this.props;
		return (
			<React.Fragment> 
				<Tostify/>
				<div className="main mb-5 mt-5">
				{this.state.loader == false && (						
					<Container>
						<h1 className="page_heading bold text-uppercase black mb-3">{t('select')} {t('seller')}</h1>
						{this.state.carts.length > 0 ?	
							<Row className="flex-column-reverse flex-sm-row">
								<Col lg={8} sm={8} className="mt-4 mt-sm-0">
									<Card className="cart_table shadow-sm">									
										<Card.Body>										
											{this.state.carts.map((item, index)=>(
												<div className="cart_item">
													<Row className="">
														<Col xs={4}>
															<Link to={"/product-detail/"+item.item_details.id} title={item.item_details.name} className="product_item_photo">
																<span className="product_item_wrapper">
																	{(typeof this.state.images[item.id] != 'undefined' && this.state.images[item.id] != "") ?
																		<img src={this.state.images[item.id]} className="product_item_img img-fluid" /> : 
																		<img src={loader} className="product_img img-fluid" /> 
																	}
																</span>
															</Link>
														</Col>
														<Col xs={8}>
															<div className="product_item_details">
																<div className="d-flex align-items-center">
																	<div className="flex-fill w-50">
																		<Link to={"/pp/"+item.item_details.category_slug+'/'+item.item_details.slug} className="product_item_name">{item.item_details.name}</Link>
																		<Table borderless className="mt-2 mb-0">
																			<tbody>
																				<tr className="item_options">
																					<td width="18%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">{t('model')}</p></td>
																					<td width="82%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">: {item.item_details.item_code}</p></td>
																				</tr>
																				<tr className="item_options">
																					<td width="18%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">{item.item_details.product_type_field_name}</p></td>
																					<td width="82%" className="px-0 py-0 pb-1"><p className="text-capitalize black mb-0">: <i className="fal fa-rupee-sign"></i> {item.item_details.product_type_field_value - ((item.item_details.product_type_field_value * item.dealer_discount) / 100)}</p></td>
																				</tr>
																			</tbody>
																		</Table>																				
																	</div>
																	{item.item_details.product_type_field_currency == 0 && (
																		<div className="price_div flex-fill">
																			<span className="item_price bold"> 
																				{(typeof item.item_details.product_type_field_value != 'undefined') && (
																					item.item_details.product_type_field_value+' '+item.item_details.product_type_field_unit
																				)}
																			</span>
																		</div>
																	)}
																</div>
																<div className="d-flex">
																	<div className="flex-fill">
																		<Table borderless width="100%" className="mb-0">
																			<tbody>
																				<Variations variations={item.variations} />
																				<tr className="item_options">
							                            <td width="18%" className="px-0 py-0 pb-1"><p className="">{t('qty')}</p></td>                       
							                            <td width="82%" className="px-0 py-0 pb-1"><p className="">: {item.quantity}</p></td>                        
							                          </tr>
																			</tbody>
									    							</Table>
																	</div>
																</div>
																{(dealersPortal == false && item.user_delivery_note != "")&&
																	<div className="d-flex">
																		<div className="flex-fill">
																			{/* <div className="item_options">
																				<span className="bold">Note:</span><br/>
																				<span className="red">{item.user_delivery_note} </span>
																			</div>																	 */}
																		</div>
																	</div>
																}
															</div>
														</Col>
													</Row>
													<div className="mt-2 mb-3">
														<Form className="fields-group-md seller_check">
															<label className="form-label mb-0">{t('select')} {t('seller')} :</label>
															<Row className="ml-3 mt-1">
																{(item.item_details.sellers.length > 0) ? 
																	item.item_details.sellers.map((seller, index) => (
																		<Col key={index} className='mt-3' sm={4}>
																	    <div key={`custom-inline-${seller.id}`} className="p-2">
																	      <Form.Check
																	        custom
																	        inline
																	        label=""
																	        onClick={() => this.updateSellerId(seller.id, item.id)}
																	        type={'radio'}
																	        id={`seller-${seller.id+'-'+item.id}`}
																	        name={`seller-${item.id}`}
																	        title="Seller 1 address"
																	      />	
																	      <div className="pr-2">		
																	      	<p className="mb-0 bold" style={{fontSize:'0.95rem'}}>{seller.trading_name}</p>
																	      	<p className="mb-0" style={{fontSize:'0.95rem'}}>{seller.address}</p>
																	      </div>
																	    </div>
																		</Col>
																 )):<Col className='mt-3' sm={4}>
																	    <div className="p-2">
																	      <div className="pr-2">		
																	      	<p className="mb-0 red" style={{fontSize:'0.95rem'}}>Seller not available.</p>
																	      </div>
																	    </div>
																		</Col>
																}
															</Row>	
															<Row className="ml-3 mt-1">
																<Col className='mt-3' sm={12}>																			
																	<span className="red">{this.state.errors['transaction_'+item.id]}</span>
																</Col>	
															</Row>	
														</Form>
													</div>
												</div>
											))}											
										</Card.Body>
									</Card>
								</Col>
								<OrderSummery 
									error={0}
									parentProps={this.props}
									applyCouponCode={false}
									useStoreCredit={false}
									placeOrder={true}
									validateSellerSelection={() => this.validateSellerSelection()}
									order={true}
									settings={this.props.settings}
								/>
							</Row>
						:
						<EmptyCart/>}
					</Container>
				)}
				<Loader loaderState={this.state.loader} />	
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
  return {
    settings:state.settings.settings,
  }
}
export default withTranslation()(connect(mapStateToProps, null)(SellerSelection));
