import React from 'react';
import { Link } from 'react-router-dom';
import "../../../assets/css/prebooking.css";

import prebookImg1 from "../../../assets/images/prebookImg/prebooking1.jpg";
import prebookImg2 from "../../../assets/images/prebookImg/prebooking2.jpg";
import prebookImg3 from "../../../assets/images/prebookImg/prebooking3.jpg";
import prebookImg4 from "../../../assets/images/prebookImg/prebooking4.jpg";
import prebookImg5 from "../../../assets/images/prebookImg/prebooking5.jpg";
import prebookImg6 from "../../../assets/images/prebookImg/prebooking6.jpg";
import prebookImg7 from "../../../assets/images/prebookImg/prebooking7.jpg";
import prebookImg8 from "../../../assets/images/prebookImg/prebooking8.jpg";


class PreBooking extends React.Component {
    render() {
        return (
            <div>
                <div className="tab-pane fade" id="prebook_faq" role="tabpanel" aria-labelledby="prebook_faq_tab">
                    <h5 className="black bold mt-5 ms-4">
                        <a href="" className='btn btn-warning bold' data-toggle="modal" data-target="#myModal">Steps To Prebook</a>
                    </h5>
                </div>

                <div className="container">

                    <div className="modal" id="myModal">
                        <div className="modal-dialog">
                            <div className="modal-content">


                                <div className="modal-header">
                                    <h4 className="modal-title">FAQ</h4>
                                    <button type="button" className="btn-close" data-dismiss="modal"></button>
                                </div>


                                <div className="modal-body">
                                    {/* <div className="row">
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg1} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg2} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg3} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg4} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg5} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg6} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg7} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <div className="prebook_img_steps">
                                                        <img src={prebookImg8} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>


                                <div className="modal-footer">
                                    <Link to="/pl/bikes/bikes/pre-booking" className="btn btn-warning" >PreBooking</Link>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default PreBooking;
